import React from 'react';
import classNames from 'classnames';
import Button from '@mui/material/Button';
import useStyles from './EngineTypeButtons.style';
import Tooltip from '../Tooltip/Tooltip';
import { ReactComponent as EngineTypeCombustion } from '../../../assets/svg/fuel_resizeable.svg';
import { ReactComponent as EngineTypeElectric } from '../../../assets/svg/electrical_services_black_24dp.svg';
import { EngineTypeEnum } from '../../../enums/engineType.enum';
import { useTranslation } from 'react-i18next';

const EngineTypeButtons: React.FC<Props> = ({
  buttonPadding,
  iconSize,
  engineType,
  setEngineType,
  disabled = false,
}) => {
  const classes = useStyles({ buttonPadding, iconSize });
  const { t } = useTranslation('licensePlateEngineType');

  return (
    <>
      <Tooltip disabled={disabled} content={t('combustionEngineType')}>
        <Button
          disableRipple
          aria-label={t('combustionEngineType')}
          variant='outlined'
          disabled={disabled}
          onClick={() => setEngineType(EngineTypeEnum.COMBUSTION)}
          className={classNames(
            classes.engineTypeButton,
            engineType === EngineTypeEnum.COMBUSTION && !disabled && classes.engineTypeButtonActive
          )}
        >
          <EngineTypeCombustion title={t('combustionEngineType')} className={classes.engineIcon} />
        </Button>
      </Tooltip>
      <Tooltip disabled={disabled} content={t('evAndPhevEngineType')}>
        <Button
          disableRipple
          aria-label={t('evAndPhevEngineType')}
          variant='outlined'
          disabled={disabled}
          onClick={() => setEngineType(EngineTypeEnum.EV_PHEV)}
          className={classNames(
            classes.engineTypeButton,
            engineType === EngineTypeEnum.EV_PHEV && !disabled && classes.engineTypeButtonActive
          )}
        >
          <EngineTypeElectric title={t('evAndPhevEngineType')} className={classes.engineIcon} />
        </Button>
      </Tooltip>
    </>
  );
};

interface Props {
  disabled?: boolean;
  engineType: EngineTypeEnum | undefined;
  setEngineType: (engineType: EngineTypeEnum | undefined) => void;
  buttonPadding?: number;
  iconSize?: number;
}

export default EngineTypeButtons;
