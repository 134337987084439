import { initialReservation, ReservationState } from './ReservationState';
import { EngineTypeEnum } from '../../../../../enums/engineType.enum';
import { Guest } from '../../../../../models/guest/guest';
import { Place } from '../../../../../models/place/place';
import { ReservationError } from '../../../../../models/parkingReservation/parkingReservation';
import { TeamSpaceMember } from '../../../../../models/teamSpaceMember/team-space-member';
import { BookingUtil } from '../../../../../utils/booking/booking-util';
import { GuestsUtil } from '../../../../../utils/guests/guests-util';
import { ReservationErrorEnum } from '../../../../../enums/reservationWizard.enum';

export enum ReservationActionType {
  AddError,
  AddGuest,
  RemoveError,
  RemoveGuest,
  ResetDefaultLicensePlateAndEngineType,
  SetCanContinue,
  SetDate,
  SetEngineType,
  SetExistingTeamSpaceMembers,
  SetGuestName,
  SetLicensePlate,
  SetNeedGuests,
  SetNeedParkingSpace,
  SetPlace,
  SetTeamSpaceMemberNeedParking,
  SetTeamSpaceMembers,
  ValidateGuests,
  ValidateLicensePlateAndEngineType,
}

interface AddErrorAction {
  type: ReservationActionType.AddError;
  payload: ReservationError;
}

interface AddGuestAction {
  type: ReservationActionType.AddGuest;
  payload: Guest;
}

interface RemoveErrorAction {
  type: ReservationActionType.RemoveError;
  payload: ReservationError;
}

interface RemoveGuestAction {
  type: ReservationActionType.RemoveGuest;
  payload: number;
}

interface SetCanContinueAction {
  type: ReservationActionType.SetCanContinue;
  payload: boolean;
}

interface SetDateAction {
  type: ReservationActionType.SetDate;
  payload: string[];
}

interface SetEngineTypeAction {
  type: ReservationActionType.SetEngineType;
  payload: EngineTypeEnum | undefined;
}

interface SetExistingTeamSpaceMembersAction {
  type: ReservationActionType.SetExistingTeamSpaceMembers;
  payload: Pick<TeamSpaceMember, 'email' | 'needParkingSpace'>[];
}

interface SetGuestNameAction {
  type: ReservationActionType.SetGuestName;
  payload: Guest;
}

interface SetLicenseAction {
  type: ReservationActionType.SetLicensePlate;
  payload: string | undefined;
}

interface ResetDefaultLicensePlateAndEngineTypeAction {
  type: ReservationActionType.ResetDefaultLicensePlateAndEngineType;
  payload: { licensePlate: string | undefined; engineType: EngineTypeEnum | undefined };
}

interface SetNeedGuests {
  type: ReservationActionType.SetNeedGuests;
  payload: boolean;
}

interface SetNeedParkingSpace {
  type: ReservationActionType.SetNeedParkingSpace;
  payload: boolean;
}

interface SetPlaceAction {
  type: ReservationActionType.SetPlace;
  payload: Place;
}

interface SetTeamSpaceMembersAction {
  type: ReservationActionType.SetTeamSpaceMembers;
  payload: Pick<TeamSpaceMember, 'index' | 'person'>[];
}

interface SetTeamSpaceMembersNeedParkingAction {
  type: ReservationActionType.SetTeamSpaceMemberNeedParking;
  payload: Pick<TeamSpaceMember, 'index' | 'needParkingSpace'>[];
}

interface ValidateGuestsAction {
  type: ReservationActionType.ValidateGuests;
}

interface ValidateLicensePlateAndEngineTypeAction {
  type: ReservationActionType.ValidateLicensePlateAndEngineType;
  payload: { shouldValidate: boolean; errorMessage: string };
}

export type ReservationActions =
  | AddErrorAction
  | AddGuestAction
  | RemoveErrorAction
  | RemoveGuestAction
  | ResetDefaultLicensePlateAndEngineTypeAction
  | SetCanContinueAction
  | SetDateAction
  | SetEngineTypeAction
  | SetExistingTeamSpaceMembersAction
  | SetGuestNameAction
  | SetLicenseAction
  | SetNeedGuests
  | SetNeedParkingSpace
  | SetPlaceAction
  | SetTeamSpaceMembersAction
  | SetTeamSpaceMembersNeedParkingAction
  | ValidateGuestsAction
  | ValidateLicensePlateAndEngineTypeAction;

export function reservationReducer(
  state: ReservationState = initialReservation,
  action: ReservationActions
): ReservationState {
  switch (action.type) {
    case ReservationActionType.AddError:
      return { ...state, reservationError: addErrorToState(state.reservationError, action.payload) };
    case ReservationActionType.AddGuest:
      return {
        ...state,
        guests: [...state.guests, action.payload],
      };
    case ReservationActionType.RemoveError:
      return { ...state, reservationError: removeErrorFromState(state.reservationError, action.payload) };
    case ReservationActionType.RemoveGuest:
      return { ...state, guests: state.guests.filter((guest) => guest.index !== action.payload) };
    case ReservationActionType.ResetDefaultLicensePlateAndEngineType:
      if (!state.licensePlate && !state.engineType) {
        return { ...state, licensePlate: action.payload.licensePlate, engineType: action.payload.engineType };
      }
      return state;
    case ReservationActionType.SetCanContinue:
      return { ...state, canContinue: action.payload };
    case ReservationActionType.SetDate:
      return { ...state, date: action.payload };
    case ReservationActionType.SetEngineType:
      return { ...state, engineType: action.payload };
    case ReservationActionType.SetExistingTeamSpaceMembers:
      return { ...state, existingTeamSpaceMembers: action.payload };
    case ReservationActionType.SetGuestName:
      return {
        ...state,
        guests: [...state.guests.filter((guest) => guest.index !== action.payload.index), action.payload],
      };
    case ReservationActionType.SetLicensePlate:
      return { ...state, licensePlate: action.payload };
    case ReservationActionType.SetNeedGuests:
      return { ...state, needGuests: action.payload };
    case ReservationActionType.SetNeedParkingSpace:
      if (action.payload) {
        return { ...state, needParkingSpace: action.payload };
      }
      return { ...state, needParkingSpace: action.payload, engineType: undefined, licensePlate: undefined };
    case ReservationActionType.SetPlace:
      return { ...state, place: action.payload };
    case ReservationActionType.SetTeamSpaceMembers:
      return { ...state, teamSpaceMembers: action.payload };
    case ReservationActionType.SetTeamSpaceMemberNeedParking:
      return { ...state, teamSpaceMembersParking: action.payload };
    case ReservationActionType.ValidateGuests:
      return { ...state, canContinue: state.needGuests ? GuestsUtil.areGuestNamesValid(state.guests) : true };
    case ReservationActionType.ValidateLicensePlateAndEngineType:
      const error = {
        kind: ReservationErrorEnum.INVALID_LICENSE_PLATE_OR_ENGINE_TYPE,
        message: action.payload.errorMessage,
      };
      if (action.payload.shouldValidate) {
        return {
          ...state,
          reservationError: !BookingUtil.validateLicensePlateAndEngineType({
            licensePlate: state.licensePlate,
            engineType: state.engineType,
          })
            ? addErrorToState(state.reservationError, error)
            : removeErrorFromState(state.reservationError, error),
        };
      }
      return { ...state, reservationError: removeErrorFromState(state.reservationError, error) };

    default:
      return state;
  }
}

//helper functions
export const addError = (reservationError: ReservationError): AddErrorAction => ({
  type: ReservationActionType.AddError,
  payload: reservationError,
});

const addErrorToState = (reservationError: ReservationError[], error: ReservationError) => {
  return [...reservationError, error].reduce<ReservationError[]>((result, current) => {
    return result.find((element) => element.kind === current.kind) ? [...result] : [...result, current];
  }, []);
};

export const addGuest = (guest: Guest): AddGuestAction => ({
  type: ReservationActionType.AddGuest,
  payload: guest,
});

export const removeError = (reservationError: ReservationError): RemoveErrorAction => ({
  type: ReservationActionType.RemoveError,
  payload: reservationError,
});

const removeErrorFromState = (reservationError: ReservationError[], error: ReservationError) => {
  return reservationError.filter((e) => e.kind !== error.kind);
};

export const removeGuest = (index: number): RemoveGuestAction => ({
  type: ReservationActionType.RemoveGuest,
  payload: index,
});

export const resetDefaultLicensePlateAndEngineType = ({
  licensePlate,
  engineType,
}: {
  licensePlate: string | undefined;
  engineType: EngineTypeEnum | undefined;
}): ResetDefaultLicensePlateAndEngineTypeAction => ({
  type: ReservationActionType.ResetDefaultLicensePlateAndEngineType,
  payload: { licensePlate, engineType },
});

export const setCanContinue = (canContinue: boolean): SetCanContinueAction => ({
  type: ReservationActionType.SetCanContinue,
  payload: canContinue,
});

export const setDate = (date: string[]): SetDateAction => ({
  type: ReservationActionType.SetDate,
  payload: date,
});

export const setEngineType = (engineType: EngineTypeEnum | undefined): SetEngineTypeAction => ({
  type: ReservationActionType.SetEngineType,
  payload: engineType,
});

export const setExistingTeamSpaceMembers = (
  teamSpaceMembers: Pick<TeamSpaceMember, 'email' | 'needParkingSpace'>[]
): SetExistingTeamSpaceMembersAction => ({
  type: ReservationActionType.SetExistingTeamSpaceMembers,
  payload: teamSpaceMembers,
});

export const setGuestName = (guest: Guest): SetGuestNameAction => ({
  type: ReservationActionType.SetGuestName,
  payload: guest,
});

export const setLicensePlate = (licensePlate: string | undefined): SetLicenseAction => ({
  type: ReservationActionType.SetLicensePlate,
  payload: licensePlate,
});

export const setNeedGuests = (needGuests: boolean): SetNeedGuests => ({
  type: ReservationActionType.SetNeedGuests,
  payload: needGuests,
});

export const setNeedParkingSpace = (needParkingSpace: boolean): SetNeedParkingSpace => ({
  type: ReservationActionType.SetNeedParkingSpace,
  payload: needParkingSpace,
});

export const setPlace = (place: Place): SetPlaceAction => ({
  type: ReservationActionType.SetPlace,
  payload: place,
});

export const setTeamSpaceMembers = (
  teamSpaceMembers: Pick<TeamSpaceMember, 'index' | 'person'>[]
): SetTeamSpaceMembersAction => ({
  type: ReservationActionType.SetTeamSpaceMembers,
  payload: teamSpaceMembers,
});

export const setTeamSpaceMembersParking = (
  teamSpaceMembersParking: Pick<TeamSpaceMember, 'index' | 'needParkingSpace'>[]
): SetTeamSpaceMembersNeedParkingAction => ({
  type: ReservationActionType.SetTeamSpaceMemberNeedParking,
  payload: teamSpaceMembersParking,
});

export const validateGuests = (): ValidateGuestsAction => ({
  type: ReservationActionType.ValidateGuests,
});

export const validateLicensePlateAndEngineType = ({
  shouldValidate,
  errorMessage,
}: {
  shouldValidate: boolean;
  errorMessage: string;
}): ValidateLicensePlateAndEngineTypeAction => ({
  type: ReservationActionType.ValidateLicensePlateAndEngineType,
  payload: { shouldValidate, errorMessage },
});
