import makeStyles from '@mui/styles/makeStyles';
import { muiTheme, theme } from '../../../../styles/theme';
import sharedStyles from '../../../../styles/sharedStyles/sharedStyles';

const shared = sharedStyles();

export default makeStyles({
  ...shared,

  main: {
    ...shared.flexColumn,
    ...shared.gap16,
    padding: 16,
    paddingRight: 56,
    backgroundColor: theme.colors.blue.lightest,
    width: '100%',
  },

  mainLoading: {
    ...shared.gap16,
    padding: 16,
    paddingRight: 56,
    backgroundColor: theme.colors.blue.lightest,
    width: '100%',
    ...shared.flexRowAlignCenterJustifyCenter,
  },

  mainResizeWithUnavailable: {
    [muiTheme.breakpoints.down('md')]: {
      paddingTop: 32,
    },
  },

  marginTop: {
    marginTop: 2,
  },

  title: {
    ...shared.colorGreyDark,
    ...shared.fontSize10,
    fontWeight: 'normal',
    letterSpacing: 0.5,
    margin: '0 0 4px',
    textTransform: 'uppercase',
  },

  content: {
    ...shared.flexColumn,
    ...shared.fontSize12,
    ...shared.gap4,
    letterSpacing: 0,
    textTransform: 'uppercase',

    '& p': {
      margin: 0,
    },
  },

  garageLabel: {
    [muiTheme.breakpoints.down('md')]: {
      width: 36,
    },
    ...shared.colorBlueMain,
    ...shared.fontSize10,
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },

  garageLabelExt: {
    ...shared.colorGreyDark,
  },

  parkingStatusContainer: {
    alignItems: 'center',
    columnGap: 8,
    display: 'flex',
    gridTemplateColumns: 'auto 1fr 1fr',
    rowGap: 4,
    width: 'fit-content',
    [muiTheme.breakpoints.down('md')]: {
      display: 'grid',
      gridTemplateColumns: 'auto 1fr',
    },
  },

  parkingStatus: {
    [muiTheme.breakpoints.down('md')]: {
      gridColumnEnd: -1,
    },
    ...shared.flexAlignCenter,
    ...shared.fontSize14,
    ...shared.gap4,
    color: theme.colors.blue.royal,
  },

  parkingIcon: {
    color: theme.colors.grey.dark,
    display: 'inline-block',
    height: 16,
    width: 16,
  },

  parkingTooltip: {
    textTransform: 'initial',
  },

  loadingSpinnerContainer: {
    ...shared.flexRowAlignCenterJustifyCenter,
    width: 24,
    height: 24,
  },
});
