import React, { useEffect, useMemo, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { ReservationContext } from './ReservationContext/ReservationContext';
import { reservationReducer, setDate, setPlace } from './ReservationContext/ReservationReducer';
import { getInitialReservation, initialReservation } from './ReservationContext/ReservationState';
import StartingStep from './StartingStep/StartingStep';
import Step from './Step/Step';
import ConfirmedReservation from '../ConfirmedReservation/ConfirmedReservation';
import { RoutePaths } from '../../../App/Router/RouterConstants';
import Wizard from '../../../Shared/Wizard/Wizard';
import { BookingRequestAssembler } from '../../../../assemblers/booking/booking-request-assembler';
import { useSelectedDate } from '../../../../hooks/appHooks/appHooks';
import { useCreateBooking, useSelectedPlace } from '../../../../hooks/bookingHooks/bookingHooks';
import { useGetUserDefaults } from '../../../../hooks/userHooks/userHooks';
import { setEngineType as setDispatchEngineType } from '../../../../redux/actions/appActions/appActions';
import Formatters from '../../../../services/Formatters/Formatters';
import i18n from '../../../../translations/i18n';
import { GuestsUtil } from '../../../../utils/guests/guests-util';
import { TeamSpaceUtil } from '../../../../utils/teamSpace/team-space-util';
import { PlaceTypeEnum } from '../../../../enums/placeType.enum';

const ReservationWizard: React.FC = () => {
  const navigate = useNavigate();
  void i18n.loadNamespaces('reservationWizard');
  const { t } = useTranslation('reservationWizard');
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { licensePlate: defaultLicensePlate, engineType: defaultEngineType, email } = useGetUserDefaults();

  const stepLength = 1;

  const [reservationState, reservationDispatch] = useReducer(
    reservationReducer,
    getInitialReservation({ licensePlate: defaultLicensePlate, engineType: defaultEngineType ?? undefined })
  );

  const providerValue = useMemo(
    () => ({ state: reservationState, dispatch: reservationDispatch }),
    [reservationState, reservationDispatch]
  );

  const place = useSelectedPlace();
  const isTeamSpace: boolean = useMemo(() => place?.type === PlaceTypeEnum.TEAM_SPACE ?? false, [place]);

  const onCancel = () => {
    navigate(RoutePaths.dashboard);
  };

  const bookingTeamSpaceMembers = useMemo(
    () =>
      TeamSpaceUtil.getTeamSpaceMembers(
        reservationState.teamSpaceMembers,
        (reservationState.needParkingSpace && reservationState.teamSpaceMembersParking) || []
      ),
    [reservationState.teamSpaceMembers, reservationState.needParkingSpace, reservationState.teamSpaceMembersParking]
  );

  const bookingRequest = BookingRequestAssembler.fromBooking({
    dates: reservationState.date,
    licensePlate:
      (isTeamSpace && TeamSpaceUtil.getTeamSpaceMemberNeedParkingSpaceByEmail(bookingTeamSpaceMembers, email)) ||
      (!isTeamSpace && reservationState.needParkingSpace)
        ? reservationState.licensePlate
        : undefined,
    reason: undefined,
    engineType:
      (isTeamSpace && TeamSpaceUtil.getTeamSpaceMemberNeedParkingSpaceByEmail(bookingTeamSpaceMembers, email)) ||
      (!isTeamSpace && reservationState.needParkingSpace)
        ? reservationState.engineType
        : undefined,
    teamSpaceMembers: TeamSpaceUtil.getTeamSpaceMembers(
      reservationState.teamSpaceMembers,
      reservationState.teamSpaceMembersParking
    ),
    guests: reservationState.needGuests ? GuestsUtil.getGuestList(reservationState.guests) : undefined,
  });

  const selectedDate = useSelectedDate();
  const dateRange = useMemo(
    () => [Formatters.formatDateISOYearMonthDay(selectedDate.date)],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const { createBooking, booking, bookingError, bookingLoading } = useCreateBooking(bookingRequest, place!);

  useEffect(() => {
    if (bookingError) {
      enqueueSnackbar(bookingError, {
        variant: 'error',
        persist: true,
      });
    }
  }, [bookingError, enqueueSnackbar]);

  useEffect(() => {
    place && reservationDispatch(setPlace(place));
    dateRange && reservationDispatch(setDate(dateRange));
  }, [dateRange, place, reservationDispatch]);

  const onConfirm = () => {
    if (!!defaultLicensePlate && !defaultEngineType && defaultLicensePlate === reservationState.licensePlate) {
      dispatch(setDispatchEngineType(reservationState.engineType));
    }
    void createBooking();
  };

  const stepProps = {
    bookingLoading,
    stepLength,
    onCancel,
    onConfirm,
  };

  return (
    <ReservationContext.Provider value={providerValue}>
      {!booking ? (
        <Wizard stepLength={stepLength}>
          {({ onNextStep, onPreviousStep, step }) => {
            const wizardProps = {
              ...stepProps,
              step,
              onNextStep,
              onPreviousStep,
            };
            return (
              <Step {...wizardProps} stepTitle={t('reservationWizard.newReservation')}>
                <StartingStep />
              </Step>
            );
          }}
        </Wizard>
      ) : (
        <ConfirmedReservation booking={booking} />
      )}
    </ReservationContext.Provider>
  );
};

export default ReservationWizard;
