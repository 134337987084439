import classNames from 'classnames';
import React from 'react';
import useStyles from './Card.style';

const Card: React.FC<Props> = ({ children, className, id, padding = 0, width = 'auto', ...rest }) => {
  const classes = useStyles({ padding, width });

  return (
    <div className={classNames(classes.root, className)} {...rest}>
      {children}
    </div>
  );
};

export type Props = {
  children?: React.ReactNode | undefined;
  className?: string;
  id?: string;
  padding?: string | number;
  width?: string | number;
};

export default Card;
