import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { theme } from '../../../styles/theme';
import { pxToRemOrEm } from '../../../styles/utilities';

export default makeStyles(() =>
  createStyles({
    buttonRoot: {
      padding: 0,
      color: theme.colors.grey.main,
      '&:hover': {
        backgroundColor: 'transparent',
        color: theme.colors.blue.royal,
      },
      '& svg': {
        width: 16,
        height: 16,
      },
    },

    menuRoot: {
      borderRadius: 0,
      boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.12)',
      width: 112,

      '& .MuiList-padding': {
        padding: 0,
      },
    },

    menuItem: {
      fontSize: pxToRemOrEm(14),

      '&:hover': {
        backgroundColor: theme.colors.blue.royal,
        color: theme.colors.white.main,
      },
    },
  })
);
