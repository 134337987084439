import { useSelector } from 'react-redux';
import { MomentInput } from 'moment';
import { getSelectedDate, getUserBuilding } from '../../redux/selectors/appSelectors';
import { EndpointActions, useFetch } from '../ajaxHooks/ajaxHooks';
import { useGetUserDefaults } from '../userHooks/userHooks';
import { ILocationStatusResponse } from '../../interfaces/location/location-status-response';
import { LocationAssembler } from '../../assemblers/location/location-assembler';
import { useMemo } from 'react';

// ************************************************************************
// GET LOCATION STATUS
// ************************************************************************
export const useGetLocationStatus = (locationId?: string, argDate?: MomentInput) => {
  const date = useSelector(getSelectedDate);

  const selectedDate = argDate || date;

  const building = useSelector(getUserBuilding);

  const location = locationId || building?.id;

  const endpoint = EndpointActions.GetLocationStatusByIdAndDay([location || '', selectedDate]);
  const { response, loading, error } = useFetch<ILocationStatusResponse>(endpoint, [selectedDate]);
  const locationStatus = useMemo(() => response && LocationAssembler.fromLocationStatus(response), [response]);

  return {
    locationsStatus: response && locationStatus,
    locationsStatusLoading: loading,
    locationsStatusError: error,
  };
};

// ************************************************************************
// GET BUILDING STATUS
// ************************************************************************
export const useGetBuildingStatus = (argDate?: MomentInput, skip = false) => {
  const { location } = useGetUserDefaults();

  const date = useSelector(getSelectedDate);

  const selectedDate = argDate || date;

  const endpoint = EndpointActions.GetBuildingStatusByLocationIdAndDay([location?.id || '', selectedDate]);
  const { response, loading, error } = useFetch<ILocationStatusResponse>(endpoint, [selectedDate], { skip });
  const locationResponse = useMemo(() => response && LocationAssembler.fromLocationStatus(response), [response]);

  return {
    buildingStatus: response && locationResponse,
    buildingStatusLoading: loading,
    buildingStatusError: error,
  };
};
