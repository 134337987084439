import { useEffect, useState } from 'react';

export const useScrollYDistance = (): number => {
  const [state, setState] = useState<number>(window.scrollY);

  useEffect(() => {
    const setter = () => {
      setState(window.scrollY);
    };

    window.addEventListener('scroll', setter);
    return () => window.removeEventListener('scroll', setter);
  }, []);

  return state;
};
