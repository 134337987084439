import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { reservation } from '../../../styles/reservation';
import sharedStyles from '../../../styles/sharedStyles/sharedStyles';
import { theme } from '../../../styles/theme';
import { negativeValue } from '../../../styles/utilities';

const shared = sharedStyles();

export default makeStyles(() =>
  createStyles({
    ...shared,

    container: {
      ...shared.flexColumnJustifySpaceBetweenAlignCenter,
      marginBottom: negativeValue(reservation.marginSpacer),
      width: theme.widthFull,
    },

    backToTop: {
      bottom: 96,
    },
  })
);
