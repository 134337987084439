import makeStyles from '@mui/styles/makeStyles';
import { theme } from '../../../styles/theme';

export interface StyleProps {
  padding: string | number;
  width: string | number;
}

export default makeStyles({
  root: {
    borderRadius: theme.defaultBorderRadius,
    overflow: 'hidden',
    padding: (props: StyleProps) => props.padding,
    width: (props: StyleProps) => props.width,
  },
});
