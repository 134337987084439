import { ClassNameMap } from '@mui/styles';
import classNames from 'classnames';
import React from 'react';
import useStyles from './ReservationCardPeople.style';

let classes: ClassNameMap;

const ReservationCardPeople: React.FC<Props> = ({
  id,
  className,
  generalPeople = [],
  teamSpacePeople = [],
  teamSpaceName,
  ...rest
}) => {
  classes = useStyles();

  return (
    <div id={id} className={classNames(classes.root, className)} {...rest}>
      <h1 className={classes.title}>Reservations for this floor</h1>
      {RenderPeopleUserNames(generalPeople, teamSpacePeople, teamSpaceName)}
    </div>
  );
};

const RenderPeopleUserNames = (generalPeople: string[], teamSpacePeople?: string[], teamSpaceName?: string) => {
  if (!generalPeople || !generalPeople.length) {
    return <span className={classes.usernameListItem}>No reservations on this day yet</span>;
  }
  let filteredPeople = generalPeople;

  if (teamSpacePeople && teamSpacePeople?.length > 0) {
    filteredPeople = generalPeople.filter((person) => !teamSpacePeople?.includes(person));
  }

  const orderTeamSpace = teamSpacePeople?.sort((a, b) => {
    return Intl.Collator('pt').compare(a, b);
  });

  const orderUsername = filteredPeople?.sort((a, b) => {
    return Intl.Collator('pt').compare(a, b);
  });

  return (
    <ul className={classes.usernameList}>
      {orderTeamSpace?.map((username, idx) => (
        <li className={classes.usernameListItem} key={idx}>
          <strong>{username}</strong>
          <span className={classes.teamSpaceName}> {teamSpaceName?.toUpperCase()}</span>
        </li>
      ))}
      {orderUsername.map((username, idx) => (
        <li className={classes.usernameListItem} key={idx}>
          {username}
        </li>
      ))}
    </ul>
  );
};

export type Props = {
  className?: string;
  id?: string;
  generalPeople?: string[];
  teamSpacePeople?: string[];
  teamSpaceName?: string;
};

export default ReservationCardPeople;
