import React from 'react';
import Switch, { SwitchProps, SwitchClassKey } from '@mui/material/Switch';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { theme, muiTheme } from '../../../styles/theme';

interface Styles extends Partial<Record<SwitchClassKey, string>> {
  [x: string]: string | undefined;
}

interface Props extends SwitchProps {
  classes: Styles;
  className?: string;
}

export default withStyles(() =>
  createStyles({
    switchRoot: {
      width: 42,
      height: 19,
      padding: 0,
    },

    switchBase: {
      padding: 1,
      '&$checked': {
        transform: 'translateX(23px)',
        color: theme.colors.white.main,
        '& + $switchTrack': {
          backgroundColor: theme.colors.blue.royal,
          opacity: 1,
          border: 'none',
        },
      },
      '&$focusVisible $switchThumb': {
        color: theme.colors.blue.royal,
        border: `6px solid ${theme.colors.blue.royal}`,
      },
    },

    switchTrack: {
      borderRadius: 26 / 2,
      border: `1px solid ${theme.colors.grey.light}`,
      backgroundColor: theme.colors.grey.light,
      opacity: 1,
      transition: muiTheme.transitions.create(['background-color', 'border']),
    },

    switchThumb: {
      width: 17,
      height: 17,
    },

    checked: {},
    focusVisible: {},
  })
)(({ classes, className, ...props }: Props) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      className={className}
      classes={{
        root: classes.switchRoot,
        switchBase: classes.switchBase,
        track: classes.switchTrack,
        thumb: classes.switchThumb,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});
