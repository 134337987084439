import { Msal2Config } from '@microsoft/mgt-msal2-provider';
import { CacheLocation } from 'msal';
import settings from '../config';

interface Config {
  API_ENDPOINT: string;
  API_SCOPE: string;
  BOOKINGS_LIMIT: number;
  FEATURE_TOGGLE: any;
  MSAL_PROVIDER: Msal2Config;
  REDIRECT_URI: string;
  STAGE: 'localhost' | 'prod' | 'dev';
  TRANSLATIONS_ENDPOINT: string;
  FEATURE_TOGGLE_GUESTS: string;
}

interface EnvironmentVariables {
  API_ENDPOINT: string;
  BOOKINGS_LIMIT?: string;
  FEATURE_TOGGLE: any;
  MSAL_PROVIDER: {
    CLIENT_ID: string;
    AUTHORITY: string;
  };
  REDIRECT_URI: string;
  STAGE: 'localhost' | 'prod' | 'dev';
  TRANSLATIONS_ENDPOINT: string;
  FEATURE_TOGGLE_GUESTS: string;
}

const environmentVariables: EnvironmentVariables = {
  API_ENDPOINT: settings.API_ENDPOINT,
  BOOKINGS_LIMIT: process.env.REACT_APP_BOOKINGS_LIMIT,
  FEATURE_TOGGLE: {
    CREATE_BOOKING_WITH_REASON: false,
    MODAL_STATE: false,
  },
  MSAL_PROVIDER: {
    CLIENT_ID: settings.MSAL_CLIENT_ID,
    AUTHORITY: settings.MSAL_AUTHORITY,
  },
  REDIRECT_URI: settings.REDIRECT_URI,
  STAGE: settings.STAGE,
  TRANSLATIONS_ENDPOINT: settings.TRANSLATIONS_ENDPOINT,
  FEATURE_TOGGLE_GUESTS: settings.FEATURE_TOGGLE_GUESTS,
};

function getConfig(): Config {
  const stage: Config['STAGE'] = (() => {
    return environmentVariables.STAGE;
  })();

  const api_endpoint: Config['API_ENDPOINT'] = (() => {
    return environmentVariables.API_ENDPOINT;
  })();

  const redirect_uri: Config['REDIRECT_URI'] = (() => {
    return environmentVariables.REDIRECT_URI;
  })();

  const bookings_limit: Config['BOOKINGS_LIMIT'] = (() => {
    const envLimit = parseInt(environmentVariables.BOOKINGS_LIMIT || '');
    return Number.isInteger(envLimit) ? envLimit : 30;
  })();

  const localStorage: CacheLocation = 'localStorage';

  const msal_provider: Config['MSAL_PROVIDER'] = (() => {
    return {
      clientId: environmentVariables.MSAL_PROVIDER.CLIENT_ID || '',
      scopes: ['openid', 'profile', 'User.Read', 'User.ReadBasic.All', 'email'],
      options: {
        auth: {
          authority: environmentVariables.MSAL_PROVIDER.AUTHORITY || '',
          clientId: environmentVariables.MSAL_PROVIDER.CLIENT_ID || '',
          validateAuthority: false,
          navigateToLoginRequestUrl: false,
          redirectUri: redirect_uri,
        },
        cache: {
          storeAuthStateInCookie: false,
          cacheLocation: localStorage,
        },
      },
    };
  })();

  const api_scope: Config['API_SCOPE'] = (() => {
    return `api://${environmentVariables.MSAL_PROVIDER.CLIENT_ID || ''}/.default`;
  })();

  const translations_endpoint: Config['TRANSLATIONS_ENDPOINT'] = (() => {
    return environmentVariables.TRANSLATIONS_ENDPOINT;
  })();

  const guests: Config['FEATURE_TOGGLE_GUESTS'] = (() => {
    return environmentVariables.FEATURE_TOGGLE_GUESTS;
  })();

  return {
    API_ENDPOINT: api_endpoint,
    API_SCOPE: api_scope,
    BOOKINGS_LIMIT: bookings_limit,
    FEATURE_TOGGLE: environmentVariables.FEATURE_TOGGLE,
    MSAL_PROVIDER: msal_provider,
    REDIRECT_URI: redirect_uri,
    STAGE: stage,
    TRANSLATIONS_ENDPOINT: translations_endpoint,
    FEATURE_TOGGLE_GUESTS: guests,
  };
}

const config: Config = getConfig();

export default config;
