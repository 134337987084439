import React, { useEffect, useState } from 'react';
import { isEqual } from 'lodash';
import classNames from 'classnames';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { DateRangePickerProps } from '@wojtekmaj/react-daterange-picker';
import useStyles from '../Report/Report.style';
import BackToTopButton from '../../Shared/BackToTopButton/BackToTopButton';
import DateRangePicker from '../../Shared/DateRangePicker/DateRangePicker';
import { DEFAULT_DAYS } from '../../../constants/dateFormat';
import config from '../../../config/config';
import { useGetReportBookings } from '../../../hooks/bookingHooks/bookingHooks';
import { BookingReport } from '../../../models/bookingReport/bookingReport';
import i18n from '../../../translations/i18n';
import { useTranslation } from 'react-i18next';
import { ReactComponent as EngineTypeCombustion } from '../../../assets/svg/icon_local_gas_station_bold.svg';
import { ReactComponent as EngineTypeElectric } from '../../../assets/svg/icon_electrical_services_bold.svg';
import { EngineTypeEnum } from '../../../enums/engineType.enum';

const Report: React.FC = () => {
  const classes = useStyles();
  const currentDay = new Date();
  const nextMonth = new Date(currentDay.getTime() + DEFAULT_DAYS);
  const [date, changeDate] = useState<DateRangePickerProps['value']>([currentDay, nextMonth]);
  const [page, setPage] = useState<number>(0);
  const { bookings } = useGetReportBookings(date as Date[], page);
  const [reportBookings, setReportBookings] = useState<BookingReport[]>([]);
  const [showLoadMoreButton, setShowLoadMoreButton] = useState<boolean>(false);
  void i18n.loadNamespaces(['garages', 'report']);
  const { t } = useTranslation('garages');

  useEffect(() => {
    setPage(0);
    setReportBookings([]);
    setShowLoadMoreButton(true);
  }, [date]);

  useEffect(() => {
    if (bookings?.length && !reportBookings.some((b) => isEqual(b, bookings[0]))) {
      setReportBookings([...reportBookings, ...bookings]);
    }
    if (bookings && bookings.length < 15) {
      setShowLoadMoreButton(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookings]);

  const loadMoreData = () => {
    setPage(page + 1);
  };

  return (
    <>
      <Grid component='main' container justifyContent='center' className={classes.container}>
        <Grid item xs={12}>
          <Typography className={classes.pageTitle}>{t('report:report.pageTitle')}</Typography>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.marginTop}>
            <DateRangePicker value={date} onChange={changeDate} />
          </div>
        </Grid>
        <Grid item xs={12}>
          <TableContainer
            className={classNames(classes.marginTop, classes.flexWrap, classes.flexColumnAlignCenterJustifyCenter)}
            component={Paper}
          >
            <Table className={classes.table} aria-label='customized table'>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableHead} align='center'>
                    Email
                  </TableCell>
                  <TableCell className={classes.tableHead} align='center'>
                    Username
                  </TableCell>
                  <TableCell className={classes.tableHead} align='center'>
                    Office
                  </TableCell>
                  <TableCell className={classes.tableHead} align='center'>
                    Floor
                  </TableCell>
                  <TableCell className={classes.tableHead} align='center'>
                    Date
                  </TableCell>
                  {config.FEATURE_TOGGLE.CREATE_BOOKING_WITH_REASON && (
                    <TableCell className={classes.tableHead} align='center'>
                      Reason
                    </TableCell>
                  )}
                  <TableCell className={classes.tableHead} align='center' title='License Plate'>
                    Plate
                  </TableCell>
                  <TableCell className={classes.tableHead} align='center'>
                    Garage
                  </TableCell>
                  <TableCell className={classes.tableHead} align='center'>
                    Engine Type
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {reportBookings?.map((row, idx) => (
                  <TableRow key={idx}>
                    <TableCell className={classes.tableRow} component='th' scope='row'>
                      {row.email}
                    </TableCell>
                    <TableCell className={classes.tableRow} align='center'>
                      {row.username}
                    </TableCell>
                    <TableCell className={classes.tableRow} align='center'>
                      {row.office}
                    </TableCell>
                    <TableCell className={classes.tableRow} align='center'>
                      {row.floor}
                    </TableCell>
                    <TableCell className={classes.tableRow} align='center'>
                      {row.date}
                    </TableCell>
                    {config.FEATURE_TOGGLE.CREATE_BOOKING_WITH_REASON && (
                      <TableCell className={classes.tableRow} align='center'>
                        {row.reason}
                      </TableCell>
                    )}
                    <TableCell className={classes.tableRow} align='center'>
                      {row?.licensePlate}
                    </TableCell>
                    <TableCell className={classes.tableRow} align='center'>
                      {t(row?.garage)}
                    </TableCell>
                    <TableCell className={classes.tableRow} align='center'>
                      {row?.engineType === EngineTypeEnum.COMBUSTION ? (
                        <EngineTypeCombustion />
                      ) : (
                        <EngineTypeElectric />
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              {showLoadMoreButton && (
                <TableFooter>
                  <TableRow key='loadMoreTableButton' onClick={loadMoreData}>
                    <TableCell className={classes.loadMoreTableButton} component='td' scope='row' colSpan={8}>
                      Load More
                    </TableCell>
                  </TableRow>
                </TableFooter>
              )}
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <BackToTopButton />
    </>
  );
};

export default Report;
