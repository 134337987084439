import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import moment from 'moment';
import IconButton from '@mui/material/IconButton';
import useStyles from './DateSlider.style';
import { ReactComponent as Chevron } from '../../../../assets/svg/icon_chevron.svg';
import { useDebouncedEffect } from '../../../../hooks/customHooks/useDebouncedEffect';
import { selectDate } from '../../../../redux/actions/appActions/appActions';
import { defaultDebounceTime } from '../../../../utils/constants';

enum operationEnum {
  ADD = 'add',
  SUBTRACT = 'subtract',
}

const DateSlider: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [sliderDate, setSliderDate] = useState(moment());
  const [wasClicked, setWasClicked] = useState(false); // to avoid initial dispatch of date

  const onChangeDate = (operation: operationEnum) => {
    !wasClicked && setWasClicked(true);
    operation === operationEnum.ADD
      ? setSliderDate(sliderDate.add(1, 'days').clone())
      : setSliderDate(sliderDate.subtract(1, 'days').clone());
  };

  const dateDispatch = () => wasClicked && dispatch(selectDate(moment(sliderDate).toDate()));
  useDebouncedEffect(dateDispatch, [sliderDate], defaultDebounceTime);

  return (
    <>
      <div className={classes.dateSliderContainer}>
        <IconButton
          aria-label='menu'
          size={'medium'}
          onClick={() => onChangeDate(operationEnum.SUBTRACT)}
          classes={{
            disabled: classes.buttonDisabled,
          }}
        >
          <Chevron className={classes.chevron} />
        </IconButton>

        <div className={classes.date}>{sliderDate.format('D MMM, YYYY')}</div>

        <IconButton aria-label='menu' size={'medium'} onClick={() => onChangeDate(operationEnum.ADD)}>
          <Chevron className={classNames(classes.chevron, classes.flip)} />
        </IconButton>
      </div>
      <div className={classes.weekdayContainer}>{sliderDate.format('dddd')}</div>
    </>
  );
};

export default DateSlider;
