import { useMemo } from 'react';
import { EndpointActions, useFetch } from '../ajaxHooks/ajaxHooks';
import { PlaceAndParkingStatusAssembler } from '../../assemblers/placeAndParkingStatus/place-and-parking-status-assembler';
import { IPlaceAndParkingStatusResponse } from '../../interfaces/placeAndParkingStatus/place-and-parking-status-response';

// ************************************************************************
// GET PLACE AND PARKING STATUS
// ************************************************************************
export const useGetStatusByIdAndDates = (placeId: string | undefined, dates: string[]) => {
  const endpoint = EndpointActions.GetPlaceStatusByIdAndDates([placeId || '', dates]);
  const { run, response, loading, error } = useFetch<IPlaceAndParkingStatusResponse[]>(endpoint, [dates, placeId], {
    defer: true,
  });

  const placeAndParkingStatus = useMemo(
    () => response && PlaceAndParkingStatusAssembler.fromList(response),
    [response]
  );

  return {
    getPlaceAndParkingStatus: run,
    placeAndParkingStatus: response && placeAndParkingStatus,
    placeAndParkingStatusLoading: loading,
    placeAndParkingStatusError: error,
  };
};
