import React, { ChangeEvent, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { times } from 'lodash';
import Typography from '@mui/material/Typography';
import useStyles from './TeamSpaceManager.style';
import { ReservationContext } from '../ReservationContext/ReservationContext';
import {
  addError,
  removeError,
  resetDefaultLicensePlateAndEngineType,
  setCanContinue,
  setEngineType,
  setExistingTeamSpaceMembers,
  setLicensePlate,
  setTeamSpaceMembers,
  setTeamSpaceMembersParking,
  validateLicensePlateAndEngineType,
} from '../ReservationContext/ReservationReducer';
import { useGetUserDefaults, useGetUserInformation } from '../../../../../hooks/userHooks/userHooks';
import Checkbox from '../../../../Shared/Checkbox/Checkbox';
import BlueRoyalSwitch from '../../../../Shared/ToggleSwitch/ToggleSwitch';
import LicensePlateAndEngineType from '../../../../Shared/LicensePlateAndEngineType/LicensePlateAndEngineType';
import PeoplePicker from '../../../../Shared/PeoplePicker/PeoplePicker';
import StaticPeoplePicker from '../../../../Shared/PeoplePicker/StaticPeoplePicker';
import { ReactComponent as IconWarning } from '../../../../../assets/svg/icon_warning.svg';
import { TeamSpaceMemberRequestAssembler } from '../../../../../assemblers/teamSpaceMember/team-space-member-request-assembler';
import { TeamSpaceUtil } from '../../../../../utils/teamSpace/team-space-util';
import { ReactComponent as IconDelete } from '../../../../../assets/svg/icon_bin.svg';
import { ReservationErrorEnum } from '../../../../../enums/reservationWizard.enum';
import i18n from '../../../../../translations/i18n';

const localText = {
  PEOPLE_PICKER_KEY_PREFIX: 'people-picker-',
  PEOPLE_PICKER_KEY_SELF: 0,
};

const TeamSpaceManager: React.FC<Props> = ({ editMode = false }) => {
  const classes = useStyles();
  const { email, licensePlate: defaultLicensePlate, engineType: defaultEngineType } = useGetUserDefaults();
  const { state, dispatch } = useContext(ReservationContext);
  const { date, engineType, existingTeamSpaceMembers, licensePlate, place, teamSpaceMembers, teamSpaceMembersParking } =
    state;
  const maxCapacity: number = useMemo(() => place?.maxCapacity || 0, [place]);
  const minimumCapacity: number = useMemo(() => place?.minimumCapacity || 0, [place]);

  void i18n.loadNamespaces('reservationWizard');
  const { t } = useTranslation('reservationWizard');

  const [additionalPickersCounter, setAdditionalPickersCounter] = useState(0);
  const [additionalPickerElements, setAdditionalPickerElements] = useState<number[]>([]);
  const { getUserInformation, userInformation } = useGetUserInformation(
    date,
    TeamSpaceMemberRequestAssembler.fromList(teamSpaceMembers)
  );

  const memoizedGetParkingInfo = useCallback(() => {
    void getUserInformation();
  }, [getUserInformation]);

  const compiledTeamSpaceMembersInfo = useMemo(
    () =>
      userInformation &&
      TeamSpaceUtil.compileTeamSpaceMembersInfo(teamSpaceMembers, teamSpaceMembersParking, userInformation),
    [teamSpaceMembers, teamSpaceMembersParking, userInformation]
  );

  useEffect(() => {
    teamSpaceMembers.length > 0 && void memoizedGetParkingInfo();
    // this should only be dependent on teamSpaceMembers
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, teamSpaceMembers]);

  useEffect(() => {
    !editMode && dispatch(setTeamSpaceMembers([{ person: [{ userPrincipalName: email }], index: 0 }]));
  }, [dispatch, editMode, email]);

  useEffect(() => {
    const error = {
      kind: ReservationErrorEnum.MINIMUM_CAPACITY,
      message: t('teamSpaceManager.teamSpaceCapacity', { count: minimumCapacity }),
    };
    if (existingTeamSpaceMembers.length + teamSpaceMembers.length < minimumCapacity) {
      dispatch(addError(error));
    } else {
      dispatch(removeError(error));
      dispatch(setCanContinue(true));
    }
  }, [dispatch, existingTeamSpaceMembers.length, minimumCapacity, t, teamSpaceMembers.length]);

  const hasOverlappedBookings = (index: number) =>
    userInformation &&
    TeamSpaceUtil.getOverlappedBookingsByEmail(
      userInformation,
      TeamSpaceUtil.getTeamSpaceMemberByIndex(teamSpaceMembers, index)?.person?.[0]?.userPrincipalName ?? ''
    );

  const handlePeopleSelected = (e: any, index: number) => {
    const foundIndex = teamSpaceMembers.findIndex((teamMember) => teamMember.index === index);
    const foundIndexParking = teamSpaceMembersParking.findIndex(
      (teamMemberParking) => teamMemberParking.index === index
    );

    // if it's new, then append it
    if (foundIndex === -1) {
      e && dispatch(setTeamSpaceMembers([...teamSpaceMembers, { person: [...e.target.selectedPeople], index }]));
      // if it already exists, then delete at index
    } else {
      e && dispatch(setTeamSpaceMembers(teamSpaceMembers.filter((_teamMember, idx) => idx !== foundIndex)));
      e &&
        dispatch(
          setTeamSpaceMembersParking([
            ...teamSpaceMembersParking.slice(0, foundIndexParking),
            ...teamSpaceMembersParking.slice(foundIndexParking + 1),
          ])
        );
    }
  };

  const handleCheckbox = (e: ChangeEvent<HTMLInputElement>, index: number) => {
    const existingMemberIndex = existingTeamSpaceMembers.findIndex((_teamSpaceMember, idx) => idx === index);
    const foundIndex = teamSpaceMembersParking.findIndex((teamMemberParking) => teamMemberParking.index === index);

    if (existingMemberIndex >= 0) {
      e &&
        dispatch(
          setExistingTeamSpaceMembers([
            ...existingTeamSpaceMembers.slice(0, existingMemberIndex),
            { ...existingTeamSpaceMembers[existingMemberIndex], needParkingSpace: e.target.checked },
            ...existingTeamSpaceMembers.slice(existingMemberIndex + 1),
          ])
        );
    } else {
      // if it's new, then append it
      if (foundIndex === -1) {
        e &&
          dispatch(
            setTeamSpaceMembersParking([...teamSpaceMembersParking, { index, needParkingSpace: e.target.checked }])
          );
        // if it already exists, then replace at same index
      } else {
        e &&
          dispatch(
            setTeamSpaceMembersParking([
              ...teamSpaceMembersParking.slice(0, foundIndex),
              { index, needParkingSpace: e.target.checked },
              ...teamSpaceMembersParking.slice(foundIndex + 1),
            ])
          );
      }
    }

    if (index === localText.PEOPLE_PICKER_KEY_SELF) {
      !e.target.checked &&
        dispatch(
          resetDefaultLicensePlateAndEngineType({ licensePlate: defaultLicensePlate, engineType: defaultEngineType })
        );
      dispatch(
        validateLicensePlateAndEngineType({
          shouldValidate: e.target.checked,
          errorMessage: t('tooltipMessage.invalidLicensePlateOrEngineType'),
        })
      );
    }
  };

  function validateAdditionalElementsQty() {
    if (editMode) {
      return maxCapacity && additionalPickerElements.length + 1 > maxCapacity - existingTeamSpaceMembers.length;
    }
    return maxCapacity && additionalPickerElements.length + 1 > maxCapacity - minimumCapacity;
  }

  const handleAddMemberButton = () => {
    if (validateAdditionalElementsQty()) {
      return;
    }

    if (editMode) {
      setAdditionalPickerElements((previous) => [...previous, additionalPickersCounter + (maxCapacity ?? 100)]);
    } else {
      setAdditionalPickerElements((previous) => [...previous, additionalPickersCounter + (minimumCapacity ?? 100)]);
    }
    setAdditionalPickersCounter((previous) => previous + 1);
  };

  const handleTrashBinButton = (index: number) => {
    const existingMemberIndex = existingTeamSpaceMembers.findIndex((_teamSpaceMember, idx) => idx === index);
    if (existingMemberIndex >= 0) {
      dispatch(setExistingTeamSpaceMembers(existingTeamSpaceMembers.filter((_, idx) => idx !== index)));
    } else {
      dispatch(setTeamSpaceMembers(teamSpaceMembers.filter((teamMember) => teamMember.index !== index)));
      dispatch(
        setTeamSpaceMembersParking(
          teamSpaceMembersParking.filter((teamMemberParking) => teamMemberParking.index !== index)
        )
      );
      setAdditionalPickerElements((previous) => previous.filter((element) => element !== index));
    }
  };

  const renderCheckbox = ({
    index,
    isStatic = false,
    checked = false,
    disabled = false,
  }: {
    index: number;
    isStatic?: boolean;
    checked?: boolean;
    disabled?: boolean;
  }) => {
    const member = TeamSpaceUtil.getTeamSpaceMemberByIndex(teamSpaceMembers, index);
    const existingMember = existingTeamSpaceMembers[index];
    // to avoid flashing between the warning and the checkbox
    const disableCheckbox = editMode ? !existingMember && !member : !member;

    const hasDefaultSettings =
      TeamSpaceUtil.getHasParkingInformationByEmail(userInformation ?? [], TeamSpaceUtil.getTeamMemberEmail(member)) ??
      true;

    if (disableCheckbox) {
      return (
        <div className={classes.parkingWarningCheckboxColumn}>
          <Checkbox name={index as unknown as string} disabled checked={editMode && checked} />
        </div>
      );
    }

    return (
      <div
        className={classNames(
          hasOverlappedBookings(index) && classes.iconMarginBottom,
          classes.parkingWarningCheckboxColumn
        )}
      >
        {hasDefaultSettings || isStatic ? (
          <>
            <Checkbox
              name={index as unknown as string}
              disabled={disabled || (hasOverlappedBookings(index) ?? false)}
              onChange={(e) => handleCheckbox(e, index)}
              checked={
                teamSpaceMembersParking.find((memberParking) => memberParking.index === index)?.needParkingSpace ||
                checked ||
                false
              }
            />
          </>
        ) : (
          <IconWarning className={classes.warning} />
        )}
      </div>
    );
  };

  const renderDefaultSettingsMessage = () => {
    return (
      <div>
        {
          // this message should only appear if there is new team space members added
          !TeamSpaceUtil.hasEveryUserParkingInformation(userInformation) && teamSpaceMembers.length > 0 && (
            <div className={classes.parkingWarningWrapper}>
              <Typography variant={'caption'} className={classes.parkingWarningLabel}>
                <IconWarning className={classNames(classes.parkingWarningIcon, classes.warning)} />
                {t('teamSpaceManager.parkingWarning')}
              </Typography>
            </div>
          )
        }
      </div>
    );
  };

  const renderTrashBin = (index: number) => {
    return (
      <button
        className={classNames(
          hasOverlappedBookings(index) && classes.iconMarginBottom,
          classes.deleteButtonContainer,
          classes.gridColumnStartOne
        )}
        onClick={() => handleTrashBinButton(index)}
      >
        <IconDelete />
      </button>
    );
  };

  const renderAddMemberButton = (isDisabled?: boolean) => {
    const shouldRender = editMode
      ? +additionalPickerElements.length + existingTeamSpaceMembers.length < maxCapacity
      : +additionalPickerElements.length < maxCapacity - minimumCapacity;
    return (
      shouldRender && (
        <button
          className={classNames(classes.addMemberButtonContainer, classes.gridColumnStartTwo)}
          onClick={handleAddMemberButton}
          disabled={isDisabled}
        >
          {t('teamSpaceManager.addMember')}
        </button>
      )
    );
  };

  const renderStaticPickerGroup = ({
    index,
    userId = email,
    username,
    hasParking = false,
  }: {
    index: number;
    userId?: string | null;
    username?: string | null;
    hasParking?: boolean;
  }) => {
    const isItMe = email === userId;
    return (
      <React.Fragment key={`${localText.PEOPLE_PICKER_KEY_PREFIX}${index}`}>
        {!isItMe && renderTrashBin(index)}
        <StaticPeoplePicker
          user={username}
          hasOverlappedBookings={!editMode && (hasOverlappedBookings(index) ?? false)}
          className={classes.gridColumnStartTwo}
        />
        <div>{renderCheckbox({ index, isStatic: editMode, checked: hasParking, disabled: !isItMe })}</div>

        {isItMe && hasParking && editMode && (
          <LicensePlateAndEngineType
            className={classes.licensePlateAndEngineType}
            engineType={engineType}
            licensePlate={licensePlate}
            setEngineType={(type) => {
              dispatch(setEngineType(type));
              dispatch(
                validateLicensePlateAndEngineType({
                  shouldValidate: true,
                  errorMessage: t('tooltipMessage.invalidLicensePlateOrEngineType'),
                })
              );
            }}
            setLicensePlate={(l) => {
              dispatch(setLicensePlate(l));
              dispatch(
                validateLicensePlateAndEngineType({
                  shouldValidate: true,
                  errorMessage: t('tooltipMessage.invalidLicensePlateOrEngineType'),
                })
              );
            }}
          />
        )}
      </React.Fragment>
    );
  };

  const renderPickerGroup = ({ index, hasTrashBin = false }: { index: number; hasTrashBin?: boolean }) => (
    <React.Fragment key={`${localText.PEOPLE_PICKER_KEY_PREFIX}${index}`}>
      {hasTrashBin && <div>{renderTrashBin(index)}</div>}
      <PeoplePicker
        handlePeopleSelected={(e) => handlePeopleSelected(e, index)}
        hasOverlappedBookings={hasOverlappedBookings(index) ?? false}
        className={classes.gridColumnStartTwo}
      />
      <div>{renderCheckbox({ index })}</div>
    </React.Fragment>
  );

  return (
    <>
      <Typography className={classNames(classes.chooseTeamLabel)}>
        <Trans components={{ 1: <strong /> }} values={{ count: maxCapacity }} t={t} i18nKey={'teamSpaceManager.team'} />
      </Typography>
      <Typography variant={'caption'} className={classes.textContent} gutterBottom>
        {t('teamSpaceManager.teamSpaceCapacity', { count: minimumCapacity })}
      </Typography>
      {renderDefaultSettingsMessage()}
      {email && (
        <>
          <div className={classes.peoplePickerWrapper}>
            <div className={classNames(classes.peoplePickerHeading, classes.gridColumnStartTwo)}>
              <span>{t('teamSpaceManager.name')}</span>
              <span className={classes.textContent}>
                <Trans
                  i18nKey='teamSpaceManager.occupation'
                  values={{ used: existingTeamSpaceMembers.length + teamSpaceMembers.length, count: maxCapacity || 0 }}
                  t={t}
                />
              </span>
            </div>
            <div className={classes.peoplePickerHeadingPark}>{<span>{t('teamSpaceManager.park')}</span>}</div>

            {editMode ? (
              existingTeamSpaceMembers.map((member, index) =>
                renderStaticPickerGroup({
                  index,
                  userId: member.person?.[0].userPrincipalName,
                  username: member.person?.[0].displayName,
                  hasParking: member.needParkingSpace,
                })
              )
            ) : (
              <>
                {renderStaticPickerGroup({
                  index: localText.PEOPLE_PICKER_KEY_SELF,
                  hasParking:
                    teamSpaceMembersParking.find(
                      (teamMemberParking) => teamMemberParking.index === localText.PEOPLE_PICKER_KEY_SELF
                    )?.needParkingSpace ?? false,
                })}
                {maxCapacity && (
                  <>
                    {times(minimumCapacity - 1, (idx) => {
                      const index = idx + 1;
                      return renderPickerGroup({ index });
                    })}
                  </>
                )}
              </>
            )}
            {additionalPickerElements.map((element) => renderPickerGroup({ index: element, hasTrashBin: true }))}
            {renderAddMemberButton(!editMode && teamSpaceMembers.length < minimumCapacity)}
          </div>
        </>
      )}
    </>
  );
};

interface Props {
  editMode?: boolean;
}

export default TeamSpaceManager;
