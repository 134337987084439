import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import sharedStyles from '../../../../../styles/sharedStyles/sharedStyles';
import { reservation, stepInfo, stepTitle, textContent } from '../../../../../styles/reservation';
import { muiTheme, theme } from '../../../../../styles/theme';

const shared = sharedStyles();

export default makeStyles(() =>
  createStyles({
    ...shared,
    ...stepInfo,
    ...stepTitle,
    ...textContent,

    widthFull: {
      width: theme.widthFull,
    },

    gridWrapper: {
      ...shared.gap16,
      padding: '32px 24px',
      justifyContent: 'space-evenly',
      flexWrap: 'unset',
      maxWidth: reservation.containerWidth,
      overflow: 'hidden',
      [muiTheme.breakpoints.down('md')]: {
        ...shared.flexColumn,
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'unset',
      },
      [muiTheme.breakpoints.up('md')]: {
        maxHeight: `calc(${theme.heightFull} - 88px)`, // 88px from the sticky wrapper
        overflow: 'auto',
      },
    },

    leftSide: {
      ...shared.flexColumnAlignCenter,
      flex: '1 3 auto',
      maxWidth: reservation.leftColumnMaxWidth,
      overflowY: 'auto',
      '&::-webkit-scrollbar': {
        width: 27,
        height: 18,
      },
      '&::-webkit-scrollbar-thumb': {
        height: 6,
        border: '10px solid rgba(0, 0, 0, 0)',
        backgroundClip: 'padding-box',
        '-webkit-border-radius': '14px',
        backgroundColor: 'rgba(178,180,182)',
      },
      [muiTheme.breakpoints.down('md')]: {
        width: theme.widthFull,
        maxWidth: reservation.rightColumnMaxWidth,
      },
    },

    stepContentWrapper: {
      width: theme.widthFull,
      [muiTheme.breakpoints.up('md')]: {
        paddingBottom: reservation.marginSpacer,
      },
    },

    informationSide: {
      maxWidth: reservation.rightColumnMaxWidth,
      width: theme.widthFull,
      flex: '1 2 auto',
      overflowY: 'auto',
      '&::-webkit-scrollbar': {
        width: 27,
        height: 18,
      },
      '&::-webkit-scrollbar-thumb': {
        height: 6,
        border: '10px solid rgba(0, 0, 0, 0)',
        backgroundClip: 'padding-box',
        '-webkit-border-radius': '14px',
        backgroundColor: 'rgba(178,180,182)',
      },
      [muiTheme.breakpoints.down('md')]: {
        margin: '20px 0',
      },
    },

    cardContent: {
      ...shared.flexJustifyCenter,
      alignItems: 'center',
      background: theme.colors.blue.lightest,
      width: theme.widthFull,
      maxWidth: 520,
      height: 120,
      marginBottom: muiTheme.spacing(4),
      [muiTheme.breakpoints.down('lg')]: {
        margin: `0 auto ${muiTheme.spacing(4)}px`,
      },
    },

    reservationCardsContainer: {
      marginBottom: reservation.marginSpacer,
      width: theme.widthFull,
      [muiTheme.breakpoints.only('xs')]: {
        maxHeight: 'unset',
      },
    },

    reservationCardsContainerWithoutEditMode: {
      width: theme.widthFull,
      maxHeight: theme.heightFull,
    },

    reservationCardsArea: {
      ...shared.flexColumnAlignCenter,
      ...shared.gap24,
      marginTop: 8,
    },

    noDaysSelected: {
      margin: 24,
    },
  })
);
