import React from 'react';
import { Login as MSLogin } from '@microsoft/mgt-react';
import Container from '@mui/material/Container';
import useStyles from './Login.style';
//import { ReactComponent as IllustrationLogin } from './../../../assets/svg/illustration_login.svg';
//import { ReactComponent as IllustrationLogin } from './../../../assets/svg/illustration_login_csw.svg';
import { ReactComponent as IllustrationLogin } from './../../../assets/svg/illustration_login_csw_empty.svg';
//import { ReactComponent as LogoCtw } from './../../../assets/svg/logo_ctw.svg';
import { ReactComponent as LogoCtw } from './../../../assets/svg/logo_empty.svg';
//import { ReactComponent as LogoWally } from './../../../assets/svg/logo_wally-notitle.svg';
import { ReactComponent as LogoWally } from './../../../assets/svg/logo_wally_csw.svg';

import { ReactComponent as LogoWallyTitle } from './../../../assets/svg/logo_wally-title.svg';

const Login: React.FC = () => {
  const classes = useStyles();

  return (
    <Container component='main' maxWidth='xs' className={classes.container}>
      <div className={classes.flexColumnAlignCenter}>
        <LogoWally className={classes.logoWally} />
        <LogoWallyTitle className={classes.logoWallyTitle} />
        <IllustrationLogin className={classes.marginBottom3} />
        <MSLogin className={classes.loginButton} />
        <LogoCtw />
      </div>
    </Container>
  );
};

export default Login;
