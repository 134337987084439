import { Parking } from '../../models/parking/parking';
import { PlaceAndParkingStatus } from '../../models/placeAndParkingStatus/placeAndParkingStatus';
import Formatters from '../../services/Formatters/Formatters';
import { GarageTypeEnum } from '../../enums/garageType.enum';

interface DayAndParkingQty {
  day: string;
  quantity: number;
}

export class ParkingLotsUtil {
  static isParkingLotsFull = (parkingLots: Parking[] | undefined, parkingDate: string | undefined) => {
    return parkingLots?.every((p: Parking) => {
      let condition = (p?.currentOccupation ?? 0) >= (p?.maxCapacity ?? 0);
      if (p.garageType === GarageTypeEnum.EXTERNAL_GARAGE) {
        condition = condition || parkingDate === Formatters.formatDateISOYearMonthDay(new Date());
      }
      return condition;
    });
  };

  static availableParkingPlaces = (parkingLots: Parking[] | undefined, parkingDate: string | undefined): number => {
    return (
      parkingLots?.reduce<number>((result, current) => {
        let delta = (current.maxCapacity ?? 0) - (current.currentOccupation ?? 0);
        if (
          current.garageType === GarageTypeEnum.EXTERNAL_GARAGE &&
          parkingDate === Formatters.formatDateISOYearMonthDay(new Date())
        ) {
          delta = 0;
        }
        return result + delta;
      }, 0) || 0
    );
  };

  static reduceParkingLots = (parkingLots: Parking[] | undefined) => {
    return (
      parkingLots?.reduce<Parking[]>((result, parking) => {
        return parking.parkingLots ? [...result, ...parking.parkingLots] : [...result];
      }, []) || []
    );
  };

  static daysWithNotEnoughParkingPlaces = (
    placeAndParkingStatus: PlaceAndParkingStatus[] | null,
    parkingPlacesNeeded = 1
  ): DayAndParkingQty[] => {
    return (
      placeAndParkingStatus?.reduce<DayAndParkingQty[]>((result, current) => {
        const availableParkingPlaces = ParkingLotsUtil.availableParkingPlaces(current.parkingLots, current.date);
        const unavailableParkingPlaces = availableParkingPlaces - parkingPlacesNeeded;
        return unavailableParkingPlaces < 0 && current.date
          ? [...result, { day: current.date, quantity: Math.abs(unavailableParkingPlaces) }]
          : [...result];
      }, []) || []
    );
  };

  static formatDaysWithFullyBookedParkMessage = (
    initialString: string,
    daysWithFullyBookedPark: DayAndParkingQty[]
  ): string => {
    return daysWithFullyBookedPark.reduce<string>((result, current, idx, array) => {
      let message = result;
      if (array.length > 3) {
        message = 'in some of the selected days.';
      } else {
        idx !== 0 && idx < array.length && (message = result + ', ');
        message = message + `by ${current.quantity} ${current.quantity === 1 ? 'place' : 'places'} on ${current.day}`;
      }
      return message;
    }, initialString);
  };
}
