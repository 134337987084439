import { IUserReportResponse } from '../../interfaces/user/user-report-response';
import { UserReport } from '../../models/user/userReport';

export class UserReportAssembler {
  static from(userReportResponse: IUserReportResponse): UserReport {
    return {
      name: userReportResponse.name,
      email: userReportResponse.email,
      licensePlate: userReportResponse.licensePlate,
      engineType: userReportResponse.engineType,
    };
  }

  static fromList(userReportResponseList: IUserReportResponse[]): UserReport[] {
    return userReportResponseList?.map((userReportResponse) => this.from(userReportResponse));
  }
}
