import { AppAction } from '../../actions/appActions/appActions';
import { EngineTypeEnum } from '../../../enums/engineType.enum';
import { Location } from '../../../models/location/location';
import {
  SELECT_BUILDING_SUCCESS,
  SELECT_DATE_SUCCESS,
  SET_CONSENT_STATE,
  SET_LICENSE_PLATE,
  SET_MODAL_STATE,
  SET_ENGINE_TYPE,
} from '../../constants/appConstants';
import config from '../../../config/config';
import { ConsentTypeEnum } from '../../../enums/consentType.enum';

export interface AppState {
  selectedDate: Date;
  useParking: boolean;
  licensePlate?: string;
  selectedBuilding?: Location;
  modalState?: boolean;
  engineType?: EngineTypeEnum;
  consentState?: ConsentTypeEnum;
}

const initialAppState: AppState = {
  selectedDate: new Date(),
  useParking: false,
  modalState: config.FEATURE_TOGGLE.MODAL_STATE,
  consentState: ConsentTypeEnum.UNKNOWN,
};

const appReducer = (state: AppState = initialAppState, action: AppAction): AppState => {
  switch (action.type) {
    case SELECT_BUILDING_SUCCESS:
      return {
        ...state,
        selectedBuilding: action.building,
      };
    case SET_LICENSE_PLATE: {
      return {
        ...state,
        licensePlate: action.licensePlate,
        useParking: true,
      };
    }
    case SELECT_DATE_SUCCESS:
      return {
        ...state,
        selectedDate: action.selectedDate,
      };
    case SET_MODAL_STATE:
      return {
        ...state,
        modalState: action.modalState,
      };
    case SET_CONSENT_STATE:
      return {
        ...state,
        consentState: action.consentState,
      };
    case SET_ENGINE_TYPE:
      return {
        ...state,
        engineType: action.engineType,
        useParking: true,
      };
    default:
      return state;
  }
};

export default appReducer;
