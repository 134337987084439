import { IBookingResponse } from '../../interfaces/booking/booking-response';
import { Booking } from '../../models/booking/booking';

export class BookingAssembler {
  static from(bookingResponse: IBookingResponse): Booking {
    return {
      id: bookingResponse.id,
      user: bookingResponse.user,
      location: bookingResponse.location,
      building: bookingResponse.building,
      place: bookingResponse.place,
      parking: bookingResponse.parking,
      date: bookingResponse.date,
      reason: bookingResponse.reason,
      bookingsInfo: bookingResponse.bookingsInfo,
    };
  }

  static fromList(bookingResponseList: IBookingResponse[] | null): Booking[] {
    return bookingResponseList?.map((bookingResponse) => this.from(bookingResponse)) || [];
  }

  static fromListWithOrder(bookingResponseList: IBookingResponse[] | null, userId?: string): Booking[] {
    const list = this.fromList(bookingResponseList);
    if (userId) {
      const foundIndex = list.findIndex((b) => b.user?.email === userId);
      if (foundIndex !== -1) {
        const element = list[foundIndex];
        list.splice(foundIndex, 1);
        list.unshift(element);
      }
    }
    return list;
  }
}
