import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import Container from '@mui/material/Container';
import Drawer from '@mui/material/Drawer';
import useMediaQuery from '@mui/material/useMediaQuery';
import useStyles from './Layout.style';
import SideMenu from './SideMenu/SideMenu';
import TopBar from './TopBar/TopBar';
import { mobileQuery } from '../../../constants/mediaQuery';
import { useLoggedUser } from '../../../hooks/userHooks/userHooks';
import { clearStore } from '../../../redux/store/configureStore';

const Layout: React.FC<Props> = ({ children }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [isMenuOpen, setMenuOpen] = useState<boolean>(false);
  const isMobile = useMediaQuery(mobileQuery);
  const { loggedUser, loggedUserError } = useLoggedUser();

  useEffect(() => {
    if (loggedUserError) {
      clearStore();
      navigate(0);
    }
  }, [loggedUser, loggedUserError, navigate]);

  const toggleMenu = (state: boolean) => (event: any) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setMenuOpen(state);
  };

  const openMenu = () => {
    setMenuOpen(true);
  };

  return (
    <Container className={classes.container} data-testid='container-validation' maxWidth={false}>
      {isMobile ? renderMobileMenu() : <SideMenu />}
      <div className={classNames(classes.innerContainer, !isMobile && classes.innerContainerDesktop)}>{children}</div>
    </Container>
  );

  function renderMobileMenu() {
    return (
      <>
        <TopBar openMenu={openMenu} />
        <Drawer anchor={'left'} open={isMenuOpen} onClose={toggleMenu(false)}>
          <SideMenu closeMenu={() => setMenuOpen(false)} />
        </Drawer>
      </>
    );
  }
};

interface Props {
  children?: React.ReactNode;
}

export default Layout;
