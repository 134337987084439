export enum PlaceTypeEnum {
  EV_PARKING = 'EV_PARKING',
  FAMILY_SPACE = 'FAMILY_SPACE',
  FIXED_DESKS = 'FIXED_DESKS',
  FLOOR = 'FLOOR',
  HOT_DESKS = 'HOT_DESKS',
  PARKING = 'PARKING',
  TEAM_SPACE = 'TEAM_SPACE',
  TRAINING_SPACE = 'TRAINING_SPACE',
}
