import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import useStyles from './MenuLink.style';
import { selectDate } from '../../../../../redux/actions/appActions/appActions';

const MenuLink: React.FC<Props> = ({ title, path, isActive, closeMenu }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const onClick = () => {
    dispatch(selectDate(new Date()));
    closeMenu?.();
  };

  return (
    <li className={classNames(classes.menuItem, { [classes.active]: isActive })}>
      <Link to={path} className={classes.menuItemLink} onClick={onClick}>
        {t(title)}
      </Link>
    </li>
  );
};

export default MenuLink;

type Props = {
  title: string;
  path: string;
  isActive: boolean;
  closeMenu?: () => void;
};
