import { StoreState } from '../reducers/rootReducer/rootReducer';
import { isNil } from 'lodash';

export function getIsLocationSelected(state: StoreState): boolean {
  return !isNil(getUserBuilding(state));
}

export function getUseParking(state: StoreState): boolean {
  return state.app.useParking;
}

export function getUserBuilding(state: StoreState) {
  return state.app.selectedBuilding;
}

export function getUserLicensePlate(state: StoreState) {
  return state.app.licensePlate;
}

export function getSelectedDate(state: StoreState) {
  return state.app.selectedDate;
}

export function getModalState(state: StoreState) {
  return state.app.modalState;
}

export function getConsentState(state: StoreState) {
  return state.app.consentState;
}

export function getUserEngineType(state: StoreState) {
  return state.app.engineType;
}
