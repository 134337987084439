import { SELECT_FLOOR_SUCCESS, SELECT_PLACE_SUCCESS, SELECT_PARKING_SPACE } from '../../constants/appConstants';
import { Location } from '../../../models/location/location';
import { Place } from '../../../models/place/place';

export type BookingAction = SelectFloorSuccess | SelectPlaceSuccess | SelectParkingSpace;

// ************************************************************************
// SELECT FLOOR SUCCESS
// ************************************************************************

interface SelectFloorSuccess {
  type: SELECT_FLOOR_SUCCESS;
  floor: Location;
}
export const setSelectedFloor = (floor: Location): SelectFloorSuccess => {
  return {
    type: SELECT_FLOOR_SUCCESS,
    floor,
  };
};

// ************************************************************************
// SELECT PLACE SUCCESS
// ************************************************************************

interface SelectPlaceSuccess {
  type: SELECT_PLACE_SUCCESS;
  place: Place;
}
export const setSelectedPlace = (place: Place): SelectPlaceSuccess => {
  return {
    type: SELECT_PLACE_SUCCESS,
    place,
  };
};

// ************************************************************************
// SELECT PARKING SPACE
// ************************************************************************

interface SelectParkingSpace {
  type: SELECT_PARKING_SPACE;
  parkingSpace: boolean;
}
export const setSelectedParkingSpace = (parkingSpace: boolean): SelectParkingSpace => {
  return {
    type: SELECT_PARKING_SPACE,
    parkingSpace,
  };
};
