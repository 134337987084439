import React from 'react';
import { useTranslation } from 'react-i18next';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import BlueRoyalSwitch from '../../../Shared/ToggleSwitch/ToggleSwitch';
import VehicleTypeButtons from '../../../Shared/VehicleTypeButtons/VehicleTypeButtons';
import { ReactComponent as EngineTypeCombustion } from '../../../../assets/svg/local_gas_station_black_24dp.svg';
import { ReactComponent as EngineTypeElectric } from '../../../../assets/svg/electrical_services_black_24dp.svg';
import { ReactComponent as IconWarning } from '../../../../assets/svg/icon_warning.svg';
import { EngineTypeEnum } from '../../../../enums/engineType.enum';

import classNames from 'classnames';
import useStyles from './LicensePlateEngineType.style';
import i18n from '../../../../translations/i18n';
import { LicensePlateOptionsEnum, LicensePlateEngineTypeEnum } from '../../../../enums/licensePlateEngineTypeEnum';

const LicensePlateEngineType: React.FC<Props> = ({
  children,
  label,
  labelWithSwitch,
  switchChecked,
  switchOnChange,
  radioGroupOnChange,
  radioGroupOption,
  fixedOptionLabel,
  mode,
  switcherClassName,
  labelClassName,
  defaultEngineType,
  setEngineType,
  selectedEngineType,
  ...other
}) => {
  const classes = useStyles();
  void i18n.loadNamespaces(['settings', 'licensePlateEngineType']);
  const { t } = useTranslation('settings');
  const isDefaultEngineTypeCombustion = defaultEngineType === EngineTypeEnum.COMBUSTION;

  return (
    <>
      <div className={classes.optionContainer}>
        <InputLabel className={classNames(labelClassName, classes.inputLabel)}>
          {label}
          {labelWithSwitch && (
            <BlueRoyalSwitch
              className={classNames(switcherClassName, classes.toggle)}
              checked={switchChecked}
              onChange={switchOnChange}
            />
          )}
        </InputLabel>
        {switchChecked && (
          <>
            {mode === LicensePlateEngineTypeEnum.SHOW_AS_TEXT_FIELD && renderTextField()}
            {mode === LicensePlateEngineTypeEnum.SHOW_AS_RADIO_GROUP && renderRadioGroup()}
          </>
        )}
      </div>
    </>
  );

  function radioGroupOptionIs(radioValue: LicensePlateOptionsEnum) {
    return radioGroupOption === radioValue;
  }

  function renderRadioGroup() {
    return (
      <FormControl>
        <RadioGroup className={classes.radioGroupContainer} onChange={radioGroupOnChange} value={radioGroupOption}>
          <div className={classNames(classes.flexRow, classes.gap16)}>
            <FormControlLabel
              className={classes.defaultTextField}
              value={LicensePlateOptionsEnum.RADIO_VALUE_DEFAULT}
              control={<Radio disableRipple />}
              disabled={!switchChecked}
              label={
                <>
                  <span
                    className={
                      !switchChecked || !radioGroupOptionIs(LicensePlateOptionsEnum.RADIO_VALUE_DEFAULT)
                        ? classes.defaultTextFieldDisabled
                        : undefined
                    }
                  >
                    {fixedOptionLabel?.replace(/(.{2})/g, '$1 ').trim()}
                  </span>
                  <span
                    className={classNames(
                      classes.parkingIcon,
                      !radioGroupOptionIs(LicensePlateOptionsEnum.RADIO_VALUE_DEFAULT) &&
                        classes.defaultTextFieldDisabled
                    )}
                  >
                    {defaultEngineType &&
                      (isDefaultEngineTypeCombustion ? <EngineTypeCombustion /> : <EngineTypeElectric />)}
                  </span>
                </>
              }
            />
            {!defaultEngineType &&
              renderEngineTypeGroup(!radioGroupOptionIs(LicensePlateOptionsEnum.RADIO_VALUE_DEFAULT))}
          </div>
          {!defaultEngineType && radioGroupOptionIs(LicensePlateOptionsEnum.RADIO_VALUE_DEFAULT) && (
            <Typography variant={'caption'} className={classNames(classes.textContent, classes.warning)}>
              <IconWarning />
              {t('defaultEngineType.text')}
            </Typography>
          )}
          <div className={classNames(classes.flexRow, classes.gap16, other.helperText && classes.helperTextMargin)}>
            <FormControlLabel
              value={LicensePlateOptionsEnum.RADIO_VALUE_NEW}
              control={<Radio disableRipple />}
              label={
                <TextField
                  className={classes.licensePlateTextField}
                  variant={'outlined'}
                  disabled={!switchChecked || !radioGroupOptionIs(LicensePlateOptionsEnum.RADIO_VALUE_NEW)}
                  {...other}
                />
              }
              disabled={!switchChecked}
            />
            {renderEngineTypeGroup(!radioGroupOptionIs(LicensePlateOptionsEnum.RADIO_VALUE_NEW))}
          </div>
        </RadioGroup>
      </FormControl>
    );
  }

  function renderTextField() {
    return (
      <div className={classNames(classes.flexRow, classes.gap16)}>
        <TextField variant={'outlined'} className={classes.licensePlateTextField} {...labelWithSwitch} {...other}>
          {children}
        </TextField>
        {renderEngineTypeGroup(!switchChecked)}
      </div>
    );
  }

  function renderEngineTypeGroup(disabled: boolean) {
    return (
      <div className={classNames(classes.flexRowAlignCenter, classes.gap16)}>
        <VehicleTypeButtons selectedEngineType={selectedEngineType} setEngineType={setEngineType} disabled={disabled} />
      </div>
    );
  }
};

interface CommonProps {
  value: string;
  children?: React.ReactElement[];
  switcherClassName?: string;
  labelClassName?: string;
  defaultEngineType?: EngineTypeEnum;
  setEngineType: (engineType?: EngineTypeEnum) => void;
  selectedEngineType?: EngineTypeEnum;
}

type CommonTextFieldWithTopLabelPropsAndTextField = CommonProps & Partial<TextFieldProps>;

export type Props =
  | (CommonTextFieldWithTopLabelPropsAndTextField & {
      labelWithSwitch?: true;
      switchChecked: boolean;
      switchOnChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
      radioGroupOnChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
      radioGroupOption?: string;
      fixedOptionLabel?: string;
      mode: LicensePlateEngineTypeEnum;
    })
  | (CommonTextFieldWithTopLabelPropsAndTextField & {
      labelWithSwitch?: false;
      switchChecked?: never;
      switchOnChange?: never;
      radioGroupOnChange?: never;
      radioGroupOption?: never;
      fixedOptionLabel?: never;
      mode: LicensePlateEngineTypeEnum;
    });

export default LicensePlateEngineType;
