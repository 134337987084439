import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import GuardedRoute from './GuardedRoute';
import { routeEntries, RoutePaths } from './RouterConstants';
import Layout from '../Layout/Layout';
import Login from '../../pages/Login/Login';
import { useIsSignedIn } from '../../../hooks/authHooks/authHooks';

const Router: React.FC = () => {
  const isSignedIn = useIsSignedIn();

  if (isSignedIn === undefined) {
    return LoadingPage();
  }

  return isSignedIn ? LoggedInRouter() : LoggedOutRouter();

  function LoadingPage() {
    return <></>;
  }

  function LoggedInRouter() {
    return (
      <BrowserRouter>
        <Layout>
          <Routes>
            {routeEntries.map((entry, key) => (
              <Route
                key={key}
                path={entry.path}
                element={
                  <GuardedRoute title={entry.title} permissions={entry.permissions}>
                    {entry.children}
                  </GuardedRoute>
                }
              />
            ))}
            <Route path={'/'} element={<Navigate replace to={RoutePaths.dashboard} />} />
          </Routes>
        </Layout>
      </BrowserRouter>
    );
  }

  function LoggedOutRouter() {
    return (
      <BrowserRouter>
        <Routes>
          <Route
            path={'*'}
            element={
              <>
                <Helmet>
                  <title>Login</title>
                </Helmet>
                <Login />
              </>
            }
          />
        </Routes>
      </BrowserRouter>
    );
  }
};

export default connect()(Router);
