import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import sharedStyles from '../../../../styles/sharedStyles/sharedStyles';
import fontWeight from '../../../../styles/sharedStyles/fontweight';
import { muiTheme, theme } from '../../../../styles/theme';
import { pxToRemOrEm, rgbToHex } from '../../../../styles/utilities';

const shared = sharedStyles();

const xTransParkingIcon = 5;
const yTransParkingIcon = 3;

export default makeStyles(() =>
  createStyles({
    ...shared,

    floorCard: {
      ...shared.flex,
      boxShadow: theme.shadows.medium,
      borderRadius: theme.defaultBorderRadius,
      //border: `1px solid ${theme.colors.blue.royal}`,
      border: `1px solid #c01722`,
      background: theme.colors.white.main,
      marginBottom: muiTheme.spacing(2),
      '& :hover': {
        background: theme.colors.blue.lightest,
      },
      '& .MuiCardActionArea-focusHighlight': {
        backgroundColor: 'transparent',
      },
      '& .Mui-focusVisible': {
        backgroundColor: 'transparent',
      },
    },

    floorCardDisabled: {
      ...shared.flex,
      background: theme.colors.white.main,
      borderRadius: theme.defaultBorderRadius,
      border: `1px solid ${rgbToHex(216, 216, 216)}`,
      boxShadow: theme.shadows.medium,
      marginBottom: muiTheme.spacing(2),
    },

    parkingCard: {
      background: theme.colors.blue.lightest,
      borderRadius: theme.defaultBorderRadius,
      boxShadow: 'none',
      '&:not(:last-child)': {
        marginBottom: muiTheme.spacing(2),
      },
    },

    floorName: {
      color: theme.colors.grey.main,
    },

    floorHeadTitle: {
      color: theme.colors.grey.main,
      marginBottom: muiTheme.spacing(1),
    },

    parkingList: {
      ...shared.flexRowAlignCenter,
      justifyContent: 'start',
    },

    floorIcon: {
      marginLeft: muiTheme.spacing(1),
    },

    details: {
      ...shared.flexWrap,
      borderRadius: theme.defaultBorderRadius,
      background: theme.colors.grey.lighter,
      minHeight: 80,
      marginBottom: 14,
      padding: 28,

      '&[open] summary > svg:last-of-type': {
        transform: 'rotate(-270deg)',
      },
    },

    '@keyframes open': {
      '0%': {
        opacity: 0,
      },
      '100%': {
        opacity: 1,
      },
    },

    detailsContent: {
      ...shared.flexRowAlignFlexStartJustifyEnd,
      width: '100%',
      alignItems: 'center',
      paddingTop: '20px',
      animation: '$open .3s ease-in-out',
    },

    parkingIcon: {
      display: 'inline-block',
      transform: `translate(${xTransParkingIcon}px, ${yTransParkingIcon}px)`,
      width: 18,
      height: 18,
      [muiTheme.breakpoints.up('sm')]: {
        width: 24,
        height: 24,
      },
    },

    summary: {
      ...shared.flexRowAlignFlexStartJustifyEnd,
      width: '100%',
      position: 'relative',
      cursor: 'pointer',
      alignItems: 'center',

      '&::-webkit-details-marker': {
        display: 'none',
      },
    },

    capacityWithIcon: {
      ...shared.flex,
      marginRight: '57px',
      alignItems: 'center',
      [muiTheme.breakpoints.down('md')]: {
        marginRight: '0px',
        '& p': {
          minWidth: '50px',
        },
      },
    },

    capacityWithIconDetails: {
      ...shared.flex,
      marginRight: '57px',
      alignItems: 'center',
      justifyContent: 'flex-start',
      flexGrow: 1,
      [muiTheme.breakpoints.down('md')]: {
        marginRight: '0px',
      },
    },

    typeOfSpaceIcons: {
      ...shared.flex,
      marginLeft: '16px',
      alignItems: 'center',
      [muiTheme.breakpoints.only('xs')]: {
        display: 'none',
      },
    },

    spaceIcon: {
      paddingLeft: 8,
      paddingRight: 8,
    },

    cardIcon: {
      ...shared.flex,
      marginLeft: '8px',
    },

    showMoreIcon: {
      color: theme.colors.grey.main,
      height: 15,
      transform: 'rotate(-90deg)',
      transition: 'transform .3s ease-in-out',
    },

    currentOccupationText: {
      textAlign: 'center',
    },

    officeGarageLabel: {
      color: theme.colors.blue.main,
      paddingRight: '8px',
    },

    externalGarageLabel: {
      color: theme.colors.grey.dark,
      paddingRight: '8px',
    },

    currentOccupationPercentageText: {
      textAlign: 'right',
      fontWeight: 'bold',
      marginRight: '40px',
      minWidth: '60px',
      [muiTheme.breakpoints.down('md')]: {
        marginRight: '10px',
      },
    },

    cursorNoPointer: {
      cursor: 'unset',
    },
  })
);
