/* eslint-disable @typescript-eslint/no-unsafe-argument */
export interface RequestProps {
  api: string;
  authorization?: string | null;
  body?: any;
  method: 'GET' | 'PUT' | 'POST' | 'DELETE' | 'OPTIONS' | 'PATCH';
  path: string;
}

export default class Ajax {
  public static async fetchRequest(props: RequestProps) {
    const { method, api, path, body, authorization } = props;
    const url = new URL(api + path);

    const bodyAndHeaders = body && {
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const options = ((): RequestInit => {
      if (authorization) {
        return {
          method,
          ...(bodyAndHeaders && { body: bodyAndHeaders.body }),
          headers: {
            Authorization: 'Bearer ' + authorization,
            ...(bodyAndHeaders && { ...bodyAndHeaders.headers }),
          },
        };
      }

      return {
        method,
        ...bodyAndHeaders,
      };
    })();

    const response = await fetch(url.href, options);

    if (response.ok) {
      return {
        status: response.status,
        body: method === 'DELETE' && response.status === 204 ? null : await response.json(),
      };
    }

    if (response.status === 412) {
      return {
        status: response.status,
      };
    }

    const responseBody = await response.json();

    const message =
      responseBody.message ||
      responseBody.Message ||
      responseBody.error ||
      responseBody.errors?.map((error: { message: string }) => error.message).join(' ') ||
      responseBody.parameterViolations?.map((error: any) => error.message).join(' ') ||
      '';

    throw new Error(message);
  }
}
