import { Typography } from '@mui/material';
import React from 'react';
import { ReactComponent as LocationIcon } from '../../../assets/svg/pin.svg';
import useStyles from './LocationTag.style';

const LocationTag: React.FC<Props> = ({ location }) => {
  const classes = useStyles();

  return (
    <div className={classes.locationTag}>
      <LocationIcon className={classes.locationIcon} />
      <Typography variant='subtitle1'>{location}</Typography>
    </div>
  );
};

export default LocationTag;

type Props = {
  location?: string;
};
