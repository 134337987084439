import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { theme } from '../../../styles/theme';

const tooltipMargin = '30px';

export default makeStyles(() =>
  createStyles({
    TooltipWrapper: {
      display: 'inline-block',
      position: 'relative',

      '&.not-allowed': {
        cursor: 'not-allowed',
      },

      '&.pointer': {
        cursor: 'pointer',
      },
    },

    Tooltip: {
      alignItems: 'center',
      justifyContent: 'center',
      background: theme.colors.grey.lighter,
      borderRadius: 6,
      boxShadow: '0px 0px 3px 0px rgb(178, 180, 182)',
      color: theme.colors.grey.main,
      display: 'flex',
      fontFamily: 'sans-serif',
      fontSize: 12,
      minHeight: 32,
      left: '50%',
      lineHeight: '18px',
      paddingBottom: 2,
      paddingLeft: 8,
      paddingRight: 8,
      paddingTop: 2,
      position: 'absolute',
      transform: 'translateX(-50%)',
      zIndex: 100,

      '&.top': {
        top: `calc(${tooltipMargin} * -1.4)`,
      },

      '&.right': {
        left: `calc(100% + ${tooltipMargin})`,
        top: '50%',
        transform: 'translateX(0) translateY(-50%)',
      },

      '&.bottom': {
        bottom: `calc(${tooltipMargin} * -1.4)`,
      },

      '&.left': {
        left: 'auto',
        right: `calc(100% + ${tooltipMargin})`,
        top: '50%',
        transform: 'translateX(0) translateY(-50%)',
      },
    },

    tooltipWidthSmallText: {
      minWidth: 60,
    },

    tooltipWidthMediumText: {
      minWidth: 180,
    },

    tooltipWidthLongText: {
      width: '100%',
      whiteSpace: 'normal',
    },

    disabled: {
      cursor: 'default',
    },
  })
);
