import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import addDays from 'date-fns/addDays';
import moment from 'moment';
import useStyles from './StartingStep.style';
import GuestPicker from '../GuestPicker/GuestPicker';
import TeamSpaceManager from '../TeamSpaceManager/TeamSpaceManager';
import { ReservationContext } from '../ReservationContext/ReservationContext';
import {
  setCanContinue,
  setDate,
  setLicensePlate,
  setNeedParkingSpace,
  setEngineType,
} from '../ReservationContext/ReservationReducer';
import LicensePlate from '../../LicensePlate/LicensePlate';
import { useFeatureToggle } from '../../../../App/FeatureToggleProvider/FeatureToggleContext';
import Calendar from '../../../../Shared/Calendar/Calendar';
import config from '../../../../../config/config';
import { DATE_FORMAT_MDY, DAYS } from '../../../../../constants/dateFormat';
import { ConsentTypeEnum } from '../../../../../enums/consentType.enum';
import { EngineTypeEnum } from '../../../../../enums/engineType.enum';
import { useGetUserConsent, useGetUserDefaults } from '../../../../../hooks/userHooks/userHooks';
import { setModalState } from '../../../../../redux/actions/appActions/appActions';
import Formatters from '../../../../../services/Formatters/Formatters';
import { PlaceUtil } from '../../../../../utils/place/place-util';

const StartingStep: React.FC = () => {
  const { guestPicker: showGuestPicker } = useFeatureToggle();
  const { state, dispatch } = useContext(ReservationContext);
  const { date, licensePlate, place, needParkingSpace: reducerNeedParkingSpace, engineType: reducerEngineType } = state;
  const isPlaceATeamSpace = useMemo(() => (place && PlaceUtil.isTeamSpace(place)) || false, [place]);
  const memoizedInitialDate = useMemo(() => date[0], [date]);
  const [datePickerValue, setDatePickerValue] = useState<Date[]>([new Date()]);

  const [withError, setWithError] = useState<boolean>(false);

  const { licensePlate: defaultLicensePlate, engineType: defaultEngineType } = useGetUserDefaults();

  const reduxDispatch = useDispatch();
  const consent = useGetUserConsent();

  /**
   * Enable Need Parking Place if Consent Agree and Default License Plate
   * If Place is a Team Space it should be set to false, thus this useEffect has to depend on place
   */
  useEffect(() => {
    !isPlaceATeamSpace && dispatch(setNeedParkingSpace(consent === ConsentTypeEnum.AGREE && !!defaultLicensePlate));
  }, [consent, defaultLicensePlate, dispatch, isPlaceATeamSpace]);

  useEffect(() => {
    !!memoizedInitialDate &&
      setDatePickerValue([moment(memoizedInitialDate).toDate(), moment(memoizedInitialDate).toDate()]);
  }, [memoizedInitialDate]);

  const toggleReducerNeedParkingSpace = useCallback(() => {
    if (consent === ConsentTypeEnum.AGREE) {
      dispatch(setNeedParkingSpace(!reducerNeedParkingSpace));
    } else {
      reduxDispatch(setModalState(true));
    }
  }, [consent, dispatch, reduxDispatch, reducerNeedParkingSpace]);

  const setReducerEngineType = useCallback(
    (engineType: EngineTypeEnum | undefined) => {
      dispatch(setEngineType(engineType));
    },
    [dispatch]
  );

  const setReducerLicensePlate = useCallback(
    (plate: string | undefined) => {
      dispatch(setLicensePlate(plate));
    },
    [dispatch]
  );

  const presentDay = useMemo(() => new Date(), []);
  const maxDateToBook = addDays(presentDay, config.BOOKINGS_LIMIT);
  const handleChangeDate = (newDate: Date[]) => {
    setDatePickerValue(newDate);
    let initialDate = moment(newDate[0], DATE_FORMAT_MDY);
    const finalDate = newDate[1] ? moment(newDate[1], DATE_FORMAT_MDY) : moment(newDate[0], DATE_FORMAT_MDY);
    const rangeDates: string[] = [];

    if (initialDate.valueOf() !== finalDate.valueOf() || !finalDate) {
      while (initialDate.valueOf() <= finalDate.valueOf()) {
        if (![0, 6].includes(initialDate.day())) {
          rangeDates.push(Formatters.formatDateISOYearMonthDay(initialDate));
        }
        initialDate = initialDate.add(1, DAYS);
      }
    } else {
      rangeDates.push(Formatters.formatDateISOYearMonthDay(initialDate));
    }

    dispatch(setDate(rangeDates));
  };

  const [isBuildingFull] = useState<boolean>(false);

  const isSelectedDateInPast = useMemo(() => moment(date[0]).isBefore(moment(presentDay), 'day'), [date, presentDay]);
  const isSelectedDateAfterTheBookingsLimit = useMemo(
    () => moment(date[0]).isAfter(moment(maxDateToBook), 'day'),
    [date, maxDateToBook]
  );

  const disabledConditions = useMemo(
    () =>
      isSelectedDateInPast ||
      isSelectedDateAfterTheBookingsLimit ||
      !date[0] ||
      (!isPlaceATeamSpace && reducerNeedParkingSpace && !state.engineType) ||
      (!isPlaceATeamSpace && reducerNeedParkingSpace && !state.licensePlate) ||
      (!isPlaceATeamSpace && reducerNeedParkingSpace && withError) ||
      isBuildingFull,
    [
      date,
      isBuildingFull,
      isPlaceATeamSpace,
      isSelectedDateAfterTheBookingsLimit,
      isSelectedDateInPast,
      reducerNeedParkingSpace,
      state.licensePlate,
      state.engineType,
      withError,
    ]
  );

  useEffect(() => {
    dispatch(setCanContinue(!disabledConditions));
  }, [disabledConditions, dispatch]);

  const classes = useStyles();

  return (
    <>
      <Calendar
        allowPartialRange
        maxDate={maxDateToBook}
        minDate={presentDay}
        onChange={handleChangeDate}
        returnValue='range'
        selectRange
        styles={classes.calendarPicker}
        value={datePickerValue}
      />
      {place && isPlaceATeamSpace ? (
        <TeamSpaceManager />
      ) : (
        <>
          <div className={classes.licensePlateWrapper}>
            <LicensePlate
              needParkingSpace={reducerNeedParkingSpace}
              reducerLicensePlate={licensePlate}
              reducerEngineType={reducerEngineType}
              setLicensePlate={setReducerLicensePlate}
              setEngineType={setReducerEngineType}
              toggleNeedParkingSpace={toggleReducerNeedParkingSpace}
              {...{
                defaultLicensePlate,
                defaultEngineType,
                setWithError,
                withError,
              }}
            />
          </div>
          {showGuestPicker ? <GuestPicker /> : null}
        </>
      )}
    </>
  );
};

export default StartingStep;
