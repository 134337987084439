import createStyles from '@mui/styles/createStyles';

/**
 * Font-weight styles :
 *
 * - lighter
 * - normal
 * - bold
 * - bolder
 *
 */

const fontWeight = createStyles({
  fontWeightLighter: {
    fontWeight: 'lighter',
  },
  fontWeightNormal: {
    fontWeight: 'normal',
  },
  fontWeightBold: {
    fontWeight: 'bold',
  },
  fontWeightBolder: {
    fontWeight: 'bolder',
  },
});

export default fontWeight;
