import React, { useContext, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { startCase } from 'lodash';
import classNames from 'classnames';
import Typography from '@mui/material/Typography';
import useStyles from './ConfirmedReservation.style';
import { ReservationContext } from '../ReservationWizard/ReservationContext/ReservationContext';
import { RoutePaths } from '../../../App/Router/RouterConstants';
import RoundButton from '../../../Shared/RoundButton/RoundButton';
import { ReactComponent as ConfirmationImg } from '../../../../assets/svg/illustration_confirmed-reservation.svg';
import { Booking } from '../../../../models/booking/booking';
import { getUserBuilding } from '../../../../redux/selectors/appSelectors';
import Formatters from '../../../../services/Formatters/Formatters';
import i18n from '../../../../translations/i18n';
import { PlaceUtil } from '../../../../utils/place/place-util';
import { GuestsUtil } from '../../../../utils/guests/guests-util';

const ConfirmedReservation: React.FC<Props> = ({ booking }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { state } = useContext(ReservationContext);
  const { place, date, teamSpaceMembers, teamSpaceMembersParking, needParkingSpace, needGuests, guests } = state;
  void i18n.loadNamespaces(['reservation', 'reservationWizard']);
  const { t } = useTranslation(['reservation', 'reservationWizard']);

  const firstTeamSpaceReservationDate = new Date(date[0]);
  const firstDateDay = firstTeamSpaceReservationDate.getDate().toString();
  const firstDateMonth = firstTeamSpaceReservationDate.toLocaleString('en-us', { month: 'short' });
  const lastTeamSpaceReservationDate = new Date(date[date.length - 1]);
  const lastDateDay = lastTeamSpaceReservationDate.getDate().toString();
  const lastDateMonth = lastTeamSpaceReservationDate.toLocaleString('en-us', { month: 'short' });

  const building = useSelector(getUserBuilding)?.name;
  const floor = place?.floor;
  const floorNumber = floor?.split(' ')[0];
  const wing = place?.wing;

  const placeType = startCase(place?.type.replace('_', ' ').toLowerCase());
  const teamSpace = place?.name;
  const guestList = GuestsUtil.getGuestList(guests);
  const hasGuests: boolean = needGuests && ((guestList && guestList?.length > 0) ?? false);

  const teamSpaceUsersWithParkingSpace = booking.reduce<string[]>((result, current) => {
    if (current.parking && current.user?.username) {
      return !result.includes(current.user.username) ? [...result, current.user.username] : [...result];
    }
    return [...result];
  }, []);

  const teamSpaceUsersNeedParkingSpace = teamSpaceMembersParking.some((member) => member.needParkingSpace);

  const firstDateMonthIsRendered = firstDateMonth !== lastDateMonth && firstDateMonth;

  // TODO - [WIP] Move code that is checking if the range of dates is in the same month or if it's including more months to an utility method - US CTWTETRIS-2325
  const isSameMonthReservation = !firstDateMonthIsRendered
    ? t('confirmedReservation.reservationDateRange', {
        firstDateDay,
        lastDateDay,
        lastDateMonth,
      })
    : t('confirmedReservation.reservationDateRangeMonths', {
        firstDateDay,
        firstDateMonthIsRendered,
        lastDateDay,
        lastDateMonth,
      });

  const dates =
    firstDateDay === lastDateDay
      ? t('confirmedReservation.reservationDate', { lastDateDay, lastDateMonth })
      : isSameMonthReservation;

  const floorHasWing = wing?.toLowerCase() !== t('confirmedReservation.noWing').toLowerCase();

  const teamSpaceParkingReservations = teamSpaceUsersNeedParkingSpace ? (
    teamSpaceUsersWithParkingSpace.map((user) => (
      <Typography
        variant='body1'
        key={user}
        className={classNames(classes.teamSpaceReservationMessage, classes.flexJustifyCenter)}
      >
        {t('confirmedReservation.teamSpaceParkingReservations', { user })}
      </Typography>
    ))
  ) : (
    <Typography variant='body1' className={classNames(classes.teamSpaceReservationMessage, classes.flexJustifyCenter)}>
      {t('confirmedReservation.noParkingReservations')}
    </Typography>
  );

  const hotDeskParkingReservations = (
    <Typography variant='body1' className={classes.flexJustifyCenter}>
      {needParkingSpace ? t('confirmedReservation.reserved') : t('confirmedReservation.noParkingReservations')}
    </Typography>
  );

  const isPlaceATeamSpace = useMemo(() => (place && PlaceUtil.isTeamSpace(place)) || false, [place]);

  const renderRegularSpaceInformation = () => {
    return (
      <>
        <Typography variant='body1' className={classNames(classes.teamSpaceReservationMessage, classes.fontBold)}>
          {t('confirmedReservation.parkingSpace')}:
        </Typography>
        <Typography variant='body1'>{hotDeskParkingReservations}</Typography>
        {hasGuests && renderGuestInformation()}
        <Typography variant='body1' className={classNames(classes.reservationInformation, classes.marginTop2)}>
          <Trans
            components={{ 1: <Link className={classes.noWrap} to={RoutePaths.circulationRules} /> }}
            t={t}
            i18nKey={'confirmedReservation.circulationRulesText'}
          />
        </Typography>
      </>
    );
  };

  const renderTeamSpaceInformation = () => {
    return (
      <div className={classes.flexColumnAlignCenter}>
        <Typography variant='body1' className={classes.reservationInformation}>
          <Trans
            components={{ 1: <span className={classNames(classes.reservationInformation, classes.fontBold)} /> }}
            values={{
              placeType,
              teamSpace,
              teamSpaceMembers: teamSpaceMembers.length,
              maxCapacity: place?.maxCapacity,
            }}
            t={t}
            i18nKey={'confirmedReservation.teamSpaceLocationInformation'}
          />
        </Typography>
        <Typography variant='body1' className={classNames(classes.teamSpaceReservationMessage, classes.fontBold)}>
          {t('confirmedReservation.parkingSpace')}:
        </Typography>
        <Typography variant='body1' data-testid='teamSpaceParkingReservations'>
          {teamSpaceParkingReservations}
        </Typography>
        {hasGuests && renderGuestInformation()}
      </div>
    );
  };

  const renderGuestInformation = () => {
    return (
      <div className={classNames(classes.flexColumnAlignCenter, classes.guestInformation)}>
        <Typography variant='body1'>
          <Trans t={t} components={{ 1: <strong /> }} i18nKey={'reservationWizard:guests.externalGuests'} />
        </Typography>
        <ul className={classes.guestList} data-testid='guestList'>
          {guestList?.map((guest) => (
            <Typography variant='body1' key={guest}>
              <li> {guest} </li>
            </Typography>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <div className={classes.container}>
      <ConfirmationImg className={classes.image} data-testid='confirmationReservationImg' />
      <Typography className={classes.title}>
        {isPlaceATeamSpace
          ? t('confirmedReservation.teamSpaceReservedMessage')
          : t('confirmedReservation.seatReservedMessage')}
      </Typography>
      <Typography variant='body1' className={classes.warningMessage}>
        {t('confirmedReservation.warningMessage')}
      </Typography>
      <div className={classNames(classes.flexColumnAlignCenter, classes.reservationInformation)}>
        <Typography
          variant='body1'
          className={classNames(classes.teamSpaceReservationMessage, classes.fontBold)}
          data-testid='reservationDate'
        >
          {dates}
        </Typography>
        <Typography variant='body1' className={classes.teamSpaceReservationMessage}>
          {floorHasWing ? (
            <Trans
              components={{
                1: <strong />,
              }}
              values={{
                building,
                floorNumber,
                floorText: startCase(t('confirmedReservation.floor').toLowerCase()),
                wing: startCase(wing?.toLowerCase()),
              }}
              t={t}
              i18nKey={'confirmedReservation.buildingInformationWithWing'}
            />
          ) : (
            <Trans
              components={{
                1: <strong />,
              }}
              values={{
                building,
                floorNumber,
                floorText: startCase(t('confirmedReservation.floor').toLowerCase()),
              }}
              t={t}
              i18nKey={'confirmedReservation.buildingInformationWithoutWing'}
            />
          )}
        </Typography>
      </div>
      {isPlaceATeamSpace ? renderTeamSpaceInformation() : renderRegularSpaceInformation()}
      <div className={classNames(classes.marginTop3, classes.flexJustifyCenter)}>
        <RoundButton
          className={classes.confirmButton}
          textTransformNone
          onClick={() => {
            navigate(RoutePaths.myReservations);
          }}
        >
          {t('confirmedReservation.callToActionMessage')}
        </RoundButton>
      </div>
    </div>
  );
};

type Props = {
  booking: Booking[];
};

export default ConfirmedReservation;
