import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import sharedStyles from '../../../styles/sharedStyles/sharedStyles';
import { muiTheme, theme } from '../../../styles/theme';

const shared = sharedStyles();

export default makeStyles(() =>
  createStyles({
    ...shared,
    container: {
      paddingRight: 0,
      paddingLeft: 0,
    },

    innerContainer: {
      ...shared.flex,
      minHeight: '100vh',
    },

    innerContainerDesktop: {
      marginLeft: theme.navbarSmWidth,
      [muiTheme.breakpoints.up('sm')]: {
        marginLeft: theme.navbarWidth,
      },
    },
  })
);
