import React, { useLayoutEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { PeoplePicker as MgtPeoplePicker, UserType } from '@microsoft/mgt-react';
import FormHelperText from '@mui/material/FormHelperText';
import SelectedPerson from './SelectedPerson';
import './PeoplePicker.scss';
import { ReactComponent as IconWarning } from '../../../assets/svg/icon_warning.svg';

const PeoplePicker: React.FC<Props> = ({
  handlePeopleSelected,
  selectedUserId,
  className = '',
  disabled = false,
  hasOverlappedBookings = false,
}) => {
  const { t } = useTranslation('reservationWizard');
  const peoplePickerRoot = useRef<HTMLElement>();
  const style = document.createElement('style');
  style.innerHTML = `
    .people-picker { padding-left: 4px !important; }
    .people-person-job-title { display: none; }
  `;
  peoplePickerRoot?.current?.shadowRoot?.appendChild(style);

  return (
    <div className={classNames('column-row', className)}>
      <div
        className={classNames(
          'people-picker-input-wrapper',
          disabled && 'people-picker-input-disabled',
          hasOverlappedBookings && 'people-picker-input-error'
        )}
      >
        <MgtPeoplePicker
          selectionMode={'single'}
          selectionChanged={handlePeopleSelected}
          userType={UserType.user}
          placeholder={t('reservationWizard.addMember')}
          defaultSelectedUserIds={selectedUserId}
          disabled={disabled}
          className={classNames(disabled && 'people-picker-input-disabled', 'people-picker-host')}
          ref={peoplePickerRoot}
        >
          {/*<DropdownResult template='person' />*/}
          <SelectedPerson template='selected-person' />
        </MgtPeoplePicker>
      </div>
      {hasOverlappedBookings && (
        <FormHelperText error className={classNames('overlappedText')}>
          <IconWarning className={classNames('overlappedErrorIcon')} />
          {t('teamSpaceManager.userOverlappedBooking')}
        </FormHelperText>
      )}
    </div>
  );
};

interface Props {
  className?: string;
  disabled?: boolean;
  handlePeopleSelected: (e: Event) => void;
  hasOverlappedBookings?: boolean;
  selectedUserId?: string[];
}

export default PeoplePicker;
