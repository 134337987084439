import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import sharedStyles from '../../../styles/sharedStyles/sharedStyles';
import { muiTheme, theme } from '../../../styles/theme';
import { pxToRemOrEm } from '../../../styles/utilities';

export default makeStyles(() =>
  createStyles({
    ...sharedStyles(),

    root: {
      minWidth: 354,
      maxWidth: 320,
      wordBreak: 'break-word',
      color: theme.colors.grey.main,
      fontSize: pxToRemOrEm(16),
      fontWeight: 'normal',
      height: 57,
      letterSpacing: 0,
      margin: 'auto',
      marginBottom: 100,
      marginLeft: 350,
      [muiTheme.breakpoints.down('md')]: {
        margin: 'auto',
        marginBottom: 100,
      },

      '& .SnackbarItem-variantError': {
        backgroundColor: theme.colors.white.main,
        color: theme.colors.grey.main,
      },

      '& .SnackbarItem-variantSuccess': {
        backgroundColor: theme.colors.white.main,
        color: theme.colors.grey.main,
      },

      '& .SnackbarItem-contentRoot': {
        align: 'center',
        flexWrap: 'nowrap',
        borderRadius: 6,
      },
    },

    content: {
      align: 'center',
      flexWrap: 'nowrap',
      borderRadius: 6,
    },

    success: {
      whiteSpace: 'pre-line',
      borderLeft: '0.6em solid #21ad7a',
    },

    error: {
      whiteSpace: 'pre-line',
      borderLeft: '0.6em solid #f13030',
    },
  })
);
