import React, { ChangeEvent, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import useStyles from './GuestPicker.style';
import {
  addGuest,
  removeGuest,
  setGuestName,
  setNeedGuests,
  validateGuests,
} from '../ReservationContext/ReservationReducer';
import { ReservationContext } from '../ReservationContext/ReservationContext';
import BlueRoyalSwitch from '../../../../Shared/ToggleSwitch/ToggleSwitch';
import { maximumGuests } from '../../../../../utils/constants';
import { ReactComponent as IconDelete } from '../../../../../assets/svg/icon_bin.svg';
import { ReactComponent as IconClose } from '../../../../../assets/svg/icon_close.svg';
import { GuestsUtil } from '../../../../../utils/guests/guests-util';
import Typography from '@mui/material/Typography';

const GuestPicker = () => {
  const classes = useStyles();
  const { state, dispatch } = useContext(ReservationContext);
  const { guests, needGuests } = state;
  const [guestCount, setGuestCount] = useState<number>(0);
  const [guestIndex, setGuestIndex] = useState<number[]>([]);
  const { t } = useTranslation('reservationWizard');

  const haveNameOfAllGuests = useMemo(() => GuestsUtil.haveNameOfAllGuests(guests), [guests]);

  const validateGuestsQty = () => {
    return guestIndex.length < maximumGuests;
  };

  const toggleNeedGuests = () => {
    if (guestCount === 0) {
      handleAddGuest();
    }
    dispatch(setNeedGuests(!needGuests));
    dispatch(validateGuests());
  };

  const handleAddGuest = () => {
    if (!validateGuestsQty()) {
      return;
    }

    setGuestIndex((previous) => [...previous, guestCount]);
    setGuestCount((previous) => previous + 1);
    dispatch(addGuest({ name: '', index: guestCount }));
    dispatch(validateGuests());
  };

  const handleChangeGuestName = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, index: number) => {
    dispatch(setGuestName({ name: event.target.value, index }));
    dispatch(validateGuests());
  };

  const handleTrashBinButton = (index: number) => {
    dispatch(removeGuest(index));
    setGuestIndex((prevState) => prevState.filter((i) => i !== index));
    dispatch(validateGuests());
  };

  const handleClearInputField = (index: number) => {
    dispatch(setGuestName({ name: '', index }));
    dispatch(validateGuests());
  };

  const renderLabelAndSwitch = () => (
    <InputLabel className={classes.toggleLabel}>
      <Typography variant='subtitle1' component='span'>
        {t('guests.toggleLabel')}
      </Typography>
      <BlueRoyalSwitch className={classes.toggle} checked={needGuests} onChange={toggleNeedGuests} />
    </InputLabel>
  );

  const renderTrashBin = (index: number, withError?: boolean) =>
    guestIndex.length > 1 && (
      <div>
        <button
          type={'button'}
          aria-label={t('guests.trashBinAria', { index: index + 1 })}
          className={classNames(classes.deleteButtonContainer, withError && classes.inputMarginBottom)}
          onClick={() => handleTrashBinButton(index)}
        >
          <IconDelete />
        </button>
      </div>
    );

  const renderGuestsInputFields = () => {
    return guestIndex.map((index) => {
      const guest = guests.find((g) => g.index === index);
      const withError = (guest?.name && guest.name.length < GuestsUtil.guestMinLength) || false;
      return (
        <React.Fragment key={index}>
          {renderTrashBin(index, withError)}
          <TextField
            className={classNames(classes.guestInputField, withError && classes.inputMarginBottom)}
            variant={'outlined'}
            aria-label={t('guests.inputAria', { index: index + 1 })}
            placeholder={t('guests.inputPlaceholder')}
            value={guest?.name}
            error={(guest?.name && guest?.name?.length < GuestsUtil.guestMinLength) || false}
            helperText={
              guest?.name &&
              guest.name.length < GuestsUtil.guestMinLength &&
              t('guests.nameLengthError', { count: GuestsUtil.guestMinLength })
            }
            onChange={(event) => handleChangeGuestName(event, index)}
            inputProps={{
              minLength: GuestsUtil.guestMinLength,
              maxLength: GuestsUtil.guestMaxLength,
              'data-testid': `guest-input-${index}`,
              autoComplete: 'off',
            }}
            InputProps={{
              endAdornment: guest?.name && (
                <InputAdornment position='end'>
                  <IconButton
                    aria-label={t('guests.clearInputAria', { index: index + 1 })}
                    onClick={() => handleClearInputField(index)}
                    disableRipple
                    edge='end'
                  >
                    <IconClose />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </React.Fragment>
      );
    });
  };

  const renderAddButton = () =>
    validateGuestsQty() && (
      <button
        type={'button'}
        className={classes.addGuestButtonContainer}
        onClick={handleAddGuest}
        disabled={!haveNameOfAllGuests}
      >
        {t('guests.add')}
      </button>
    );

  return (
    <div>
      {renderLabelAndSwitch()}
      {needGuests && (
        <div className={classNames(classes.guestsWrapper, guestIndex.length > 1 && classes.guestsWrapperGap)}>
          {renderGuestsInputFields()}
          {renderAddButton()}
        </div>
      )}
    </div>
  );
};

export default GuestPicker;
