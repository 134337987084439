import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import sharedStyles from '../../../styles/sharedStyles/sharedStyles';
import { muiTheme, theme } from '../../../styles/theme';

const shared = sharedStyles();

export default makeStyles(() =>
  createStyles({
    ...shared,

    container: {
      ...shared.flexColumnAlignCenterJustifyCenter,
      height: '100vh',
    },

    loginButton: {
      marginTop: muiTheme.spacing(8),
      marginBottom: muiTheme.spacing(8),
      textTransform: 'inherit',
      backgroundColor: theme.colors.red.main,
      transition: theme.transition,
      '--background-color--hover': theme.colors.orange.office365,
      '--button-color--hover': theme.colors.white.main,
      '--button-color': theme.colors.white.main,
      '&:hover': {
        opacity: theme.hoverOpacity,
      },
    },

    maxWidth230: {
      maxWidth: 230,
    },

    marginBottom3: {
      marginBottom: muiTheme.spacing(3),
    },

    logoWally: {
      ...shared.fillBlueMain,
    },

    logoWallyTitle: {
      ...shared.fillBlueMain,
      marginBottom: muiTheme.spacing(3),
    },
  })
);
