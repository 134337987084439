import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import useStyles from './LicensePlateReport.style';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import { FormHelperText } from '@mui/material';
import { useGetReportUsersByLicensePlate } from '../../../hooks/bookingHooks/bookingHooks';
import { ReactComponent as EngineTypeCombustion } from '../../../assets/svg/icon_local_gas_station_bold.svg';
import { ReactComponent as EngineTypeElectric } from '../../../assets/svg/icon_electrical_services_bold.svg';
import { EngineTypeEnum } from '../../../enums/engineType.enum';
import i18n from '../../../translations/i18n';
import useIsLicensePlateValid from '../../../hooks/customHooks/useIsLicensePlateValid';

const LicensePlateReport: React.FC = () => {
  const classes = useStyles();
  const [licensePlate, setLicensePlate] = useState<string>('');
  const { users, getUsers } = useGetReportUsersByLicensePlate(licensePlate);
  void i18n.loadNamespaces('report');
  const { t } = useTranslation(['report', 'settings']);
  const isLicensePlateValid = useIsLicensePlateValid(licensePlate);

  const handleGetUsers = () => {
    isLicensePlateValid && getUsers();
  };

  const renderSearch = () => {
    return (
      <>
        <Paper component='div' className={classes.search}>
          <InputBase
            className={classes.inputField}
            placeholder={t('licensePlate.title')}
            inputProps={{ 'aria-label': t('licensePlate.title') }}
            value={licensePlate}
            onKeyPress={(event) => {
              if (event.key === 'Enter') {
                event.preventDefault();
                void handleGetUsers();
              }
            }}
            onChange={(event) => setLicensePlate(event.target.value.toUpperCase())}
            error={!isLicensePlateValid}
          />

          <IconButton
            type='button'
            className={classes.iconSearch}
            aria-label={t('licensePlate.search')}
            onClick={() => void handleGetUsers()}
          >
            <SearchIcon />
          </IconButton>
        </Paper>
        {!isLicensePlateValid && (
          <FormHelperText className={classes.helperText} error={!isLicensePlateValid}>
            {t('settings:licensePlate.licensePlateHelperText')}
          </FormHelperText>
        )}
      </>
    );
  };

  const renderUserByLicensePlateTable = () => {
    return (
      <TableContainer component={Paper}>
        <Table className={classes.tableHeader} size='small' aria-label={t('licensePlate.tableResults')}>
          <TableHead>
            <TableRow>
              <TableCell align='center'>{t('licensePlate.columnName')}</TableCell>
              <TableCell align='center'>{t('licensePlate.columnEmail')}</TableCell>
              <TableCell align='center'>{t('licensePlate.columnLicensePlate')}</TableCell>
              <TableCell align='center'>{t('licensePlate.columnEngineType')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users?.map((row) => (
              <TableRow key={row.name} className={classes.tableRows}>
                <TableCell align='center' component='th' scope='row'>
                  {row.name}
                </TableCell>
                <TableCell align='center' component='th' scope='row'>
                  {row.email}
                </TableCell>
                <TableCell align='center'>{row.licensePlate}</TableCell>
                <TableCell align='center'>
                  {row?.engineType === EngineTypeEnum.COMBUSTION ? (
                    <EngineTypeCombustion data-testid='combustion-svg' />
                  ) : (
                    <EngineTypeElectric data-testid='ev-phev-svg' />
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <>
      <Grid component='main' container justifyContent='center' className={classes.container}>
        <Grid item xs={12}>
          <Typography className={classes.pageTitle}>{t('licensePlate.pageTitle')}</Typography>
        </Grid>
        <div className={classes.searchBar}>{renderSearch()}</div>
        <div className={classNames(classes.userResultsTable, !isLicensePlateValid && classes.marginTopZero)}>
          {renderUserByLicensePlateTable()}
        </div>
      </Grid>
    </>
  );
};

export default LicensePlateReport;
