import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { isEmpty, map, orderBy } from 'lodash';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import Grid from '@mui/material/Grid';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import useMediaQuery from '@mui/material/useMediaQuery';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  lisbonOfficeParkingAdress,
  parkingRules,
  portoExternalParkingAdress,
  portoOfficeParkingAddress,
  preventionRules,
  reservationRules,
  Rule,
} from './Rules-item';
import useStyles from './Rules.style';
import BlueRadio from '../../Shared/BlueRadio/BlueRadio';
import { ReactComponent as IconPin } from '../../../assets/svg/pin.svg';
import { ReactComponent as IconCovid } from '../../../assets/svg/covid.svg';
import i18n from '../../../translations/i18n';
import { Location } from '../../../models/location/location';
import { useGetUserDefaults } from '../../../hooks/userHooks/userHooks';
import { useGetBuildings } from '../../../hooks/locationHooks/locationHooks';
import { mobileQuery, tabletQuery } from '../../../constants/mediaQuery';
import { theme } from '../../../styles/theme';
import classNames from 'classnames';
import { ReactComponent as IconDoc } from '../../../assets/svg/doc.svg';
import { Place } from '../../../models/place/place';
import GarageBlueprintModal from './GarageBlueprintModal';
import { BuildingUtils } from '../../../utils/building/bulding-utils';

const Rules: React.FC = (place) => {
  const classes = useStyles();
  void i18n.loadNamespaces('rules');
  const { t } = useTranslation('rules');
  const isMobile = useMediaQuery(mobileQuery);
  const isTablet = useMediaQuery(tabletQuery);

  const { location } = useGetUserDefaults();
  const [selectedBuildingOption, setSelectedBuildingOption] = useState<string>('');
  const { buildings } = useGetBuildings();

  useEffect(() => {
    if (location) {
      setSelectedBuildingOption(location.id);
    }
  }, [location]);

  const onChangeBuilding = (event: React.ChangeEvent<{ value: Location['id'] }>) => {
    setSelectedBuildingOption(event.target.value);
  };
  const [hasScrolled, setHasScrolled] = useState(false);

  const handleScroll = () => {
    setHasScrolled(window.pageYOffset > 2);
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  function useScrollTop() {
    const [scrollTop, setScrollTop] = useState(0);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const onScroll = (event: any) => setScrollTop(event.target.scrollTop);
    return [scrollTop, { onScroll }];
  }

  const [scrollProps] = useScrollTop();
  return (
    <>
      <Grid container direction={'column'} className={classes.container}>
        <header
          {...scrollProps}
          className={classes.header}
          style={{
            boxShadow: hasScrolled ? '0px 8px 16px 0px rgb(242, 242, 242)' : 'none',
          }}
        >
          <Grid columns={{ xs: 4, md: 12 }} className={classes.officeLocation}>
            <Grid xs={6} md={4} className={classes.officeLocationLeftGrid}>
              {!isEmpty(buildings) && renderBuildings()}
            </Grid>
            <Grid xs={6} md={4} className={classes.officeLocationRightGrid}>
              <div className={classes.officeLocationMapButtonHidden}>
                <button onClick={handleClickOpen}>{renderBlueprintButtonText()}</button>
              </div>
            </Grid>
          </Grid>
        </header>
        <Grid
          container
          className={classes.innerContainer}
          direction={'column'}
          alignItems={'center'}
          columns={{ xs: 4, md: 12 }}
        >
          <Grid xs={4} md={8}>
            {(BuildingUtils.isPorto(selectedBuildingOption) || BuildingUtils.isLisbon(selectedBuildingOption)) && (
              <Accordion
                square
                disableGutters
                defaultExpanded={!isMobile}
                sx={[
                  {
                    '& .MuiPaper-root-MuiAccordion-root::before': {
                      backgroundColor: 'transparent !important',
                    },
                  },
                  { boxShadow: 'none', borderTop: '1px solid #b2b4b6' },
                ]}
              >
                <AccordionSummary
                  sx={[
                    { padding: 0 },
                    { '& .MuiAccordionSummary-expandIconWrapper': { color: theme.colors.grey.main } },
                    { '& .MuiAccordionSummary-content': { marginTop: 1, marginBottom: 3 } },
                  ]}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={t('parkingAddress.parkingAddress')}
                  id={t('parkingAddress.parkingAddress')}
                >
                  <Typography variant='subtitle2'>{t('parkingAddress.parkingAddress')}</Typography>
                </AccordionSummary>
                {BuildingUtils.isPorto(selectedBuildingOption) && (
                  <AccordionDetails sx={{ paddingLeft: 0, paddingBottom: 3 }}>
                    {renderParkingAddress(portoOfficeParkingAddress)}
                    <Typography variant='body1' sx={{ paddingBottom: 1 }}>
                      <Trans components={{ 1: <strong /> }} t={t} i18nKey={'parkingAddress.officeGaragePortoApp'} />
                    </Typography>
                    <Typography variant='body1' sx={{ paddingBottom: 2 }}>
                      <Trans components={{ 1: <strong /> }} t={t} i18nKey={'parkingAddress.parkInIdentifiedPlaces'} />
                    </Typography>
                    {renderParkingAddress(portoExternalParkingAdress)}
                    <Typography>
                      <Trans
                        variant='body1'
                        components={{ 1: <strong /> }}
                        t={t}
                        i18nKey={'parkingAddress.externalGarageInfo'}
                      />
                    </Typography>
                  </AccordionDetails>
                )}
                {BuildingUtils.isLisbon(selectedBuildingOption) && (
                  <AccordionDetails sx={{ paddingLeft: 0 }}>
                    {renderParkingAddress(lisbonOfficeParkingAdress)}
                  </AccordionDetails>
                )}
              </Accordion>
            )}

            <Accordion disableGutters className={classes.accordionStyles}>
              <AccordionSummary
                sx={[
                  { padding: 0 },
                  { '& .MuiAccordionSummary-expandIconWrapper': { color: theme.colors.grey.main } },
                  { '& .MuiAccordionSummary-content': { marginTop: 1, marginBottom: 3 } },
                ]}
                expandIcon={<ExpandMoreIcon />}
                aria-controls={t('parkingRules.parkingRules')}
                id={t('parkingRules.parkingRules')}
              >
                <Typography variant='subtitle2'>{t('parkingRules.parkingRules')}</Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ paddingLeft: 0, paddingBottom: 1 }}>
                <Grid container>{renderRules(parkingRules)}</Grid>
              </AccordionDetails>
            </Accordion>
            <Accordion disableGutters className={classes.accordionStyles}>
              <AccordionSummary
                sx={[
                  { padding: 0 },
                  { '& .MuiAccordionSummary-expandIconWrapper': { color: theme.colors.grey.main } },
                  { '& .MuiAccordionSummary-content': { marginTop: 1, marginBottom: 3 } },
                ]}
                expandIcon={<ExpandMoreIcon />}
                aria-controls={t('reservationRules.reservationRules')}
                id={t('reservationRules.reservationRules')}
              >
                <Typography variant='subtitle2'>{t('reservationRules.reservationRules')}</Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ paddingLeft: 0, paddingBottom: 1 }}>
                <Grid container>{renderRules(reservationRules)}</Grid>
              </AccordionDetails>
            </Accordion>
            <Accordion disableGutters className={classes.accordionStyles}>
              <AccordionSummary
                sx={[
                  { padding: 0 },
                  { '& .MuiAccordionSummary-expandIconWrapper': { color: theme.colors.grey.main } },
                  { '& .MuiAccordionSummary-content': { marginTop: 1, marginBottom: 3 } },
                ]}
                expandIcon={<ExpandMoreIcon />}
                aria-controls={t('prevention.prevention')}
                id={t('prevention.prevention')}
              >
                <Typography variant='subtitle2'>{t('prevention.prevention')}</Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ paddingLeft: 0 }}>
                <Grid container>{renderRules(preventionRules)}</Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </Grid>
      {open && <GarageBlueprintModal selectedBuildingOption={selectedBuildingOption} onClose={handleClose} />}
    </>
  );
  function renderBuildings() {
    const orderedBuildings: Location[] = orderBy(buildings, ['rank']);

    return (
      <FormControl className={classes.formContainerHidden}>
        <FormLabel sx={{ marginBottom: 2 }}>
          <Typography variant='subtitle2'>{t('officeLocation.officeLocation')}</Typography>
        </FormLabel>
        <RadioGroup
          aria-label={t('officeLocation.officeLocation')}
          name={t('officeLocation.officeLocation')}
          onChange={onChangeBuilding}
          value={selectedBuildingOption}
        >
          {map(orderedBuildings, (building, index) => (
            <FormControlLabel
              control={<BlueRadio />}
              key={index}
              value={building.id}
              label={building.name}
              componentsProps={{
                typography: {
                  variant: 'subtitle1',
                },
              }}
            />
          ))}
        </RadioGroup>
      </FormControl>
    );
  }

  function renderBlueprintButtonText() {
    return (
      <Typography variant='body1' className={classes.garageBlueprintButtonText}>
        {!isTablet && <IconDoc className={classes.iconDoc} />}
        {BuildingUtils.isPorto(selectedBuildingOption) && (
          <Trans t={t} components={{ 1: <p /> }} i18nKey={'officeLocation.portoGarageBlueprintButtonText'} />
        )}
        {BuildingUtils.isLisbon(selectedBuildingOption) && (
          <Trans t={t} components={{ 1: <p /> }} i18nKey={'officeLocation.lisbonGarageBlueprintButtonText'} />
        )}
      </Typography>
    );
  }

  function renderRules(rules: Rule[]) {
    return map(rules, (rule, index) => (
      <Grid
        key={index}
        item
        container
        sx={{ marginBottom: 2, display: 'flex', alignItems: 'center' }}
        className={classes.mobileGridAlignment}
        columns={{ xs: 4, md: 12 }}
      >
        <Grid
          item
          container
          direction={'row'}
          justifyContent={'center'}
          alignItems={'center'}
          xs={1}
          className={classes.icons}
        >
          {rule.Icon && <rule.Icon />}
        </Grid>
        <Grid xs={3} md={10}>
          <Typography variant='body1'>
            <Trans components={{ 1: <strong />, 2: <li />, 3: <u /> }} t={t} i18nKey={rule.description} />
          </Typography>
        </Grid>
      </Grid>
    ));
  }

  function renderParkingAddress(rules: Rule[]) {
    return map(rules, (rule, index) => (
      <div key={index} className={classes.parkingAddress}>
        <div className={classNames(classes.flexRowAlignCenter, classes.parkingAddressDetails)}>
          <IconPin className={classes.pinIcon} />
          <Typography variant='subtitle1' className={classes.parkingTitle}>
            <Trans components={{ 1: <strong /> }} t={t} i18nKey={rule.title} />
          </Typography>
        </div>
        <div className={classNames(classes.flexRowAlignCenter, classes.parkingAddressDetails)}>
          <Typography variant='body1'>
            <Trans t={t} i18nKey={rule.address} />
          </Typography>
        </div>
        <Typography variant='body1'>
          <a href={rule.linkOne} target='_blank' rel='noreferrer'>
            <a href={rule.linkTwo} className={classes.openMaps} target='_blank' rel='noreferrer'>
              {t('parkingAddress.openMaps')}
            </a>
          </a>
        </Typography>
      </div>
    ));
  }
};
export type Props = {
  place?: Place;
};

export default Rules;
