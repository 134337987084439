/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import React from 'react';
import DateRangePickerComp, { DateRangePickerProps } from '@wojtekmaj/react-daterange-picker';
import { ReactComponent as IconCalendar } from '../../../assets/svg/calendar.svg';
import { ReactComponent as IconLeft } from '../../../assets/svg/icon_arrow_left.svg';
import { ReactComponent as IconRight } from '../../../assets/svg/icon_arrow_right.svg';
import '../../../sass/shared/DateRangePicker.scss';
import '../../../sass/shared/Calendar.scss';

const DateRangePicker: React.FC<DateRangePickerProps> = (props) => (
  <DateRangePickerComp
    calendarClassName={['calendar-picker', 'calendar-picker-flyout']}
    calendarIcon={<IconCalendar className='react-daterange-picker__button__icon' />}
    clearIcon={null}
    locale={'en'}
    format={'dd/MM/y'}
    nextLabel={<IconRight />}
    prevLabel={<IconLeft />}
    showLeadingZeros
    {...props}
  />
);

export default DateRangePicker;
