import config from '../../config/config';
import { RolesEnum } from '../../enums/roles.enum';

export class RolesUtil {
  static hasRole(role: RolesEnum, userRoles?: string[]): boolean {
    if (!userRoles || (userRoles && userRoles.length === 0)) {
      return false;
    }
    return config.STAGE === 'prod'
      ? userRoles.includes(role)
      : userRoles.includes(role) || userRoles.includes(RolesEnum.TETRIS_DEV.toString());
  }
}
