import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { muiTheme, theme } from '../../../styles/theme';
import sharedStyles from '../../../styles/sharedStyles/sharedStyles';
import { pxToRemOrEm } from '../../../styles/utilities';

const shared = sharedStyles();

export default makeStyles(() =>
  createStyles({
    ...shared,

    container: {
      paddingBlock: '32px',
      height: 'fit-content',
      margin: '0 auto',
      [muiTheme.breakpoints.up('sm')]: {
        maxWidth: '500px',
      },
      [muiTheme.breakpoints.up('sm')]: {
        maxWidth: '1000px',
      },
      [muiTheme.breakpoints.down('md')]: {
        paddingInline: '24px',
      },
    },

    fontBold: {
      fontWeight: 'bold',
    },

    remoteWorking: {
      color: theme.colors.orange.main,
      fontSize: '16px',
      ...shared.flexRowAlignFlexStartJustifyCenter,
      textTransform: 'capitalize',
    },

    iconWarning: {
      marginRight: '10px',
      width: '20px',
    },

    title: {
      textAlign: 'center',
      fontWeight: 'bold',
      color: theme.colors.grey.main,
      lineHeight: 1,
      marginBottom: muiTheme.spacing(2),
    },

    lockdownWarning: {
      ...shared.flexColumnAlignCenter,
      textAlign: 'center',
    },

    whiteSpacePreLineLockdownWarning: {
      whiteSpace: 'pre-line',
    },

    subTitleContainer: {
      flexBasis: 'auto',
    },

    subTitle: {
      color: theme.colors.grey.medium,
      fontSize: pxToRemOrEm(12),
      lineHeight: 1,
      marginBottom: muiTheme.spacing(2),
    },

    dateSliderAndLocationTagWrapper: {
      ...shared.flexColumnAlignCenter,
      marginBottom: muiTheme.spacing(5),
    },

    marginTop: {
      marginTop: 30,
    },
  })
);
