import { ReservationError } from '../../models/parkingReservation/parkingReservation';

export class ReservationErrorUtil {
  static checkIfNoErrors(errors: ReservationError[] | undefined): boolean {
    return errors?.length === 0;
  }

  static joinErrorMessages(errors: ReservationError[] | undefined): string {
    return (
      errors?.reduce<string>((result, error) => {
        if (error?.message) {
          return result !== '' ? result.concat('; ', error.message) : error.message;
        }
        return result;
      }, '') || ''
    );
  }
}
