import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import sharedStyles from '../../../../styles/sharedStyles/sharedStyles';
import { muiTheme } from '../../../../styles/theme';

const shared = sharedStyles();

export default makeStyles(() =>
  createStyles({
    ...shared,

    switchMargins: {
      marginBottom: 10,
      marginLeft: 10,
      [muiTheme.breakpoints.up('md')]: {
        marginBottom: 0,
        marginRight: 10,
      },
    },

    marginLeft1: {
      marginLeft: muiTheme.spacing(1),
    },
  })
);
