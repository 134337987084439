import { LICENSE_PLATE_REGEX } from '../../constants/regex';
import { EngineTypeEnum } from '../../enums/engineType.enum';
import { Booking } from '../../models/booking/booking';
import { TeamSpaceMember } from '../../models/teamSpaceMember/team-space-member';

export class BookingUtil {
  static getTeamSpaceUsers = (booking: Booking | undefined): Pick<TeamSpaceMember, 'person' | 'needParkingSpace'>[] => {
    return (
      booking?.bookingsInfo?.reduce<Pick<TeamSpaceMember, 'person' | 'hasParkingInformation' | 'needParkingSpace'>[]>(
        (result, user) => {
          return [
            ...result,
            {
              person: [
                {
                  userPrincipalName: user.email,
                  displayName: user.name,
                },
              ],
              needParkingSpace: user.needParkingSpace,
            },
          ];
        },
        [
          {
            person: [
              {
                userPrincipalName: booking?.user?.email,
                displayName: booking?.user?.username,
              },
            ],
            needParkingSpace: !!booking?.parking,
          },
        ]
      ) || []
    );
  };

  static validateLicensePlateAndEngineType = ({
    licensePlate = '',
    engineType,
  }: {
    licensePlate?: string;
    engineType?: EngineTypeEnum;
  }) => {
    return LICENSE_PLATE_REGEX.test(licensePlate) && Boolean(engineType);
  };
}
