import React from 'react';
import { SnackbarProvider as NotistackProvider } from 'notistack';
import { mdiClose } from '@mdi/js/mdi';
import IconButton from '@mui/material/IconButton';
import Icon from '@mdi/react';
import useStyles from './SnackbarProvider.style';

const SnackbarProvider: React.FC<Props> = ({ children }) => {
  const classes = useStyles();
  const ref = React.createRef<NotistackProvider>();

  return (
    <NotistackProvider
      ref={ref}
      action={(key) => (
        <IconButton onClick={() => ref.current?.closeSnackbar(key)} size='large'>
          <Icon path={mdiClose} size={1} color='#393939' />
        </IconButton>
      )}
      preventDuplicate
      hideIconVariant
      maxSnack={1}
      autoHideDuration={8000}
      classes={{
        root: classes.root,
        variantSuccess: classes.success,
        variantError: classes.error,
      }}
      anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
    >
      {children}
    </NotistackProvider>
  );
};

interface Props {
  children: React.ReactNode;
}

export default SnackbarProvider;
