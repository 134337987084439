import moment, { MomentInput } from 'moment';
import { DATE_FORMAT_MDY } from '../../constants/dateFormat';

export default class Formatters {
  public static formatDateISOYearMonthDay(date: MomentInput) {
    return moment(date).format('YYYY-MM-DD');
  }

  public static momentFormatDate(date: MomentInput) {
    return moment(date);
  }

  public static formatDateISOMonthDayYear(date: MomentInput) {
    return moment(date).format(DATE_FORMAT_MDY);
  }

  public static formatDayMonthYearDate(date: MomentInput) {
    const dateToFormat = moment(date);

    return {
      day: dateToFormat.format('D'),
      weekday: dateToFormat.format('dddd'),
      month: dateToFormat.format('MMM'),
      year: dateToFormat.year(),
    };
  }

  public static formatOrdinalNumber(number: string | undefined) {
    switch (number?.toLowerCase()) {
      case 'first':
        return '1st';
      case 'second':
        return '2nd';
      case 'third':
        return '3rd';
      case 'fourth':
        return '4th';
      case 'fifth':
        return '5th';
      case 'sixth':
        return '6th';
      case 'seventh':
        return '7th';
      case 'eighth':
        return '8th';
      case 'ninth':
        return '9th';
      case 'tenth':
        return '10th';
      default:
        return null;
    }
  }
}
