import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import sharedStyles from '../../../../styles/sharedStyles/sharedStyles';
import { muiTheme, theme } from '../../../../styles/theme';
import { pxToRemOrEm } from '../../../../styles/utilities';

export default makeStyles(() =>
  createStyles({
    ...sharedStyles(),

    container: {
      paddingBlock: '32px',
      marginTop: muiTheme.spacing(2),
      maxWidth: 524,
      margin: '0 auto',
      [muiTheme.breakpoints.down('md')]: {
        padding: '32px 24px',
      },
    },

    image: {
      maxWidth: '100%',
    },

    confirmButton: {
      margin: '0 auto',
      background: theme.colors.blue.royal,
    },

    guestInformation: {
      paddingTop: '16px',
    },

    guestList: {
      listStyle: 'none',
      paddingLeft: 0,
      paddingTop: '8px',
      margin: 0,
      textAlign: 'center',
    },

    title: {
      color: theme.colors.grey.main,
      fontWeight: 'bold',
      fontSize: pxToRemOrEm(24),
      lineHeight: '28px',
      marginBottom: muiTheme.spacing(2),
      paddingTop: '24px',
      textAlign: 'center',
    },

    warningMessage: {
      textAlign: 'center',
      marginBottom: muiTheme.spacing(3),
    },

    reservationInformation: {
      textAlign: 'center',
      marginBottom: muiTheme.spacing(2),
    },

    teamSpaceReservationMessage: {
      textAlign: 'center',
    },

    marginTop2: {
      marginTop: muiTheme.spacing(2),
    },

    marginTop3: {
      marginTop: muiTheme.spacing(3),
    },

    marginTop4: {
      marginTop: muiTheme.spacing(4),
    },

    marginTop92: {
      marginTop: 92,
    },

    fontBold: {
      fontWeight: 'bold',
    },

    noWrap: {
      whiteSpace: 'nowrap',
    },
  })
);
