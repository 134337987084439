import { ReactComponent as IconCalendar } from '../../../assets/svg/calendar.svg';
import { ReactComponent as IconElectricVehicleType } from '../../../assets/svg/electric_circulation_rules.svg';
import { ReactComponent as IconFuel } from '../../../assets/svg/fuel_caption.svg';
import { ReactComponent as IconSticker } from '../../../assets/svg/sticker.svg';
import { ReactComponent as IconGroup } from '../../../assets/svg/group-line.svg';
import { ReactComponent as IconFuelPorto } from '../../../assets/svg/fuel_porto.svg';
import { ReactComponent as IconBin } from '../../../assets/svg/icon_bin.svg';
import { ReactComponent as IconMotorcycle } from '../../../assets/svg/motor.svg';
import { ReactComponent as IconSpeedLimit } from '../../../assets/svg/speed.svg';
import { ReactComponent as IconElectric } from '../../../assets/svg/electric.svg';
import { ReactComponent as IconTime } from '../../../assets/svg/time.svg';
import { ReactComponent as IconDoc } from '../../../assets/svg/doc.svg';
import { ReactComponent as IconCar } from '../../../assets/svg/car.svg';
import { ReactComponent as IconUserAddLine } from '../../../assets/svg/user-add-line.svg';
import { ReactComponent as IconBicycle } from '../../../assets/svg/bike.svg';
import { ReactComponent as IconCsw } from '../../../assets/svg/csw.svg';
import { ReactComponent as IconWhite } from '../../../assets/svg/icon_white.svg';
export interface Rule {
  title?: string;
  address?: string;
  description?: string;
  Icon?:
    | React.ReactNode
    | React.FunctionComponent
    | string
    | number
    | true
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | Iterable<React.ReactNode>
    | React.ReactPortal
    | any;
  openMaps?: string;
  linkOne?: string;
  linkTwo?: string;
}

export interface Legend {
  description?: string;
  Icon?:
    | React.ReactNode
    | React.FunctionComponent
    | string
    | number
    | true
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | Iterable<React.ReactNode>
    | React.ReactPortal
    | any;
}

export const portoOfficeParkingAddress: Rule[] = [
  {
    title: 'parkingAddress.officeGarage',
    address: 'parkingAddress.officeGaragePortoAddress',
    linkOne: 'maps:?cp=41.15031~-8.60972',
    linkTwo: 'https://www.google.com/maps?q=41.15031,-8.60972',
  },
];

export const portoExternalParkingAdress: Rule[] = [
  {
    title: 'parkingAddress.externalGarage',
    address: 'parkingAddress.externalGaragePortoAddress',
    linkOne: 'maps:?cp=41.150560~-8.610390',
    linkTwo: 'https://www.google.com/maps?q=41.150560,-8.610390',
  },
];

export const lisbonOfficeParkingAdress: Rule[] = [
  {
    title: 'parkingAddress.officeGarage',
    address: 'parkingAddress.officeGarageLisbonAddress',
    linkOne: 'maps:?cp=38.74396998842431~-9.14859996603272',
    linkTwo: 'https://www.google.com/maps?q=38.74396998842431,-9.14859996603272',
  },
];

export const parkingRules: Rule[] = [
  {
    description: 'parkingRules.reservationIsMandatory',
    Icon: IconCalendar,
  },
  {
    description: 'parkingRules.phevRules',
    Icon: IconTime,
  },
  {
    description: 'parkingRules.parkingAvailabilityTime',
    Icon: IconCar,
  },
  {
    description: 'rules.moreInfo',
    Icon: IconWhite,
  },
];

export const reservationRules: Rule[] = [
  {
    description: 'reservationRules.parkingFeatureRules',
    Icon: IconCalendar,
  },
  {
    description: 'reservationRules.typeOfVehicleIsMandatory',
    Icon: IconCsw,
  },
  {
    description: 'reservationRules.teamSpaceBooking',
    Icon: IconTime,
  },
  {
    description: 'reservationRules.addPeopleToTeamSpace',
    Icon: IconCar,
  },
  {
    description: 'rules.moreInfo',
    Icon: IconWhite,
  },
];

export const preventionRules: Rule[] = [
  {
    description: 'prevention.keepDistance',
    Icon: IconCalendar,
  },
  {
    description: 'prevention.washHands',
    Icon: IconTime,
  },
  {
    description: 'prevention.useMask',
    Icon: IconDoc,
  },
  {
    description: 'prevention.coverFace',
    Icon: IconCar,
  },
  {
    description: 'rules.moreInfo',
    Icon: IconWhite,
  },
];

export const lisbonFloors = ['-1', '-2', '-3', '-4'] as const;
type LisbonFloorsType = typeof lisbonFloors;
export type LisbonFloors = LisbonFloorsType[number];

export const lisbonBlueprintLegend: {
  floor: LisbonFloors;
  showElectricIcon: boolean;
  showCombustionIcon: boolean;
}[] = [
  {
    floor: '-1',
    showElectricIcon: true,
    showCombustionIcon: false,
  },
  {
    floor: '-2',
    showElectricIcon: true,
    showCombustionIcon: true,
  },
  {
    floor: '-3',
    showElectricIcon: false,
    showCombustionIcon: true,
  },
  {
    floor: '-4',
    showElectricIcon: false,
    showCombustionIcon: true,
  },
];

export const portoBlueprintLegend: Legend[] = [
  {
    description: 'garageBlueprintModal.electricVehicleType',
    Icon: IconElectricVehicleType,
  },
  {
    description: 'garageBlueprintModal.combustionVehicleType',
    Icon: IconFuelPorto,
  },
  {
    description: 'garageBlueprintModal.motorcycleParking',
    Icon: IconMotorcycle,
  },
  {
    description: 'garageBlueprintModal.bicycleParking',
    Icon: IconBicycle,
  },
];
