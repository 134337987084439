import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import sharedStyles from '../../../../../styles/sharedStyles/sharedStyles';
import { theme } from '../../../../../styles/theme';

const padding = 5;
const underlineSize = '4ch';
export default makeStyles(() =>
  createStyles({
    ...sharedStyles(),

    menuItemLink: {
      color: 'inherit',
      textDecoration: 'inherit',
      padding,
      '&:visited': {
        color: 'inherit',
        textDecoration: 'inherit',
      },
    },

    menuItem: {
      '&:after': {
        content: '""',
        display: 'block',
        marginLeft: padding,
        width: 0,
        height: 1,
        borderBottom: `3px solid ${theme.colors.blue.main}`,
        transition: 'width .3s',
      },
      '&:hover:after': {
        width: underlineSize,
      },
      '&:focus:after': {
        width: underlineSize,
      },
    },

    active: {
      color: `${theme.colors.blue.royal} !important`,
      '&:after': {
        borderBottom: `3px solid ${theme.colors.blue.royal}`,
        width: underlineSize,
      },
    },
  })
);
