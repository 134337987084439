import React from 'react';
import { buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { CircularProgressbarDefaultProps } from 'react-circular-progressbar/dist/types';
import { Trans, useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { round } from 'lodash';
import './CircularProgressPath.css';
import useStyles from './ReservationCapacityProgress.style';
import { Location } from '../../../../models/location/location';
import { theme } from '../../../../styles/theme';
import { Typography } from '@mui/material';

const ReservationCapacityProgress: React.FC<Props> = ({ buildingStatus }) => {
  const classes = useStyles();

  const currentOccupation = buildingStatus?.currentOccupation || 0;
  const allowedCapacity = buildingStatus?.maxCapacity || 0;

  const currentOccupationPercentage = buildingStatus?.maxCapacity
    ? round((currentOccupation / allowedCapacity) * 100)
    : 0;

  const currentOccupationPercentageColor =
    currentOccupation === 0 || currentOccupationPercentage < 100 ? classes.colorBlueRoyal : classes.colorRedMain;

  const { t } = useTranslation('dashboard');

  return (
    <>
      <div className={classes.ReservationCapacityProgressContainer}>
        <svg style={{ height: 0, width: 0 }}>{stopColorToggle()}</svg>
        <CircularProgressbarWithChildren
          value={currentOccupationPercentage}
          styles={buildStyles({
            rotation: 1,
            strokeLinecap: 'round',
            pathTransitionDuration: 0.5,
            textColor: theme.colors.orange.main,
            trailColor: 'transparent',
            backgroundColor: theme.colors.white.mainBg,
          })}
        >
          <div className={classes.childrenContainer}>
            <div className={classes.childrenWrapper}>
              <Typography className={classNames(classes.progressText, currentOccupationPercentageColor)}>
                {t('reservationCapacityProgress.currentOccupationPercentage', {
                  currentOccupationPercentage,
                })}
              </Typography>

              <Typography variant='body2' className={classes.reservationText}>
                <Trans i18nKey='reservationCapacityProgress.reservations' count={currentOccupation} t={t} />
              </Typography>
            </div>
          </div>
        </CircularProgressbarWithChildren>
      </div>
      <div className={classes.totalCapacityText}>
        <Typography variant='body1'>
          <Trans
            i18nKey='reservationCapacityProgress.totalCapacity'
            count={allowedCapacity}
            components={{ 1: <strong /> }}
            values={{ allowedCapacity }}
          />
        </Typography>
      </div>
    </>
  );

  function stopColorToggle() {
    if (currentOccupationPercentage < 75) {
      return (
        <linearGradient
          id={'gradientId'}
          data-testid='gradientLessThan75'
          x1='0'
          y1='0'
          x2='2'
          y2='1'
          gradientTransform={'rotate(18)'}
        >
          <stop offset='0%' stopColor={theme.colors.blue.royal}></stop>
          <stop offset='60%' stopColor={theme.colors.blue.royal}></stop>
          <stop offset='80%' stopColor={theme.colors.blue.royal}></stop>
          <stop offset='100%' stopColor={theme.colors.blue.royal}></stop>
        </linearGradient>
      );
    } else if (currentOccupationPercentage >= 75 && currentOccupationPercentage < 90) {
      return (
        <linearGradient
          id={'gradientId'}
          data-testid='gradientLessThan90'
          x1='0'
          y1='0'
          x2='2'
          y2='1'
          gradientTransform={'rotate(3)'}
        >
          <stop offset='0%' stopColor={theme.colors.red.main}></stop>
          <stop offset='8%' stopColor={theme.colors.red.main}></stop>
          <stop offset='20%' stopColor={theme.colors.blue.royal}></stop>
          <stop offset='60%' stopColor={theme.colors.blue.royal}></stop>
          <stop offset='80%' stopColor={theme.colors.blue.royal}></stop>
          <stop offset='100%' stopColor={theme.colors.blue.royal}></stop>
        </linearGradient>
      );
    } else if (currentOccupationPercentage >= 90 && currentOccupationPercentage < 93) {
      return (
        <linearGradient
          id={'gradientId'}
          data-testid='gradientLessThan93'
          x1='0'
          y1='0'
          x2='2'
          y2='1'
          gradientTransform={'rotate(6)'}
        >
          <stop offset='0%' stopColor={theme.colors.red.main}></stop>
          <stop offset='9%' stopColor={theme.colors.red.main}></stop>
          <stop offset='20%' stopColor={theme.colors.blue.royal}></stop>
          <stop offset='60%' stopColor={theme.colors.blue.royal}></stop>
          <stop offset='80%' stopColor={theme.colors.blue.royal}></stop>
          <stop offset='100%' stopColor={theme.colors.blue.royal}></stop>
        </linearGradient>
      );
    } else if (currentOccupationPercentage >= 93 && currentOccupationPercentage < 98) {
      return (
        <linearGradient
          id={'gradientId'}
          data-testid='gradientLessThan98'
          x1='0'
          y1='0'
          x2='2'
          y2='1'
          gradientTransform={'rotate(10)'}
        >
          <stop offset='0%' stopColor={theme.colors.red.main}></stop>
          <stop offset='9%' stopColor={theme.colors.red.main}></stop>
          <stop offset='20%' stopColor={theme.colors.blue.royal}></stop>
          <stop offset='60%' stopColor={theme.colors.blue.royal}></stop>
          <stop offset='80%' stopColor={theme.colors.blue.royal}></stop>
          <stop offset='100%' stopColor={theme.colors.blue.royal}></stop>
        </linearGradient>
      );
    } else if (currentOccupationPercentage >= 98 && currentOccupationPercentage <= 99) {
      return (
        <linearGradient
          id={'gradientId'}
          data-testid='gradientLessThan99'
          x1='0'
          y1='0'
          x2='2'
          y2='1'
          gradientTransform={'rotate(15)'}
        >
          <stop offset='0%' stopColor={theme.colors.red.main}></stop>
          <stop offset='12%' stopColor={theme.colors.red.main}></stop>
          <stop offset='20%' stopColor={theme.colors.blue.royal}></stop>
          <stop offset='60%' stopColor={theme.colors.blue.royal}></stop>
          <stop offset='80%' stopColor={theme.colors.blue.royal}></stop>
          <stop offset='100%' stopColor={theme.colors.blue.royal}></stop>
        </linearGradient>
      );
    }
    return (
      <defs>
        <linearGradient id={'gradientId'} data-testid='gradient100' gradientTransform={'rotate(90)'}>
          <stop offset='100%' stopColor={theme.colors.red.main} />
        </linearGradient>
      </defs>
    );
  }
};

export type Props = Partial<CircularProgressbarDefaultProps> & {
  buildingStatus: Location | undefined | null;
};

export default ReservationCapacityProgress;
