import { IUserInformationResponse } from '../../interfaces/userInformationResponse/parking-information-response';
import { TeamSpaceMember } from '../../models/teamSpaceMember/team-space-member';

export class TeamSpaceMemberAssembler {
  static fromUserInformationResponse(
    userInformationResponse: IUserInformationResponse
  ): Pick<TeamSpaceMember, 'email' | 'hasParkingInformation' | 'hasOverlappedBookings'> {
    return {
      email: userInformationResponse.email,
      hasParkingInformation: userInformationResponse.hasParkingInformation,
      hasOverlappedBookings: userInformationResponse.hasOverlappedBookings,
    };
  }

  static fromUserInformationResponseList(
    userInformationResponseList: IUserInformationResponse[]
  ): Pick<TeamSpaceMember, 'email' | 'hasParkingInformation' | 'hasOverlappedBookings'>[] {
    return userInformationResponseList?.map((userInformation) => this.fromUserInformationResponse(userInformation));
  }
}
