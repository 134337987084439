import React, { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { addDays, subDays } from 'date-fns';
import { isEmpty, map, orderBy } from 'lodash';
import { useSnackbar } from 'notistack';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import useStyles from './MyReservations.style';
import MyReservationCardContent from './MyReservationCardContent/MyReservationCardContent';
import BackToTopButton from '../../Shared/BackToTopButton/BackToTopButton';
import DateRangePicker from '../../Shared/DateRangePicker/DateRangePicker';
import Modal from '../../Shared/Modal/Modal';
import ReservationCard from '../../Shared/ReservationCard/ReservationCard';
import { ReactComponent as IconWarning } from '../../../assets/svg/icon_warning.svg';
import config from '../../../config/config';
import { useDeleteBooking, useGetUserBookings } from '../../../hooks/bookingHooks/bookingHooks';
import { Booking } from '../../../models/booking/booking';
import i18n from '../../../translations/i18n';

const MyReservations: React.FC<Props> = ({
  editedBooking,
  onChangeDate,
  onEditBooking,
  onEditTeamSpaceBooking,
  date = [new Date(), new Date()],
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  void i18n.loadNamespaces(['deleteModal', 'reservation']);
  const { t } = useTranslation(['deleteModal', 'reservation']);

  const { bookings, bookingsLoading } = useGetUserBookings(date as Date[]);
  const [currentBookings, setCurrentBookings] = useState<Booking[]>([]);

  const [deletionModalShown, setDeletionModalShown] = useState(false);
  const [confirmModalDeletion, setConfirmModalDeletion] = useState(false);
  const [bookingToBeDeleted, setBookingToBeDeleted] = useState<Booking | null>(null);
  const {
    deleteBooking,
    deleteBookingError,
    deleteBookingMessage,
    deleteBookingStatus,
    deleteBookingResponse,
    deleteBookingLoading,
  } = useDeleteBooking(bookingToBeDeleted?.id || '', confirmModalDeletion);

  useEffect(() => {
    bookings && setCurrentBookings(bookings);
  }, [bookings]);

  /* Delete logic */
  const onDeletedBooking = useCallback(() => {
    setCurrentBookings(currentBookings?.filter((b) => b.id !== bookingToBeDeleted?.id) || []);
    onDeleteModalClose();
  }, [bookingToBeDeleted, currentBookings]);

  const onDeleteModalClose = () => {
    // As the modal is closed, we need to change the `confirm` property
    // so that we don't accidentally confirm a team space deletion
    setConfirmModalDeletion(false);
    setDeletionModalShown(false);
    setBookingToBeDeleted(null);
  };

  useEffect(() => {
    bookingToBeDeleted && deleteBooking && void deleteBooking();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingToBeDeleted]);

  useEffect(() => {
    if (!bookingToBeDeleted || deleteBookingResponse === null) {
      return;
    }

    if (deleteBookingStatus === 200 && deleteBookingResponse) {
      void onDeletedBooking();
    }

    if (deleteBookingStatus === 412) {
      // As the modal is shown, we need to change the `confirm` property
      setDeletionModalShown(true);
      setConfirmModalDeletion(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteBookingStatus, deleteBookingResponse]);

  useEffect(() => {
    if (deleteBookingError || deleteBookingMessage) {
      enqueueSnackbar(deleteBookingError || deleteBookingMessage, {
        variant: deleteBookingError ? 'error' : 'success',
      });
    }
  }, [deleteBookingError, deleteBookingMessage, enqueueSnackbar]);

  const handleDeleteBooking = () => {
    void deleteBooking();
  };

  const renderDeleteModal = () => {
    return (
      <Modal
        confirmLabel={t('delete')}
        confirmLoading={deleteBookingLoading}
        onClose={onDeleteModalClose}
        onConfirm={handleDeleteBooking}
        subtitle={t('subtitle')}
        title={t('title')}
        buttons
        maxWidth
      >
        <div className={classNames(classes.modalBodyContainer, classes.modalBodyMarginReset)}>
          <IconWarning className={classNames(classes.warning, classes.warningIcon)} />
          <h3 className={classes.modalBodyHeading}>{t('sureToDelete')}</h3>
          <p className={classes.modalBodyText}>
            <Trans components={{ 1: <span className={classes.modalBodySpan} /> }} t={t} i18nKey={'body'} />
          </p>
        </div>
      </Modal>
    );
  };

  return (
    <>
      <Grid className={classes.container} container direction={'row'} justifyContent='center'>
        <Grid item xs={12} md={6}>
          <Typography className={classes.pageTitle} data-testid='myReservationsTitle'>
            {t('reservation:reservation.myReservations')}
          </Typography>
          <Typography variant={'caption'} className={classes.textContent}>
            {t('reservation:reservation.periodOfTimeLimitation')}
          </Typography>
          <div className={classNames(classes.marginTop, classes.flexColumnAlignFlexStartJustifyCenter)}>
            {MyReservationsDateRangePicker()}
          </div>
          <div
            className={classNames(classes.marginTop, classes.flexWrap, classes.flexColumnAlignFlexStartJustifyCenter)}
          >
            {renderBookings()}
          </div>
        </Grid>
      </Grid>
      <BackToTopButton />

      {/* Delete Modal */}
      {deletionModalShown && renderDeleteModal()}
    </>
  );

  function MyReservationsDateRangePicker() {
    const daysAfter = Array.isArray(date) && date[0] ? addDays(date[0] as Date, config.BOOKINGS_LIMIT - 1) : undefined;
    const daysBefore = Array.isArray(date) && date[1] ? subDays(date[1] as Date, config.BOOKINGS_LIMIT - 1) : undefined;

    return <DateRangePicker maxDate={daysAfter} minDate={daysBefore} onChange={onChangeDate} value={date} />;
  }

  function renderBookings() {
    if (bookingsLoading) {
      return null;
    }

    if (isEmpty(bookings)) {
      return <Typography data-testid='myReservationsEmpty'>{t('reservation:reservation.noReservations')}</Typography>;
    }

    return (
      <div className={classNames(classes.reservationCard, classes.gap24)}>
        {currentBookings &&
          map(
            orderBy(currentBookings, ['date'], ['asc']),
            (booking, idx) =>
              !isEmpty(booking) && (
                <ReservationCard
                  key={`card-${booking?.id || idx}`}
                  maxWidth={520}
                  minWidth={320}
                  onDeleteBooking={() => setBookingToBeDeleted(booking)}
                  onEditBooking={() => onEditBooking?.(booking)}
                  onEditTeamSpaceBooking={() => onEditTeamSpaceBooking?.(booking)}
                  booking={booking}
                  showIfIsTeamSpace
                  style={classNames(editedBooking === booking.id && classes.glow)}
                >
                  <MyReservationCardContent key={`card-content-${booking?.id || idx}`} booking={booking} />
                </ReservationCard>
              )
          )}
      </div>
    );
  }
};

interface Props {
  date?: string | Date | (string | Date)[];
  onChangeDate?: (value: string | Date | (string | Date)[]) => void;
  editedBooking?: string | null;
  onEditBooking?: (booking: Booking) => void;
  onEditTeamSpaceBooking?: (booking: Booking) => void;
}

export default MyReservations;
