import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { startCase } from 'lodash';
import { ClassNameMap } from '@mui/styles';
import useStyles from './MyReservationCardContent.style';
import { EngineTypeEnum } from '../../../../enums/engineType.enum';
import { PlaceTypeEnum } from '../../../../enums/placeType.enum';
import { Booking } from '../../../../models/booking/booking';
import { ReactComponent as IconElectricalServices } from '../../../../assets/svg/electrical_services_black_24dp.svg';
import { ReactComponent as IconLocalGas } from '../../../../assets/svg/local_gas_station_black_24dp.svg';
import classNames from 'classnames';
import { GarageTypeEnum } from '../../../../enums/garageType.enum';

let classes: ClassNameMap;

const MyReservationCardContent: React.FC<Props> = ({ booking }) => {
  classes = useStyles();

  const floor = booking.location;
  const building = booking.building;
  const place = booking.place;
  const isCombustionParking = booking.user?.engineType === EngineTypeEnum.COMBUSTION;

  // TODO: delete the line below when we create wings type and rename the place names
  const isPlaceHotOrFixedDesks = place?.type === PlaceTypeEnum.HOT_DESKS || place?.type === PlaceTypeEnum.FIXED_DESKS;
  const isTeamSpace = booking?.place?.type?.includes(PlaceTypeEnum.TEAM_SPACE);

  const getWingName = () => {
    if (booking.place?.wing) {
      return !booking.place.wing?.toLowerCase().includes('no')
        ? `${startCase(String(booking.place?.wing).toLowerCase())}`
        : undefined;
    }
    return undefined;
  };
  const wing = getWingName();
  const { t } = useTranslation('reservation');

  return (
    <main className={classes.main}>
      <section>
        <h1 className={classes.title}>{t('reservationCardContent.spaceOccupation')}</h1>
        <div className={classNames(classes.content, classes.colorBlueRoyal, isTeamSpace && classes.colorBlueDark)}>
          <p>
            {wing
              ? t('reservationCardContent.buildingAndWing', {
                  buildingName: building?.name,
                  wing,
                })
              : t('reservationCardContent.building', {
                  buildingName: building?.name,
                })}
          </p>
          <p>
            {floor &&
              t('reservationCardContent.floorAndPlace', {
                floorName: floor.name,
                placeName: place?.name,
              })}

            {!isPlaceHotOrFixedDesks &&
              place?.maxCapacity &&
              t('reservationCardContent.teamSpaceOccupation', {
                placeCurrentOccupation: place?.currentOccupation || 0,
                placeMaxCapacity: place?.maxCapacity || 0,
              })}
          </p>
        </div>
      </section>
      {booking.parking && (
        <section>
          <h1 className={classes.title}>{t('reservationCardContent.parkingSpace')}</h1>
          <div className={classes.content}>
            <div className={classes.reservedGarage}>
              <p>
                <Trans
                  components={{
                    1: (
                      <span
                        className={classNames(
                          booking.parking?.garageType === GarageTypeEnum.OFFICE_GARAGE
                            ? classes.reservedGaragePlace
                            : classes.reservedGaragePlaceExternal,
                          classes.marginLeft1
                        )}
                      />
                    ),
                  }}
                  values={{ bookingParkingName: booking.parking?.garageType }}
                  t={t}
                  i18nKey={'reservationCardContent.parkingSpaceReserved'}
                ></Trans>
                <span className={classes.parkingIcon}>
                  {isCombustionParking ? <IconLocalGas /> : <IconElectricalServices />}
                </span>
              </p>
            </div>
            <p>{booking.user?.licensePlate?.match(/.{2}/g)?.join(' ')}</p>
          </div>
        </section>
      )}
    </main>
  );
};

export type Props = {
  booking: Booking;
};

export default MyReservationCardContent;
