import createStyles from '@mui/styles/createStyles';
import { theme } from '../theme';

/**
 * Colors styles :
 *
 * - color
 * - backgroundColor
 * - fill
 *
 */

export const colorsStyles = createStyles({
  colorWhiteMain: {
    color: theme.colors.white.main,
  },

  colorBlueRoyal: {
    color: `${theme.colors.blue.royal}!important`,
  },

  colorGreyDark: {
    color: theme.colors.grey.dark,
  },

  colorBlueMain: {
    color: theme.colors.blue.main,
  },

  colorBlueDark: {
    color: `${theme.colors.blue.dark}!important`,
  },

  colorOrangeMain: {
    color: theme.colors.orange.main,
  },

  colorRedMain: {
    color: `${theme.colors.red.main}!important`,
  },

  fillWhiteMain: {
    '& g, & path, & polygon': {
      fill: theme.colors.white.main,
    },
  },

  fillBlueMain: {
    '& g, & path, & polygon': {
      fill: theme.colors.blue.main,
    },
  },
});
