import createStyles from '@mui/styles/createStyles';
import { pxToRemOrEm } from '../utilities';

/**
 * Font-sizes styles :
 *
 * - 8
 * - 16
 * - 24
 * - 32
 * - 40
 * - 48
 *
 */

const fontSizes = createStyles({
  fontSize8: {
    fontSize: pxToRemOrEm(8),
    lineHeight: pxToRemOrEm(8),
  },

  fontSize10: {
    fontSize: pxToRemOrEm(10),
    lineHeight: pxToRemOrEm(10),
  },

  fontSize12: {
    fontSize: pxToRemOrEm(12),
    lineHeight: pxToRemOrEm(12),
  },

  fontSize14: {
    fontSize: pxToRemOrEm(14),
    lineHeight: pxToRemOrEm(14),
  },

  fontSize16: {
    fontSize: pxToRemOrEm(16),
    lineHeight: pxToRemOrEm(16),
  },

  fontSize18: {
    fontSize: pxToRemOrEm(18),
    lineHeight: pxToRemOrEm(18),
  },

  fontSize24: {
    fontSize: pxToRemOrEm(24),
    lineHeight: pxToRemOrEm(24),
  },

  fontSize32: {
    fontSize: pxToRemOrEm(32),
    lineHeight: pxToRemOrEm(32),
  },

  fontSize40: {
    fontSize: pxToRemOrEm(40),
    lineHeight: pxToRemOrEm(40),
  },

  fontSize48: {
    fontSize: pxToRemOrEm(48),
    lineHeight: pxToRemOrEm(48),
  },
});

export default fontSizes;
