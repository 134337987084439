import { useEffect, useState } from 'react';
import { Providers, ProviderState } from '@microsoft/mgt-element';
import config from '../../config/config';
import jwtDecode from 'jwt-decode';

// from https://github.com/nmetulev/graph-react-starter/blob/a413a3bfc8a2d9851bc41ca73862b734fb6127f6/src/mgt.ts
export function useIsSignedIn(): boolean | undefined {
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const updateState = () => {
      const provider = Providers.globalProvider;
      if (provider.state !== ProviderState.Loading) {
        setIsSignedIn(provider.state === ProviderState.SignedIn);
        setLoading(false);
      }
    };

    Providers.onProviderUpdated(updateState);
    updateState();

    return () => {
      Providers.removeProviderUpdatedListener(updateState);
    };
  }, []);

  return loading ? undefined : isSignedIn;
}

export function useGetUserRoles(): string[] {
  const [roles, setRoles] = useState<string[]>([]);

  useEffect(() => {
    interface JwtPayload {
      roles?: string[];
    }
    const provider = Providers.globalProvider;
    const getUserToken = async () => {
      if (provider.state === ProviderState.SignedIn) {
        const token = await Providers.globalProvider.getAccessToken({ scopes: [config.API_SCOPE] });
        const decoded = jwtDecode<JwtPayload>(token);
        setRoles(decoded.roles ?? ([] as string[]));
      }
    };

    void getUserToken();
  }, []);

  return roles;
}
