import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { EndpointActions, useFetch } from '../ajaxHooks/ajaxHooks';
import { BookingAssembler } from '../../assemblers/booking/booking-assembler';
import { BookingReportAssembler } from '../../assemblers/bookingReport/booking-report-assembler';
import { IBookingRequest } from '../../interfaces/booking/booking-request';
import { IBookingResponse } from '../../interfaces/booking/booking-response';
import { IBookingReportResponse } from '../../interfaces/bookingReport/booking-report-response';
import { Place } from '../../models/place/place';
import { getSelectedPlace } from '../../redux/selectors/bookingSelector';
import { IUserReportResponse } from '../../interfaces/user/user-report-response';
import { UserReportAssembler } from '../../assemblers/userReport/user-report-assembler';

// ************************************************************************
// CREATE BOOKING
// ************************************************************************
export const useCreateBooking = (bookingRequest: IBookingRequest, place: Place) => {
  const endpoint = EndpointActions.CreateBooking([place?.id || '', bookingRequest]);
  const { status, run, response, loading, error } = useFetch<IBookingResponse[]>(endpoint, [], { defer: true });
  const bookingResponse = useMemo(() => response && BookingAssembler.fromList(response), [response]);

  return {
    status,
    createBooking: run,
    booking: response && bookingResponse,
    bookingLoading: loading,
    bookingError: error,
  };
};

// ************************************************************************
// EDIT BOOKING
// ************************************************************************
export const useEditBooking = (bookingRequest: IBookingRequest) => {
  const endpoint = EndpointActions.EditBooking([bookingRequest]);
  const { status, run, response, loading, error } = useFetch<IBookingResponse>(endpoint, [], { defer: true });
  const bookingResponse = useMemo(() => response && BookingAssembler.from(response), [response]);

  return {
    status,
    editBooking: run,
    response: response && bookingResponse,
    loading,
    error,
  };
};

// ************************************************************************
// GET USER BOOKINGS
// ************************************************************************
export const useGetUserBookings = (date: Date[]) => {
  const endpoint = EndpointActions.GetUserBookings([date]);
  const { response, loading, error } = useFetch<IBookingResponse[]>(endpoint, [date]);
  const bookingResponse = useMemo(() => response && BookingAssembler.fromList(response), [response]);

  return {
    bookings: response && bookingResponse,
    bookingsLoading: loading,
    bookingsError: error,
  };
};

// ************************************************************************
// GET BOOKING BY ID
// ************************************************************************
export const useGetBookingById = (id: string) => {
  const endpoint = EndpointActions.GetBookingById([id]);
  const { response, loading, error } = useFetch<IBookingResponse>(endpoint, [id]);
  const bookingResponse = useMemo(() => response && BookingAssembler.from(response), [response]);

  return {
    booking: bookingResponse,
    bookingLoading: loading,
    bookingError: error,
  };
};

// ************************************************************************
// GET REPORT BOOKINGS
// ************************************************************************
export const useGetReportBookings = (date: Date[], page?: number) => {
  const endpoint = EndpointActions.GetReportBookings([date, page]);
  const { response, loading, error } = useFetch<IBookingReportResponse[]>(endpoint, [date, page]);
  const bookingReportResponse = useMemo(() => response && BookingReportAssembler.fromList(response), [response]);

  return {
    bookings: response && bookingReportResponse,
    bookingsLoading: loading,
    bookingsError: error,
  };
};

// ************************************************************************
// GET REPORT USERS BY LICENSE PLATE
// ************************************************************************
export const useGetReportUsersByLicensePlate = (licensePlate: string) => {
  const endpoint = EndpointActions.GetReportUsersByLicensePlate([licensePlate]);
  const { run, response, loading, error } = useFetch<IUserReportResponse[]>(endpoint, [licensePlate], { defer: true });
  const userReportResponse = useMemo(() => response && UserReportAssembler.fromList(response), [response]);

  return {
    getUsers: run,
    users: response && userReportResponse,
    usersLoading: loading,
    usersError: error,
  };
};

// ************************************************************************
// DELETE BOOKING
// ************************************************************************
export const useDeleteBooking = (bookingId: string, confirm?: boolean) => {
  const endpoint = EndpointActions.DeleteBooking([bookingId, confirm]);
  const { error, loading, run, message, status, response } = useFetch<IBookingResponse>(
    endpoint,
    [bookingId, confirm],
    {
      defer: true,
    }
  );

  return {
    deleteBooking: run,
    deleteBookingError: error,
    deleteBookingLoading: loading,
    deleteBookingMessage: message,
    deleteBookingStatus: status,
    deleteBookingResponse: response,
  };
};

// ************************************************************************
// SELECT PLACE
// ************************************************************************

export const useSelectedPlace = () => {
  return useSelector(getSelectedPlace);
};
