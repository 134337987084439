import React from 'react';
import classNames from 'classnames';
import IconButton from '@mui/material/IconButton';
import useStyles from './Modal.style';
import LoadingSpinner from '../Loading/LoadingSpinner';
import RoundButton from '../RoundButton/RoundButton';
import { ReactComponent as IconClose } from '../../../assets/svg/icon_close.svg';
import { LoadingSpinnerEnum } from '../../../enums/loadingSpinner.enum';
import { useTranslation } from 'react-i18next';
import i18n from '../../../translations/i18n';

const Modal: React.FC<Props> = ({
  cancelLabel,
  confirmLabel,
  children,
  confirmLoading,
  onClose,
  onConfirm,
  subtitle,
  title,
  mobileFullscreen = true,
  buttons,
  maxWidth,
  classNameOverride,
}) => {
  const classes = useStyles();
  void i18n.loadNamespaces('buttons');
  const { t } = useTranslation('buttons');

  const parsedCancelLabel: string = cancelLabel || t('cancelButtonLabel');
  const parsedConfirmLabel: string = confirmLabel || t('confirmButtonLabel');

  return (
    <div className={classes.modal}>
      <div
        className={classNames(
          classes.modalContainer,
          mobileFullscreen && classes.modalContainerFullscreen,
          maxWidth && classes.maxWidth,
          classNameOverride
        )}
      >
        <div className={classes.modalHeader}>
          <div className={classes.modalHeaderText}>
            <div className={classes.modalTitle}>{title}</div>
            {subtitle && <div className={classes.modalSubTitle}>{subtitle}</div>}
          </div>
          <div className={classes.modalHeaderCloseButton}>{renderCloseButton()}</div>
        </div>
        <div className={classNames(classes.modalBody)}>{children}</div>
        {buttons && <div className={classes.modalFooter}>{renderButtons()}</div>}
      </div>
    </div>
  );

  function renderButtons() {
    return (
      <div className={classes.buttonsPosition}>
        <RoundButton capitalizeText buttonColor={'grey'} className={classes.buttons} onClick={onClose}>
          {parsedCancelLabel}
        </RoundButton>
        <RoundButton capitalizeText className={classes.buttons} onClick={onConfirm} disabled={confirmLoading}>
          {confirmLoading ? (
            <div className={classes.loadingSpinnerContainer}>
              <LoadingSpinner color={LoadingSpinnerEnum.WHITE_COLOR} />
            </div>
          ) : (
            parsedConfirmLabel
          )}
        </RoundButton>
      </div>
    );
  }

  function renderCloseButton() {
    return (
      <IconButton className={classes.modalCloseButton} onClick={onClose} size='large'>
        <IconClose />
      </IconButton>
    );
  }
};

interface Props {
  buttons?: boolean;
  cancelLabel?: string;
  children: React.ReactNode;
  classNameOverride?: string;
  confirmLabel?: string;
  confirmLoading?: boolean;
  maxWidth?: boolean;
  onClose?: () => void;
  onConfirm?: () => void;
  subtitle?: string;
  title: string;
  mobileFullscreen?: boolean;
}

export default Modal;
