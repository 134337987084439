import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import sharedStyles from '../../../styles/sharedStyles/sharedStyles';
import { theme } from '../../../styles/theme';

const shared = sharedStyles();

const spinnerSize = 150;
export default makeStyles(() =>
  createStyles({
    ...shared,
    container: {
      ...shared.flexColumnAlignCenterJustifyCenter,
      height: theme.heightFull,
      width: theme.widthFull,
      marginBlock: 'auto',
    },

    spinnerWrapper: {
      height: spinnerSize,
      width: spinnerSize,
    },

    loadingSpinnerText: {
      fontWeight: 'bold',
      color: theme.colors.blue.dark,
      fontSize: 18,
    },
  })
);
