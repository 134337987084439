import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { filter, isEmpty, map, orderBy } from 'lodash';
import { connect, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import Container from '@mui/material/Container';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { RoutePaths } from '../../App/Router/RouterConstants';
import RadioButton from '../../Shared/BlueRadio/BlueRadio';
import RoundButton from '../../Shared/RoundButton/RoundButton';
import EngineTypeButtons from '../../Shared/EngineTypeButtons/EngineTypeButtons';
import { LocationTypeEnum } from '../../../enums/locationType.enum';
import { EngineTypeEnum } from '../../../enums/engineType.enum';
import useIsLicensePlateValid from '../../../hooks/customHooks/useIsLicensePlateValid';
import { useGetBuildings } from '../../../hooks/locationHooks/locationHooks';
import {
  useCreateUserDefaultSettings,
  useSetUserDefaults,
  useGetUserDefaults,
} from '../../../hooks/userHooks/userHooks';
import { Location } from '../../../models/location/location';

import classNames from 'classnames';
import useStyles from './DefaultSettings.style';
import { setModalState } from '../../../redux/actions/appActions/appActions';
import i18n from '../../../translations/i18n';

const DefaultSettings: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  void i18n.loadNamespaces(['settings', 'buttons']);
  const { t } = useTranslation(['settings', 'buttons']);

  const initialLocation: Location = {
    id: '',
    name: '',
    description: '',
    type: LocationTypeEnum.BUILDING,
    rank: 1000,
  };

  const { location, licensePlate: userLicensePlate, username, engineType: defaultEngineType } = useGetUserDefaults();

  const [selectedBuilding, setSelectedBuilding] = useState<Location>(location || initialLocation);
  const [selectedBuildingOption, setSelectedBuildingOption] = useState<string>('');
  const [licensePlate, setLicensePlate] = useState<string>(userLicensePlate || '');
  const [selectedEngineType, setSelectedEngineType] = useState<EngineTypeEnum | undefined>(undefined);
  const setUserDefaults = useSetUserDefaults();
  const { createUserDefaultSettings, user, userLoading, userError } = useCreateUserDefaultSettings(
    selectedBuilding,
    licensePlate,
    selectedEngineType
  );

  const { buildings } = useGetBuildings();
  const { enqueueSnackbar } = useSnackbar();

  const isLicensePlateValid = useIsLicensePlateValid(licensePlate);

  const dispatch = useDispatch();

  const openConsentModal = useCallback(() => {
    dispatch(setModalState(true));
  }, [dispatch]);

  useEffect(() => {
    if (location) {
      setSelectedBuilding(location);
      setSelectedBuildingOption(location.id);
    }
    if (!!userLicensePlate) {
      setLicensePlate(userLicensePlate);
    }
    if (defaultEngineType) {
      setSelectedEngineType(defaultEngineType);
    }
  }, [location, userLicensePlate, defaultEngineType]);

  useEffect(() => {
    if (user && !userLoading && selectedBuilding) {
      setUserDefaults(selectedBuilding, licensePlate || '', selectedEngineType);
      navigate(RoutePaths.dashboard);
    }
  }, [licensePlate, navigate, selectedBuilding, selectedEngineType, setUserDefaults, user, userLoading]);

  useEffect(() => {
    if (userError) {
      enqueueSnackbar(userError, {
        variant: 'error',
        persist: true,
      });
    }
  }, [userError, enqueueSnackbar]);

  const onChangeBuilding = (event: React.ChangeEvent<{ value: Location['id'] }>) => {
    const newLocation = filter(buildings, (location) => location.id === event.target.value)[0];

    setSelectedBuilding(newLocation);
    setSelectedBuildingOption(event.target.value);
  };

  const onChangeLicensePlate = (event: React.ChangeEvent<{ value: string }>) => {
    setLicensePlate(event.target.value.toUpperCase());

    if (event.target.value === '') {
      setSelectedEngineType(undefined);
    }
  };

  const isSubmitDisabled = () => {
    return !isLicensePlateValid || (!!licensePlate && !selectedEngineType) || selectedBuildingOption === '';
  };

  const onSubmit = () => {
    selectedBuilding && createUserDefaultSettings();
  };

  return (
    <Container component='main' maxWidth='xs' className={classes.container}>
      <div className={classes.marginBottom5}>
        {username && (
          <Typography variant='subtitle1'>
            <Trans
              components={{ b: <b className={classes.colorBlueMain} /> }}
              values={{ username }}
              t={t}
              i18nKey={'defaultSettings.greeting'}
            />
          </Typography>
        )}
        <Typography variant='subtitle1'>{t('defaultSettings.pleaseConfigureText')}</Typography>
      </div>
      <Grid container direction={'column'}>
        {!isEmpty(buildings) && renderBuildings()}
        {renderLicensePlate()}
        {renderShowConsentButton()}
        {renderConfirmButton()}
      </Grid>
    </Container>
  );

  function renderBuildings() {
    const orderedBuildings: Location[] = orderBy(buildings, ['rank']);

    return (
      <div className={classes.marginBottom4}>
        <FormControl>
          <FormLabel className={classes.marginBottom2}>
            <Typography variant='subtitle2' className={classes.inputLabel}>
              {t('defaultSettings.location')}
            </Typography>
          </FormLabel>
          <RadioGroup
            className={classes.radioGroupContainer}
            aria-label={t('defaultSettings.location')}
            name={t('defaultSettings.location')}
            onChange={onChangeBuilding}
            value={selectedBuildingOption}
          >
            {map(orderedBuildings, (building, index) => (
              <FormControlLabel
                control={<RadioButton />}
                key={index}
                value={building.id}
                label={building.name}
                componentsProps={{
                  typography: {
                    variant: 'subtitle1',
                  },
                }}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </div>
    );
  }

  function renderLicensePlate() {
    return (
      <div className={classes.marginBottom4}>
        <InputLabel className={classes.marginBottom2}>
          <Typography variant='subtitle2' className={classes.inputLabel}>
            {t('licensePlate.licensePlateLabel')}
          </Typography>
        </InputLabel>
        <div className={classes.inputGroup}>
          <TextField
            variant={'outlined'}
            className={classes.inputField}
            onChange={onChangeLicensePlate}
            placeholder={t('licensePlate.licensePlatePlaceholder')}
            value={licensePlate}
            InputProps={{ inputProps: { maxLength: 6 } }}
            error={!isLicensePlateValid}
          />
          <EngineTypeButtons
            engineType={selectedEngineType}
            setEngineType={setSelectedEngineType}
            disabled={!isLicensePlateValid || licensePlate === ''}
          />
        </div>
        {!isLicensePlateValid && (
          <FormHelperText error className={classes.overlappedText}>
            {t('licensePlate.licensePlateHelperText')}
          </FormHelperText>
        )}
      </div>
    );
  }

  function renderShowConsentButton() {
    return (
      <div className={classes.marginBottom10}>
        <FormLabel>
          <Typography variant='subtitle2' className={classNames(classes.marginBottom1, classes.inputLabel)}>
            {t('defaultSettings.personalDataAuthorizationLabel')}
          </Typography>
        </FormLabel>
        <Typography className={classes.consentText} variant='body2'>
          {t('defaultSettings.personalDataAuthorizationText')}
        </Typography>
        <button onClick={openConsentModal} className={classes.showConsentButton} data-testid='showConsentButton'>
          <Typography variant='body2' className={classes.consentButtonLabel}>
            {t('defaultSettings.personalDataAuthorizationButton')}
          </Typography>
        </button>
      </div>
    );
  }

  function renderConfirmButton() {
    return (
      <RoundButton
        className={classes.confirmButton}
        align={'center'}
        capitalizeText
        disabled={isSubmitDisabled()}
        onClick={onSubmit}
        size={'large'}
        type='submit'
        data-testid='submit'
      >
        {t('buttons:confirmButtonLabel')}
      </RoundButton>
    );
  }
};

export default connect()(DefaultSettings);
