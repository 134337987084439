import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import sharedStyles from '../../../../styles/sharedStyles/sharedStyles';
import { muiTheme, theme } from '../../../../styles/theme';
import { pxToRemOrEm } from '../../../../styles/utilities';

const shared = sharedStyles();

const xTransParkingIcon = 5;
const yTransParkingIcon = 3;
const parkingIconSize = 20;
const parkingButtonSize = 24 + 4 + 4; // icon size + padding inside svg + button padding
const warningIconSize = 18;

export default makeStyles(() =>
  createStyles({
    ...shared,

    optionContainer: {
      ...shared.flexColumn,
      width: '100%',
    },

    radioGroupContainer: {
      ...shared.flexColumn,
      ...shared.gap8,
      '& .MuiRadio-root': {
        color: theme.colors.blue.royal,
        paddingLeft: 0,
      },
      '& .MuiRadio-root:hover': {
        backgroundColor: 'transparent',
      },

      '& .MuiFormControlLabel-root': {
        width: 250 + 16, //label width + gap before parking button
        margin: 0,
        [muiTheme.breakpoints.only('xs')]: {
          width: 'unset',
        },
      },

      '& .MuiIconButton-root': {
        paddingLeft: 0,
      },
    },

    textContent: {
      ...shared.flex,
      color: theme.colors.grey.medium,
      textAlign: 'start',
    },

    warning: {
      color: theme.colors.orange.main,
      marginLeft: 4,

      '& svg': {
        width: warningIconSize,
        height: warningIconSize,
        marginRight: 4,
      },
    },

    licensePlateTextField: {
      width: '100%',
      '& .MuiOutlinedInput-root': {
        borderRadius: 6,
      },
      '& .MuiOutlinedInput-input': {
        color: theme.colors.grey.main,
        padding: 12,
        minWidth: 200,
        [muiTheme.breakpoints.only('xs')]: {
          minWidth: 'unset',
        },
        '&.Mui-disabled': {
          color: theme.colors.grey.light,
        },
      },
      '& .MuiFormHelperText-contained': {
        margin: 0,
        position: 'absolute',
        bottom: -24,
        [muiTheme.breakpoints.only('xs')]: {
          bottom: -42,
        },
      },
      '& .Mui-disabled .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.colors.grey.light,
      },
    },

    inputLabel: {
      fontSize: pxToRemOrEm(16),
      fontWeight: 'bold',
      color: theme.colors.grey.main,
      marginBottom: 8,
    },

    toggle: {
      marginLeft: '1rem',
    },

    defaultTextField: {
      '& .MuiFormControlLabel-label.Mui-disabled': {
        color: theme.colors.grey.light,
      },
    },

    defaultTextFieldDisabled: {
      color: theme.colors.grey.light,
    },

    engineTypeButton: {
      width: parkingButtonSize,
      height: parkingButtonSize,
      borderRadius: 6,
      minWidth: 0,
      padding: 3,

      '&.MuiButton-outlined.Mui-disabled': {
        borderColor: theme.colors.grey.light,

        '& svg path': {
          fill: theme.colors.grey.light,
        },
      },
    },

    engineTypeButtonActive: {
      backgroundColor: theme.colors.blue.royal,
      borderColor: theme.colors.blue.royal,

      '&:hover': {
        backgroundColor: theme.colors.blue.royal,
      },

      '& svg path': {
        fill: theme.colors.white.main,
      },
    },

    marginBottom20: {
      marginBottom: 20,
    },

    parkingIcon: {
      display: 'inline-block',
      height: parkingIconSize,
      marginLeft: 5,
      transform: `translate(${xTransParkingIcon}px, ${yTransParkingIcon}px)`,
      width: parkingIconSize,
    },
    helperTextMargin: {
      marginBottom: 24,
    },
  })
);
