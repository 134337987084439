import { SET_USERNAME, SET_EMAIL } from '../../constants/appConstants';

export type UserInformationAction = SetEmail | SetUsername;

// ************************************************************************
// SET EMAIL
// ************************************************************************
interface SetEmail {
  type: SET_EMAIL;
  email: string;
}
export const setEmail = (email: string): SetEmail => {
  return {
    type: SET_EMAIL,
    email,
  };
};

// ************************************************************************
// SET USERNAME
// ************************************************************************
interface SetUsername {
  type: SET_USERNAME;
  username: string;
}
export const setUsername = (username: string): SetUsername => {
  return {
    type: SET_USERNAME,
    username,
  };
};
