import { ILoggedUserResponse } from '../../interfaces/user/logged-user-response';
import { IUserInformation } from '../../interfaces/user/user-information';
import { User } from '../../models/user/user';

export class UserAssembler {
  static fromLoggedUser(loggedUserResponse: ILoggedUserResponse): User {
    return {
      id: loggedUserResponse.id,
      email: loggedUserResponse.email,
      username: loggedUserResponse.username,
      licensePlate: loggedUserResponse.licensePlate,
      defaultLocation: loggedUserResponse.defaultLocation,
      defaultPlace: loggedUserResponse.defaultPlace,
      engineType: loggedUserResponse.engineType,
      consent: loggedUserResponse.consent,
    };
  }

  static fromUserInformation(userInformationResponse: IUserInformation): User {
    return {
      id: userInformationResponse.id,
      email: userInformationResponse.email,
      username: userInformationResponse.username,
      licensePlate: userInformationResponse.licensePlate,
      defaultLocationId: userInformationResponse.defaultLocationId,
      defaultPlaceId: userInformationResponse.defaultPlaceId,
      engineType: userInformationResponse.engineType,
    };
  }
}
