const settings = {
  API_ENDPOINT: window.env.API_ENDPOINT,
  MSAL_AUTHORITY: window.env.MSAL_AUTHORITY,
  MSAL_CLIENT_ID: window.env.MSAL_CLIENT_ID,
  REDIRECT_URI: window.env.REDIRECT_URI,
  STAGE: window.env.STAGE,
  TRANSLATIONS_ENDPOINT: window.env.TRANSLATIONS_ENDPOINT,
  FEATURE_TOGGLE_GUESTS: window.env.FEATURE_TOGGLE_GUESTS,
};

export default settings;
