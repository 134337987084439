import { Guest } from '../../models/guest/guest';

export class GuestsUtil {
  static guestMinLength = 5;
  static guestMaxLength = 64;

  static haveNameOfAllGuests = (guests: Guest[]): boolean => {
    return guests.every((g) => !!g.name);
  };

  static areGuestNamesValid = (guests: Guest[]): boolean => {
    return guests.every(
      (g) => g.name.length >= GuestsUtil.guestMinLength && g.name.length <= GuestsUtil.guestMaxLength
    );
  };

  static getGuestList = (guests: Guest[]): string[] | undefined => {
    const guestList = guests.reduce<string[]>((current, result) => {
      return !!result.name.trim() ? [...current, result.name.trim()] : [...current];
    }, []);

    return guestList.length > 0 ? guestList : undefined;
  };
}
