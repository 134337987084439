import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import sharedStyles from '../../../styles/sharedStyles/sharedStyles';
import { muiTheme, theme } from '../../../styles/theme';
import { pxToRemOrEm } from '../../../styles/utilities';

const shared = sharedStyles();

export default makeStyles(() =>
  createStyles({
    ...shared,

    locationTag: {
      ...shared.flexAlignCenter,
      backgroundColor: theme.colors.blue.lightest,
      height: 50,
      borderRadius: '50px',
      padding: '0 20px',
      '& > h6': {
        letterSpacing: 0.3,
        color: theme.colors.blue.royal,
      },
    },

    locationIcon: {
      color: theme.colors.blue.royal,
      marginRight: muiTheme.spacing(2),
      fontSize: pxToRemOrEm(14),
    },
  })
);
