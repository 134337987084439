import React, { Suspense } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { RouteEntry, RoutePaths } from './RouterConstants';
import ConsentModal from '../../pages/ConsentModal/ConsentModal';
import Loading from '../../Shared/Loading/Loading';
import { useGetUserRoles } from '../../../hooks/authHooks/authHooks';
import { getIsLocationSelected, getModalState } from '../../../redux/selectors/appSelectors';
import '../../../translations/i18n';
import { RolesUtil } from '../../../utils/roles/roles-util';
import { RolesEnum } from '../../../enums/roles.enum';

export enum PermissionsEnum {
  HAS_DEFAULT_SETTINGS = 'HAS_DEFAULT_SETTINGS',
  HAS_REPORT_ROLE = 'HAS_REPORT_ROLE',
}

export interface Permission {
  permission: PermissionsEnum;
  ifNotRedirectTo?: RoutePaths;
}

const GuardedRoute: React.FC<RouteEntry> = ({ title, permissions, children }) => {
  const hasAccess: boolean | Permission = CanAccess(permissions);
  const permissionRejected = hasAccess as Permission;
  const showModal = useSelector(getModalState);
  document.body.style.overflow = showModal ? 'hidden' : 'initial';

  const originRoute = (
    <>
      <Helmet>
        <title>Wally App{title && ` | ${title}`}</title>
      </Helmet>
      <Suspense fallback={<Loading />}>{children}</Suspense>
      <Suspense fallback={<Loading />}>{showModal && <ConsentModal />}</Suspense>
    </>
  );

  return hasAccess === true ? originRoute : <Navigate replace to={permissionRejected?.ifNotRedirectTo || ''} />;
};

const CanAccess = (permissions: Permission[] | undefined): boolean | Permission => {
  const isLocationSelected = useSelector(getIsLocationSelected);
  const roles = useGetUserRoles();

  for (const p of permissions || []) {
    if (p.permission === PermissionsEnum.HAS_DEFAULT_SETTINGS && !isLocationSelected) {
      return p;
    }
    if (p.permission === PermissionsEnum.HAS_REPORT_ROLE && !RolesUtil.hasRole(RolesEnum.REPORT, roles)) {
      return p;
    }
    // For more permissions, add here the condition
  }

  return true;
};

export default GuardedRoute;
