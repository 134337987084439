import React, { useEffect, useState } from 'react';
import { TooltipCursorEnum, TooltipEnum } from '../../../enums/tooltip.enum';

import classNames from 'classnames';
import useStyles from './Tooltip.style';

const Tooltip: React.FC<Props> = ({ delay, children, direction, content, disabled = false, className, cursor }) => {
  const classes = useStyles();
  let timeout: NodeJS.Timeout;
  const [active, setActive] = useState(false);
  const [mouseInArea, setMouseInArea] = useState(false);

  useEffect(() => {
    if (mouseInArea) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      timeout = setTimeout(() => {
        setActive(true);
      }, delay || 300);
    } else {
      clearInterval(timeout);
      setActive(false);
    }
    return () => {
      clearInterval(timeout);
    };
  }, [delay, mouseInArea]);

  return (
    <div
      className={classNames(`${classes.TooltipWrapper} ${cursor ?? TooltipCursorEnum.POINTER}`, {
        [classes.disabled]: disabled,
      })}
      data-testid='tooltip-id-children'
      // When to show the tooltip
      onMouseEnter={() => setMouseInArea(true)}
      onMouseLeave={() => setMouseInArea(false)}
    >
      {/* Wrapping */}
      {children}
      {!disabled && active && content && (
        <div
          className={classNames(
            classes.Tooltip,
            direction ?? TooltipEnum.TOP,
            content?.length < 10 && classes.tooltipWidthSmallText,
            content?.length > 10 && content?.length < 30 && classes.tooltipWidthMediumText,
            content?.length >= 30 && classes.tooltipWidthLongText,
            className
          )}
          data-testid='tooltip-id-content'
        >
          {/* Content */}
          {content}
        </div>
      )}
    </div>
  );
};

export type Props = {
  delay?: number;
  children?: React.ReactNode;
  direction?: TooltipEnum.TOP | TooltipEnum.LEFT | TooltipEnum.BOTTOM | TooltipEnum.RIGHT;
  content?: string;
  disabled?: boolean;
  className?: string;
  cursor?: TooltipCursorEnum.NOT_ALLOWED | TooltipCursorEnum.POINTER;
};

export default Tooltip;
