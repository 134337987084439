/**
 * License Plate Engine Type Enum.
 */
export enum LicensePlateEngineTypeEnum {
  SHOW_AS_RADIO_GROUP = 'RADIO_GROUP',
  SHOW_AS_TEXT_FIELD = 'TEXT_FIELD',
}

/**
 * License Plate Value Options Enum.
 */
export enum LicensePlateOptionsEnum {
  RADIO_VALUE_DEFAULT = 'DEFAULT',
  RADIO_VALUE_NEW = 'NEW',
}
