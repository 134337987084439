import makeStyles from '@mui/styles/makeStyles';
import { theme } from '../../../../styles/theme';
import sharedStyles from '../../../../styles/sharedStyles/sharedStyles';

const shared = sharedStyles();

export default makeStyles({
  ...shared,

  root: {
    backgroundColor: theme.colors.blue.lightest,
    maxHeight: 150,
    overflowY: 'auto',
    padding: 16,
    position: 'relative',

    '&::before': {
      backgroundColor: theme.colors.white.main,
      content: '""',
      height: 1,
      left: 16,
      position: 'absolute',
      right: 16,
      top: 0,
    },
    '&::-webkit-scrollbar': {
      width: 27,
      height: 18,
    },
    '&::-webkit-scrollbar-thumb': {
      minHeight: 48,
      border: '10px solid rgba(0, 0, 0, 0)',
      backgroundClip: 'padding-box',
      '-webkit-border-radius': '14px',
      backgroundColor: 'rgba(178,180,182)',
    },
  },

  title: {
    ...shared.fontSize18,
    ...shared.fontWeightBold,
    color: theme.colors.blue.main,
    margin: '0 0 8px',
  },

  usernameList: {
    listStyle: 'none',
    margin: 0,
    padding: 0,
  },

  usernameListItem: {
    ...shared.fontSize16,
    color: theme.colors.grey.main,
    paddingTop: 8,
    '&:not(:last-child)': {
      marginBottom: 8,
    },
  },

  teamSpaceName: {
    color: theme.colors.blue.main,
    paddingLeft: 2,
  },
});
