import { IPlaceAndParkingStatusResponse } from '../../interfaces/placeAndParkingStatus/place-and-parking-status-response';
import { PlaceAndParkingStatus } from '../../models/placeAndParkingStatus/placeAndParkingStatus';
import { ParkingLotsUtil } from '../../utils/parkingLots/parking-lots-util';

export class PlaceAndParkingStatusAssembler {
  static from(placeAndParkingStatus: IPlaceAndParkingStatusResponse): PlaceAndParkingStatus {
    return {
      date: placeAndParkingStatus.date,
      building: placeAndParkingStatus.building,
      location: placeAndParkingStatus.location,
      place: placeAndParkingStatus.place,
      parkingLots: ParkingLotsUtil.reduceParkingLots(placeAndParkingStatus.parkingLots),
    };
  }

  static fromList(placeAndParkingStatusList: IPlaceAndParkingStatusResponse[]): PlaceAndParkingStatus[] {
    return placeAndParkingStatusList?.map((placeAndParkingStatus) => this.from(placeAndParkingStatus));
  }
}
