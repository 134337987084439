import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import sharedStyles from '../../../../../styles/sharedStyles/sharedStyles';
import { textContent } from '../../../../../styles/reservation';
import { pxToRemOrEm } from '../../../../../styles/utilities';
import { muiTheme, theme } from '../../../../../styles/theme';

const shared = sharedStyles();

const iconsContainer = {
  ...shared.flex,
  backgroundColor: 'transparent',
  border: 0,
  cursor: 'pointer',
  height: 24,
  right: 16,
  justifyContent: 'center',
  alignItems: 'center',
  padding: 0,
};

export default makeStyles(() =>
  createStyles({
    ...shared,
    ...textContent,

    peoplePickerWrapper: {
      display: 'grid',
      gridAutoRows: 'min-content',
      gap: '1rem',
      marginTop: muiTheme.spacing(2),
      alignItems: 'center',
      gridTemplateColumns: 'auto 1fr auto',
    },

    chooseTeamLabel: {
      color: theme.colors.grey.main,
      fontSize: pxToRemOrEm(16),
    },

    textContent: {
      color: theme.colors.grey.medium,
    },

    parkingToggleLabel: {
      color: theme.colors.grey.main,
      margin: muiTheme.spacing(2, 2, 0, 0),
    },

    parkingToggleWrapper: {
      marginTop: muiTheme.spacing(2),
    },

    peoplePickerHeading: {
      ...shared.flexRowAlignCenterJustifySpaceBetween,
      fontSize: pxToRemOrEm(12),
    },

    peoplePickerHeadingPark: {
      ...shared.flexRowAlignCenterJustifySpaceBetween,
      fontSize: pxToRemOrEm(12),
    },

    gridColumnStartOne: {
      gridColumnStart: 1,
    },

    gridColumnStartTwo: {
      gridColumnStart: 2,
    },

    overlappedText: {
      margin: 'auto',
      marginTop: 3,
      ...shared.flex,
      marginLeft: 3,
    },

    overlappedErrorIcon: {
      flexShrink: 0,
      height: 14,
      width: 14,
      marginTop: 2,
      marginRight: 3,
      color: theme.colors.red.main,
    },

    parkingWarningWrapper: {
      ...shared.flex,
    },

    parkingWarningIcon: {
      flexShrink: 0,
      height: 16,
      width: 16,
      marginTop: 2,
    },

    warning: {
      color: theme.colors.orange.main,
      height: 16,
      width: 16,
    },

    parkingWarningCheckboxColumn: {
      ...shared.flexJustifyCenter,
    },

    checkbox: {
      ...shared.flex,
    },

    parkingWarningLabel: {
      ...shared.flex,
      margin: muiTheme.spacing(2, 2, 0, 0),
      gap: 16,
    },

    iconMarginBottom: {
      marginBottom: 26,
    },

    deleteButtonContainer: {
      ...iconsContainer,
      display: 'flex',
      alignItems: 'center',
      top: 16,
      '& svg': {
        width: 16,
        height: 16,
      },
    },

    addMemberButtonContainer: {
      ...iconsContainer,
      justifyContent: 'flex-start',
      color: theme.colors.blue.dark,

      [muiTheme.breakpoints.down('md')]: {
        '&:hover:not(:disabled)': {
          textDecoration: 'none !important',
        },
      },
      '&:hover:not(:disabled)': {
        textDecoration: 'underline',
      },
      '&:disabled': {
        color: theme.colors.grey.dark,
        cursor: 'default',
      },
    },

    licensePlateAndEngineType: {
      gridColumn: 2,
      marginTop: -8,
    },
  })
);
