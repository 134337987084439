import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import sharedStyles from '../../../../styles/sharedStyles/sharedStyles';
import { theme } from '../../../../styles/theme';

const shared = sharedStyles();

export default makeStyles((_theme: Theme) =>
  createStyles({
    ...shared,

    appBar: {
      height: 80,
      backgroundColor: theme.colors.white.main,
      boxShadow: theme.shadows.medium,
      transition: theme.transition,
      '& *': {
        transition: theme.transition,
      },
    },

    scrollAppBar: {
      height: 40,
    },

    toolbar: {
      display: 'grid',
      gridTemplateColumns: ' 1fr repeat(3, auto) 1fr',
      gridColumnGap: 5,
      minHeight: '40px !important',
    },

    menuButton: {
      width: 70,
      color: theme.colors.blue.main,
      '&:hover': {
        backgroundColor: 'transparent',
        color: theme.colors.blue.royal,
      },
    },

    scrollMenuButton: {
      height: 35,
    },

    logoWrapper: {
      ...shared.flexColumnAlignCenter,
      gridColumnStart: '2',
    },

    wallyLogo: {
      height: 48,
      color: theme.colors.blue.main,
    },

    scrollWallyLogo: {
      height: 24,
    },

    wallyTitle: {
      height: 12,
      color: theme.colors.blue.main,
    },

    scrollWallyTitle: {
      height: 6,
    },
  })
);
