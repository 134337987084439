import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import sharedStyles from '../../../styles/sharedStyles/sharedStyles';
import { theme } from '../../../styles/theme';

const shared = sharedStyles();

const defaultButtonPadding = 8;
const defaultIconSize = 24;

export default makeStyles<Theme, Props>((_: Theme) =>
  createStyles({
    ...shared,

    engineTypeButton: {
      borderRadius: 6,
      minWidth: 0,
      padding: ({ buttonPadding }: Props) => buttonPadding || defaultButtonPadding,
      borderColor: theme.colors.grey.border,
      '& svg path': {
        fill: theme.colors.grey.main,
      },

      '&:hover': {
        '& svg path': {
          fill: theme.colors.blue.royal,
        },
      },

      '&.MuiButton-outlined.Mui-disabled': {
        borderColor: theme.colors.grey.light,

        '& svg path': {
          fill: theme.colors.grey.light,
        },
      },
    },

    engineTypeButtonActive: {
      backgroundColor: theme.colors.blue.lightest,
      borderColor: theme.colors.blue.royal,

      '& svg path': {
        fill: theme.colors.blue.royal,
      },

      '&:hover': {
        backgroundColor: theme.colors.blue.light,
        '& svg path': {
          fill: theme.colors.blue.royal,
        },
      },
    },

    engineIcon: {
      width: ({ iconSize }: Props) => iconSize || defaultIconSize,
      heigth: ({ iconSize }: Props) => iconSize || defaultIconSize,
    },
  })
);

interface Props {
  buttonPadding?: number;
  iconSize?: number;
}
