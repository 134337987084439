import React, { ChangeEvent } from 'react';
import './Checkbox.scss';

const SvgCheck = () => {
  return (
    <svg className='checkbox__symbol'>
      <symbol id='check' viewBox='0 0 12 10'>
        <polyline points='1.5 6 4.5 9 10.5 1' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' />
      </symbol>
    </svg>
  );
};

const Checkbox: React.FC<Props> = ({ label, name, ...rest }) => {
  return (
    <div className='checkbox__container'>
      <input className='checkbox__input' id={name} type='checkbox' {...rest} />
      <label className='checkbox' htmlFor={name}>
        <span>
          <SvgCheck />
          <svg width='12px' height='10px'>
            <use href='#check' />
          </svg>
        </span>
        {label && <span>{label}</span>}
      </label>
    </div>
  );
};

interface Props {
  disabled?: boolean;
  label?: string;
  name: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  checked?: boolean;
}

export default Checkbox;
