import { TeamSpaceMember } from '../../models/teamSpaceMember/team-space-member';

export class TeamSpaceUtil {
  /**
   * This method joins three different objects and bind them into one, with all the properties of {@link TeamSpaceMember}
   *
   * @param teamSpaceMembers The object with index and {@link MgtPerson} (people picker)
   * @param teamSpaceMembersParking The object with index and needParkingSpace info (checkboxes)
   * @param userInformation The object with email and userInformation (backend)
   * @returns an array with full teamSpaceMember info
   */
  static compileTeamSpaceMembersInfo(
    teamSpaceMembers: Pick<TeamSpaceMember, 'index' | 'person'>[],
    teamSpaceMembersParking: Pick<TeamSpaceMember, 'index' | 'needParkingSpace'>[],
    userInformation: Pick<TeamSpaceMember, 'email' | 'hasParkingInformation' | 'hasOverlappedBookings'>[]
  ): TeamSpaceMember[] {
    return teamSpaceMembers?.reduce<TeamSpaceMember[]>((result, member) => {
      const email = this.getTeamMemberEmail(member);
      const needParkingSpace = this.getTeamSpaceMemberNeedParkingSpaceByIndex(
        teamSpaceMembersParking,
        member.index ?? -1
      );
      const hasParkingInformation = this.getHasParkingInformationByEmail(userInformation, email);
      const hasOverlappedBookings = this.getUserInformationByEmail(userInformation, email)?.hasOverlappedBookings;
      return [
        ...result,
        { index: member.index, email, needParkingSpace, hasParkingInformation, hasOverlappedBookings },
      ];
    }, []);
  }

  /**
   * Method to get need parking space by index. It defaults to undefined if no information is retrieved.
   *
   * @param teamSpaceMembers The object with index and needParkingSpace info
   * @param index the desired index
   * @returns a boolean or undefined
   */
  static getTeamSpaceMemberNeedParkingSpaceByIndex(
    teamSpaceMembers: Pick<TeamSpaceMember, 'index' | 'needParkingSpace'>[],
    index: number
  ) {
    return (
      teamSpaceMembers.find((memberNeedParking) => memberNeedParking.index === index)?.needParkingSpace ?? undefined
    );
  }

  /**
   * Method to get need parking space by email
   *
   * @param teamSpaceMembers An array of {@link TeamSpaceMember} with email and needParkingSpace info
   * @param email the desired member's email
   * @returns a boolean or undefined
   */
  static getTeamSpaceMemberNeedParkingSpaceByEmail(
    teamSpaceMembers: Pick<TeamSpaceMember, 'email' | 'needParkingSpace'>[],
    email: string
  ): boolean {
    return teamSpaceMembers?.find((memberNeedParking) => memberNeedParking.email === email)?.needParkingSpace ?? false;
  }

  /**
   * Method to know what members can book a parking place and have no information of wanting it.
   * To deal with the disabled checkboxes, we also need to know if a user has overlapped bookings
   *
   * @param teamSpaceMembers Pick<{@link TeamSpaceMember}, 'hasParkingInformation' | 'index' | 'needParkingSpace' | 'hasOverlappedBookings'>
   * @returns an array with { index: number, needParkingSpace: boolean } of those eligible
   */
  static getTeamSpaceMembersParkingEligibility(
    teamSpaceMembers: Pick<
      TeamSpaceMember,
      'hasParkingInformation' | 'index' | 'needParkingSpace' | 'hasOverlappedBookings'
    >[]
  ) {
    return teamSpaceMembers?.reduce<Pick<TeamSpaceMember, 'index' | 'needParkingSpace'>[]>((result, member) => {
      if (member.hasParkingInformation && member.needParkingSpace === undefined && !member.hasOverlappedBookings) {
        result = [...result, { index: member.index, needParkingSpace: true }];
      }
      return result;
    }, []);
  }

  /**
   * Method to get a Team Member's email
   *
   * @param member a {@link TeamSpaceMember} with a userPrincipalName
   * @returns the email or an empty string
   */
  static getTeamMemberEmail(member: Pick<TeamSpaceMember, 'person'> | undefined) {
    return member?.person?.[0]?.userPrincipalName ?? '';
  }

  /**
   * Method to get a team space member by its index property (and not an array index)
   *
   * @param teamSpaceMembers the array
   * @param index the desired index
   * @returns the member
   */
  static getTeamSpaceMemberByIndex(teamSpaceMembers: TeamSpaceMember[], index: number) {
    return teamSpaceMembers.length > 0 ? teamSpaceMembers.find((teamMember) => teamMember.index === index) : undefined;
  }

  /**
   * Method to get the User Information of a member by its email
   * It returns a {@link TeamSpaceMember} with hasParkingInformation and hasOverlappedBookings information
   * Can be combined with getTeamMemberEmail()
   *
   * @param userInformation the array with the information
   * @param memberEmail the desired member email
   * @returns booleans of hasParkingInformation and hasOverlappedBookings
   */
  static getUserInformationByEmail(
    userInformation: Pick<TeamSpaceMember, 'email' | 'hasParkingInformation' | 'hasOverlappedBookings'>[],
    memberEmail: string
  ): Pick<TeamSpaceMember, 'hasParkingInformation' | 'hasOverlappedBookings'> | undefined {
    return userInformation?.find((teamMember) => teamMember?.email === memberEmail);
  }

  /**
   * Method to find if a team member has Parking Information by its email
   * Can be combined with getTeamMemberEmail()
   *
   * @param userInformation the array with the information
   * @param memberEmail the desired member email
   * @returns true if hasParkingInformation, false otherwise
   */
  static getHasParkingInformationByEmail(
    userInformation: Pick<TeamSpaceMember, 'email' | 'hasParkingInformation'>[],
    memberEmail: string
  ): boolean | undefined {
    return userInformation?.find((teamMember) => teamMember?.email === memberEmail)?.hasParkingInformation ?? undefined;
  }

  /**
   * Method to find if a team member has already booked
   * Can be combined with getTeamMemberEmail()
   *
   * @param userInformation the array with the information
   * @param memberEmail the desired member email
   * @returns true if hasOverlappedBookings, false otherwise
   */
  static getOverlappedBookingsByEmail(
    userInformation: Pick<TeamSpaceMember, 'email' | 'hasOverlappedBookings'>[],
    memberEmail: string
  ) {
    return userInformation?.find((teamMember) => teamMember?.email === memberEmail)?.hasOverlappedBookings || false;
  }

  /**
   * Method to find if there's members that have needParkingSpace
   *
   * @param users Pick<{@link TeamSpaceMember}, 'person' | 'needParkingSpace'>
   * @return true if at least one person with needParkingSpace true
   */
  static hasNeedParkingSpace = (users: Pick<TeamSpaceMember, 'person' | 'needParkingSpace'>[]) => {
    return users.some((user) => user.needParkingSpace) || false;
  };

  /**
   * This method joins the existing with the new members of {@link TeamSpaceMember}
   * taking into account the users already present in the team space
   *
   * @param teamSpaceMembers The object with index and {@link MgtPerson} (people picker)
   * @param teamSpaceMembersParking The object with index and needParkingSpace info (checkboxes)
   * @param existingTeamSpaceMembers The object with the existing members on a team space
   * @returns an array with teamSpaceMember
   */
  static getTeamSpaceMembers = (
    teamSpaceMembers: Pick<TeamSpaceMember, 'index' | 'person'>[],
    teamSpaceMembersParking: Pick<TeamSpaceMember, 'index' | 'needParkingSpace'>[],
    existingTeamSpaceMembers?: Pick<TeamSpaceMember, 'person' | 'needParkingSpace'>[]
  ): Pick<TeamSpaceMember, 'email' | 'needParkingSpace'>[] => {
    const existing =
      existingTeamSpaceMembers?.reduce<Pick<TeamSpaceMember, 'email' | 'needParkingSpace'>[]>((result, member) => {
        return [
          ...result,
          {
            email: TeamSpaceUtil.getTeamMemberEmail(member),
            needParkingSpace: member.needParkingSpace,
          },
        ];
      }, []) ?? [];
    return teamSpaceMembers.reduce<Pick<TeamSpaceMember, 'index' | 'email' | 'needParkingSpace'>[]>(
      (result, member) => {
        return [
          ...result,
          {
            email: TeamSpaceUtil.getTeamMemberEmail(member),
            needParkingSpace:
              TeamSpaceUtil.getTeamSpaceMemberNeedParkingSpaceByIndex(teamSpaceMembersParking, member.index ?? -1) ??
              false,
          },
        ];
      },
      existing
    );
  };

  /**
   * This method finds if every user has parking information
   *
   * @param userInformation the array with the information
   * @returns true if every user has parking information, false otherwise
   */
  static hasEveryUserParkingInformation = (
    userInformation: Pick<TeamSpaceMember, 'hasParkingInformation'>[] | null
  ) => {
    return userInformation ? userInformation.every((user) => user.hasParkingInformation) : true;
  };
}
