import { IUserInformation } from '../../interfaces/user/user-information';
import { User } from '../../models/user/user';

export class UserRequestAssembler {
  static from(user: User): IUserInformation {
    return {
      username: user.username,
      email: user.email,
      licensePlate: !!user.licensePlate ? user.licensePlate : undefined,
      engineType: !!user.licensePlate ? user.engineType : undefined,
      defaultLocationId: user.defaultLocationId,
    };
  }
}
