/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { useEffect, useRef, useState } from 'react';
import { MomentInput } from 'moment';
import { Providers } from '@microsoft/mgt-element';
import config from '../../config/config';
import { IBookingRequest } from '../../interfaces/booking/booking-request';
import { IUserInformation } from '../../interfaces/user/user-information';
import { Consent } from '../../models/consent/consent';
import Ajax, { RequestProps } from '../../services/Ajax/Ajax';
import Formatters from '../../services/Formatters/Formatters';

type EndpointRequestProps = Pick<RequestProps, 'body' | 'method' | 'path'>;

export const EndpointActions = {
  Consent: (args: [consent: Consent]): EndpointRequestProps => {
    const [consent] = args;

    return {
      method: 'PATCH',
      path: 'users/consent',
      body: consent,
    };
  },
  GetConsent: (): EndpointRequestProps => {
    return {
      method: 'GET',
      path: 'users/consent',
    };
  },
  GetLocationsByType: (args: [type: string]): EndpointRequestProps => {
    const [type] = args;
    return {
      method: 'GET',
      path: `locations?type=${type}`,
    };
  },
  GetLocationById: (args: [locationId: string]): EndpointRequestProps => {
    const [locationId] = args;
    return {
      method: 'GET',
      path: `locations/${locationId}`,
    };
  },
  GetLocationStatusByIdAndDay: (args: [locationId: string, date: MomentInput]): EndpointRequestProps => {
    const [locationId, date] = args;
    return {
      method: 'GET',
      path: `locations/${locationId}/status?selectedDay=${Formatters.formatDateISOYearMonthDay(date)}`,
    };
  },
  GetBuildingStatusByLocationIdAndDay: (args: [locationId: string, date: MomentInput]): EndpointRequestProps => {
    const [locationId, date] = args;
    return {
      method: 'GET',
      path: `locations/${locationId}/building/status?selectedDay=${Formatters.formatDateISOYearMonthDay(date)}`,
    };
  },
  GetPlaceStatusByIdAndDates: (args: [placeId: string, dates: string[]]): EndpointRequestProps => {
    const [placeId, dates] = args;
    const datesReq: string = dates.join('&date=');

    return {
      method: 'GET',
      path: `places/${placeId}?date=${datesReq}`,
    };
  },
  GetUserBookings: (args: [date: Date[]]): EndpointRequestProps => {
    const [date] = args;
    const from = Formatters.formatDateISOYearMonthDay(date[0]);
    const to = Formatters.formatDateISOYearMonthDay(date[1] ? date[1] : date[0]);

    return {
      method: 'GET',
      path: `users/bookings?from=${from}&to=${to}`,
    };
  },
  GetBookingById: (args: [id: string]): EndpointRequestProps => {
    const [id] = args;
    return {
      method: 'GET',
      path: `bookings?id=${id}`,
    };
  },
  GetReportBookings: (args: [date: Date[], page?: number]): EndpointRequestProps => {
    const [date, page] = args;
    const from = Formatters.formatDateISOYearMonthDay(date[0]);
    const to = Formatters.formatDateISOYearMonthDay(date[1] ? date[1] : date[0]);

    return {
      method: 'GET',
      path: `bookings/report?from=${from}&to=${to}&page=${page || 0}`,
    };
  },
  GetReportUsersByLicensePlate: (args: [licensePlate: string]): EndpointRequestProps => {
    const [licensePlate] = args;

    return {
      method: 'GET',
      path: `users/report?licensePlate=${licensePlate}`,
    };
  },
  CreateBooking: (args: [placeId: string, bookingRequest: IBookingRequest]): EndpointRequestProps => {
    const [placeId, bookingRequest] = args;
    const { dates, guests, licensePlate, engineType, reason, teamSpaceMembers } = bookingRequest;

    return {
      method: 'POST',
      path: 'bookings',
      body: {
        dates,
        guests,
        licensePlate,
        engineType,
        placeId,
        reason,
        teamSpaceMembers,
      },
    };
  },
  EditBooking: (args: [bookingRequest: IBookingRequest]): EndpointRequestProps => {
    const [bookingRequest] = args;
    const { bookingId, licensePlate, engineType, teamSpaceMembers } = bookingRequest;

    return {
      method: 'PUT',
      path: 'bookings',
      body: {
        bookingId,
        licensePlate,
        engineType,
        teamSpaceMembers,
      },
    };
  },
  DeleteBooking: (args: [bookingId: string, confirm?: boolean]): EndpointRequestProps => {
    const [bookingId, confirm] = args;
    return {
      method: 'DELETE',
      path: confirm ? `bookings/${bookingId}?confirm=true` : `bookings/${bookingId}`,
    };
  },
  CreateUserDefaultSettings: (args: [userInformation: IUserInformation]): EndpointRequestProps => {
    const [userInformation] = args;
    const { email, username, licensePlate, engineType, defaultLocationId } = userInformation;

    return {
      method: 'POST',
      path: 'users',
      body: {
        email,
        username,
        licensePlate,
        engineType,
        defaultLocationId,
      },
    };
  },
  LoggedUser: (): EndpointRequestProps => {
    return {
      method: 'GET',
      path: `users/me`,
    };
  },
  GetUserInformation: (args: [dates: string[], userIds: string[]]): EndpointRequestProps => {
    const [dates, userIds] = args;
    const queryParamEmail = 'emails=';
    const queryParamDate = 'dates=';
    const userIdsReq: string = userIds.join('&' + queryParamEmail);
    const datesReq: string = dates.join('&' + queryParamDate);

    return {
      method: 'GET',
      path: `users/user-information?${queryParamDate}${datesReq}&${queryParamEmail}${userIdsReq}`,
    };
  },
};

export type ActionHooksOptions = {
  /** Defer action */
  defer?: boolean;
  skip?: boolean;
};

const defaultOptions: ActionHooksOptions = {
  defer: false,
  skip: false,
};

export const useFetch = <T>(endpoint: EndpointRequestProps, deps: any[] = [], options = defaultOptions) => {
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [message, setMessage] = useState<string | null>(null);
  const [response, setResponse] = useState<T | null>(null);
  const [status, setStatus] = useState<number | null>(null);

  const { defer, skip } = options;

  // This cancel flag prevents state updates on an unmounted components.
  const cancel = useRef(false);

  const run = async () => {
    setLoading(true);
    setResponse(null);
    setStatus(null);
    setError(null);
    try {
      const runResponse = await Ajax.fetchRequest({
        api: config.API_ENDPOINT,
        authorization: await Providers.globalProvider.getAccessToken({ scopes: [config.API_SCOPE] }),
        ...endpoint,
      });

      if (!cancel.current) {
        setLoading(false);
        setMessage(runResponse.body?.message);
        setResponse(runResponse.body);
        setStatus(runResponse.status);
      }
    } catch (runError) {
      if (!cancel.current) {
        setError((runError as Error).message);
        setLoading(false);
        setMessage(null);
      }
    }
  };

  useEffect(() => {
    cancel.current = false;

    // Skip fetch until skip validation is true
    if (defer || skip) {
      setError(null);
      setLoading(false);
      setMessage(null);
      setResponse(null);
    } else if (endpoint.path) {
      void run();
    }

    return () => {
      cancel.current = true;
    };
    // eslint-disable-next-line
  }, [defer, skip, ...deps]);

  return {
    error,
    loading,
    message,
    response,
    run,
    status,
  };
};
