import makeStyles from '@mui/styles/makeStyles';
import { theme } from '../../../../styles/theme';
import sharedStyles from '../../../../styles/sharedStyles/sharedStyles';

const shared = sharedStyles();

const xTransParkingIcon = 4;
const yTransParkingIcon = -3;

export default makeStyles({
  ...shared,

  main: {
    ...shared.flexColumn,
    ...shared.gap16,
    padding: 16,
    paddingRight: 56,
    backgroundColor: theme.colors.blue.lightest,
    width: '100%',
  },

  title: {
    ...shared.colorGreyDark,
    ...shared.fontSize10,
    fontWeight: 'normal',
    letterSpacing: 0.5,
    margin: '0 0 4px',
    textTransform: 'uppercase',
  },

  content: {
    ...shared.flexColumn,
    ...shared.fontSize12,
    ...shared.gap2,
    letterSpacing: 0,
    textTransform: 'uppercase',

    '& p': {
      margin: 0,
    },
  },

  reservedGarage: {
    ...shared.colorBlueRoyal,
    ...shared.flexRowAlignCenter,
    ...shared.gap4,
    textTransform: 'uppercase',
    minWidth: 200,

    '& p': {
      display: 'flex',
      justifyContent: 'end',
    },
  },

  reservedGaragePlace: {
    ...shared.colorBlueMain,
    fontWeight: 'bold',
  },

  reservedGaragePlaceExternal: {
    ...shared.colorGreyDark,
    fontWeight: 'bold',
  },

  marginLeft1: {
    marginLeft: 4,
  },

  parkingIcon: {
    color: theme.colors.grey.dark,
    display: 'inline-block',
    height: 16,
    transform: `translate(${xTransParkingIcon}px, ${yTransParkingIcon}px)`,
    width: 16,
  },

  colorBlueDark: {
    ...shared.colorBlueDark,
  },
});
