import { IBookingRequest } from '../../interfaces/booking/booking-request';
import { Booking } from '../../models/booking/booking';
import { TeamSpaceMember } from '../../models/teamSpaceMember/team-space-member';
import { TeamSpaceMembersRequest } from '../../models/teamSpaceMembersRequest/team-space-members-request';

export class BookingRequestAssembler {
  static fromBooking(booking: Booking): IBookingRequest {
    return {
      bookingId: booking.id,
      dates: booking.dates,
      guests: booking.guests,
      licensePlate: booking.licensePlate,
      engineType: booking.engineType,
      reason: booking.reason,
      teamSpaceMembers: this.fromTeamSpaceMembers(booking.teamSpaceMembers),
    };
  }

  private static fromTeamSpaceMembers = (
    teamSpaceMembers?: Pick<TeamSpaceMember, 'email' | 'needParkingSpace'>[]
  ): TeamSpaceMembersRequest[] | undefined => {
    return (
      teamSpaceMembers?.reduce<TeamSpaceMembersRequest[]>((result, member) => {
        return [...result, { email: member.email ?? '', needParkingSpace: member.needParkingSpace ?? false }];
      }, []) ?? undefined
    );
  };
}
