import classNames from 'classnames';
import React, { useRef } from 'react';
import useStyles from './Collapse.style';

const Collapse: React.FC<Props> = ({ children, className, isOpen = false, timeout }: Props) => {
  const classes = useStyles();
  const parentRef = useRef() as React.MutableRefObject<HTMLDivElement>;

  return (
    <div
      className={classNames(classes.root, className)}
      ref={parentRef}
      style={Object.assign(
        isOpen ? { height: `${parentRef?.current?.scrollHeight}px` } : { height: '0px' },
        timeout !== undefined && { transitionDuration: `${timeout}s` }
      )}
    >
      {children}
    </div>
  );
};

export type Props = {
  className?: string; // Content to be expand.
  children?: React.ReactNode | undefined; // Content to be expand.
  isOpen?: boolean; // If `true`, the component will transition in.
  timeout?: number; // The duration for the transition, in seconds.
};

export default Collapse;
