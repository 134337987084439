import createStyles from '@mui/styles/createStyles';

/**
 * Gaps styles :
 *
 * - 2
 * - 4
 * - 8
 * - 16
 * - 24
 * - 32
 * - 40
 * - 48
 * - 144
 *
 */

const gaps = createStyles({
  gap2: {
    gap: 2,
  },

  gap4: {
    gap: 4,
  },

  gap8: {
    gap: 8,
  },

  gap16: {
    gap: 16,
  },

  gap24: {
    gap: 24,
  },

  gap32: {
    gap: 32,
  },

  gap40: {
    gap: 40,
  },

  gap48: {
    gap: 48,
  },

  gap144: {
    gap: 144,
  },
});

export default gaps;
