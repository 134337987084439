import { UserInformationAction } from '../../actions/userInformationAction/userInformationActions';
import { SET_EMAIL, SET_USERNAME } from '../../constants/appConstants';

export interface UserInformationState {
  email: string;
  username: string;
}

const initialUserInformation = {
  email: '',
  username: '',
};

const userInformationReducer = (
  state: UserInformationState = initialUserInformation,
  action: UserInformationAction
): UserInformationState => {
  switch (action.type) {
    case SET_EMAIL:
      return {
        ...state,
        email: action.email,
      };
    case SET_USERNAME:
      return {
        ...state,
        username: action.username,
      };
    default:
      return state;
  }
};

export default userInformationReducer;
