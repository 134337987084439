import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import sharedStyles from '../../../../styles/sharedStyles/sharedStyles';
import { pxToRemOrEm } from '../../../../styles/utilities';
import { theme, muiTheme } from '../../../../styles/theme';

const shared = sharedStyles();

export default makeStyles(() =>
  createStyles({
    ...shared,

    ReservationCapacityProgressContainer: {
      ...shared.flex,
      background: theme.colors.blue.lightest,
      borderRadius: '50%',
      padding: muiTheme.spacing(4),
      maxWidth: '320px',
      maxHeight: '320px',
      margin: '0 auto',
    },

    childrenContainer: {
      display: 'block',
      background: theme.colors.white.main,
      borderRadius: '50%',
      width: '75%',
      height: '75%',
      position: 'relative',
      [muiTheme.breakpoints.up('md')]: {
        width: '65%',
        height: '65%',
      },
    },

    childrenWrapper: {
      ...shared.flexColumnAlignCenter,
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
    },

    progressText: {
      fontSize: pxToRemOrEm(32),
      lineHeight: pxToRemOrEm(32),
    },

    reservationText: {
      textAlign: 'center',
      minWidth: '16ch',
    },

    totalCapacityText: {
      marginTop: muiTheme.spacing(4),
      marginBottom: muiTheme.spacing(4),
      textAlign: 'center',
    },
  })
);
