import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import sharedStyles from '../../../styles/sharedStyles/sharedStyles';
import { muiTheme, theme } from '../../../styles/theme';
import { pxToRemOrEm } from '../../../styles/utilities';

const shared = sharedStyles();

export default makeStyles(() =>
  createStyles({
    ...shared,

    roundButtonRoot: {
      ...shared.flex,
      fontFamily: 'Roboto',
      border: 0,
      transition: theme.transition,
      '&:hover': {
        opacity: 0.8,
      },
    },

    roundButtonRootAlignLeft: {
      marginRight: 'auto',
    },

    roundButtonRootAlignCenter: {
      marginRight: 'auto',
      marginLeft: 'auto',
    },

    roundButtonRootAlignRight: {
      marginLeft: 'auto',
    },

    roundButtonRootSizeSmall: {
      borderRadius: '12px',
      padding: muiTheme.spacing(0, 4),
    },

    roundButtonRootSizeMedium: {
      borderRadius: '28px',
      padding: muiTheme.spacing(1, 6),
    },

    roundButtonRootSizeLarge: {
      borderRadius: '28px',
      padding: muiTheme.spacing(1, 10),
    },

    roundButtonRootBlueColor: {
      background: theme.colors.blue.main,
      color: theme.colors.white.main,
      '&:hover': {
        background: theme.colors.blue.main,
        color: theme.colors.white.main,
      },
    },

    roundButtonRootBlueRoyalColor: {
      background: theme.colors.blue.royal,
      color: theme.colors.white.main,
      '&:hover': {
        background: theme.colors.blue.royal,
        color: theme.colors.white.main,
      },
    },

    roundButtonRootGreyColor: {
      background: theme.colors.grey.dark,
      color: theme.colors.white.main,
      '&:hover': {
        background: theme.colors.grey.main,
        color: theme.colors.white.main,
      },
    },

    roundButtonRootOutline: {
      background: theme.colors.white.main,
      color: theme.colors.blue.main,
      outline: `${theme.colors.blue.main} 1px solid`,
      '&:hover': {
        background: theme.colors.blue.main,
        color: theme.colors.white.main,
      },
    },

    roundButtonOutlineDisabled: {
      color: `${theme.colors.blue.main} !important`,
      pointerEvents: 'none',
      opacity: 0.5,
    },

    roundButtonLabel: {
      fontWeight: 'bold',
    },

    roundButtonLabelTextSmall: {
      fontSize: pxToRemOrEm(12),
    },

    roundButtonLabelTextMedium: {
      fontSize: pxToRemOrEm(14),
    },

    roundButtonLabelTextLarge: {
      fontSize: pxToRemOrEm(16),
    },

    buttonWrapper: {
      display: 'inline-block',
      margin: '0',
    },

    textTransformCapitalize: {
      textTransform: 'capitalize',
    },

    textTransformUppercase: {
      textTransform: 'uppercase',
    },

    textTransformNone: {
      textTransform: 'none',
    },
  })
);
