import sharedStyles from '../../../styles/sharedStyles/sharedStyles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { muiTheme, theme } from '../../../styles/theme';

const shared = sharedStyles();

export default makeStyles(() =>
  createStyles({
    ...shared,

    table: {
      minWidth: 650,
    },

    tableHead: {
      color: theme.colors.white.main,
      backgroundColor: theme.colors.grey.main,
    },

    tableRow: {
      fontSize: 14,
    },

    loadMoreTableButton: {
      backgroundColor: theme.colors.grey.main,
      color: theme.colors.white.main,
      cursor: 'pointer',
      fontWeight: 'bold',
      textAlign: 'center',
    },

    container: {
      height: 'fit-content',
      margin: '0 auto',
      marginBottom: '32px',
      padding: '32px 24px',
      [muiTheme.breakpoints.up('sm')]: {
        maxWidth: '900px',
      },
    },

    marginTop: {
      marginTop: muiTheme.spacing(5),
    },
  })
);
