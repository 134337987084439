import { TeamSpaceMember } from '../../models/teamSpaceMember/team-space-member';

export class TeamSpaceMemberRequestAssembler {
  static from(teamSpaceMember: TeamSpaceMember): string {
    return teamSpaceMember.person?.[0]?.userPrincipalName || '';
  }

  static fromList(teamSpaceMemberList: TeamSpaceMember[]): string[] {
    return teamSpaceMemberList.map((teamSpaceMember) => this.from(teamSpaceMember));
  }
}
