import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { isEmpty, map, orderBy } from 'lodash';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import { lisbonFloors, lisbonBlueprintLegend, portoBlueprintLegend, Legend } from './Rules-item';
import useStyles from './GarageBlueprintModal.style';
import BlueRadio from '../../Shared/BlueRadio/BlueRadio';
import i18n from '../../../translations/i18n';
import classNames from 'classnames';
import Modal from '../../Shared/Modal/Modal';
import lisbonMapOne from '../../../assets/svg/floors/lisbon_map-1.svg';
import lisbonMapTwo from '../../../assets/svg/floors/lisbon_map-2.svg';
import lisbonMapThree from '../../../assets/svg/floors/lisbon_map-3.svg';
import lisbonMapFour from '../../../assets/svg/floors/lisbon_map-4.svg';
import portoMap from '../../../assets/svg/floors/porto_map.svg';
import { ReactComponent as IconFuelLisbon } from '../../../assets/svg/fuel_lisbon.svg';
import { ReactComponent as IconElectric } from '../../../assets/svg/electric_circulation_rules.svg';
import useMediaQuery from '@mui/material/useMediaQuery';
import { muiMobileQuery } from '../../../constants/mediaQuery';
import { BuildingUtils } from '../../../utils/building/bulding-utils';

const GarageBlueprintModal: React.FC<Props> = ({ selectedBuildingOption, onClose }) => {
  const classes = useStyles();
  void i18n.loadNamespaces('rules');
  const { t } = useTranslation('rules');
  const isMuiMobile = useMediaQuery(muiMobileQuery);

  const [lisbonFloor, setLisbonFloor] = useState<string>(lisbonFloors[0]);
  const onChangeLisbonFloor = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLisbonFloor(event.target.value);
  };

  return (
    <>
      <Modal
        confirmLabel='title'
        onClose={onClose}
        title={
          BuildingUtils.isLisbon(selectedBuildingOption)
            ? t('garageBlueprintModal.lisbonGaragePlace')
            : t('garageBlueprintModal.portoGaragePlace')
        }
        classNameOverride={classes.modalContainer}
        maxWidth={false}
      >
        <div className={classNames(classes.modalBodyContainer, classes.modalBodyMarginReset)}>
          {BuildingUtils.isLisbon(selectedBuildingOption) && renderLisbonMaps()}
          {BuildingUtils.isPorto(selectedBuildingOption) && (
            <div className={classNames(isMuiMobile && classes.renderReverseLegend)}>
              <div className={classes.legendPorto}>{renderPortoLegend(portoBlueprintLegend)}</div>
              {renderPortoMap()}
            </div>
          )}
        </div>
      </Modal>
    </>
  );

  function renderLisbonMaps() {
    let floorUrl;
    switch (lisbonFloor) {
      case '-1':
      default:
        floorUrl = lisbonMapOne;
        break;
      case '-2':
        floorUrl = lisbonMapTwo;
        break;
      case '-3':
        floorUrl = lisbonMapThree;
        break;
      case '-4':
        floorUrl = lisbonMapFour;
        break;
    }
    const legend = lisbonBlueprintLegend.find((floor) => floor.floor === lisbonFloor);

    return (
      <div className={classNames(isMuiMobile && classes.renderReverseLegend)}>
        <div className={classes.legendLisbon}>
          <FormControl className={classes.formControl}>
            <FormLabel>
              <Typography variant='body2' className={classes.legendTitle}>
                {t('garageBlueprintModal.floor')}
              </Typography>
            </FormLabel>
            <RadioGroup
              aria-label={t('officeLocation.officeLocation')}
              className={classes.radioGroup}
              onChange={onChangeLisbonFloor}
              value={lisbonFloor}
            >
              {lisbonFloors.map((floor) => (
                <FormControlLabel
                  control={<BlueRadio size={'small'} />}
                  key={floor}
                  value={floor}
                  label={floor}
                  componentsProps={{
                    typography: {
                      variant: 'body2',
                    },
                  }}
                />
                //switch case que se for piso -1 renderiza imagem 1
              ))}
            </RadioGroup>
          </FormControl>
          <div className={classes.legendLisbonDetailsContainer}>
            <div className={classes.legendDetails}>
              {legend?.showElectricIcon && (
                <div className={classNames(classes.flexRowAlignCenter, !isMuiMobile && classes.paddingLeft3)}>
                  <IconElectric className={classes.legendIcons} />
                  <Typography variant='body2' className={classes.legendText}>
                    {t('garageBlueprintModal.electricVehicleType')}
                  </Typography>
                </div>
              )}
              {legend?.showCombustionIcon && (
                <div className={classNames(classes.flexRowAlignCenter, !isMuiMobile && classes.paddingLeft3)}>
                  <IconFuelLisbon className={classes.legendIcons} />
                  <Typography variant='body2' className={classes.legendText}>
                    {t('garageBlueprintModal.combustionVehicleType')}
                  </Typography>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={classes.garageBlueprintImagesContainer}>
          <img
            loading={'lazy'}
            alt={t(`garageBlueprintModal.lisbonGarageBlueprint.floor${lisbonFloor}`)}
            src={floorUrl}
            className={classes.garageBlueprintImage}
          />
        </div>
      </div>
    );
  }

  function renderPortoMap() {
    return (
      <div className={classes.garageBlueprintImagesContainer}>
        <img
          loading={'lazy'}
          alt={t('garageBlueprintModal.portoGarageBlueprint')}
          src={portoMap}
          className={classes.garageBlueprintImage}
        />
      </div>
    );
  }

  function renderPortoLegend(legend: Legend[]) {
    return map(legend, (l, index) => (
      <div className={classes.flexRowAlignCenter} key={index}>
        {l.Icon && <l.Icon />}
        <Typography variant='body2' className={classes.legendText}>
          <Trans components={{ 1: <strong />, 2: <li /> }} t={t} i18nKey={l.description} />
        </Typography>
      </div>
    ));
  }
};

export type Props = {
  selectedBuildingOption: string;
  onClose: () => void;
};

export default GarageBlueprintModal;
