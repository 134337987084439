import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import useStyles from './LicensePlateAndEngineType.style';
import EngineTypeButtons from '../EngineTypeButtons/EngineTypeButtons';
import { LICENSE_PLATE_REGEX } from '../../../constants/regex';
import { EngineTypeEnum } from '../../../enums/engineType.enum';
import { ReactComponent as IconClose } from '../../../assets/svg/icon_close.svg';

const LicensePlateAndEngineType: React.FC<Props> = ({
  engineType,
  setEngineType,
  setLicensePlate,
  className = '',
  licensePlate = '',
}) => {
  const classes = useStyles();
  const { t } = useTranslation('settings');

  const isLicensePlateInvalid = useMemo(() => {
    return licensePlate !== '' && !LICENSE_PLATE_REGEX.test(licensePlate);
  }, [licensePlate]);

  const handleChangeLicensePlate = (event: React.ChangeEvent<{ value: string }>) => {
    setLicensePlate(event.target.value.toUpperCase());
    setEngineType(undefined);
  };

  const handleClearInputField = () => {
    setLicensePlate('');
    setEngineType(undefined);
  };

  return (
    <div className={classNames(classes.inputGroup, className)}>
      <TextField
        variant={'outlined'}
        className={classes.inputField}
        onChange={handleChangeLicensePlate}
        placeholder={t('licensePlate.licensePlatePlaceholder')}
        value={licensePlate}
        inputProps={{ maxLength: 6, autoComplete: 'off', 'data-testid': 'license-plate-input' }}
        InputProps={{
          endAdornment: licensePlate && (
            <InputAdornment position='end'>
              <IconButton aria-label={t('licensePlate.clear')} onClick={handleClearInputField} disableRipple edge='end'>
                <IconClose />
              </IconButton>
            </InputAdornment>
          ),
        }}
        error={isLicensePlateInvalid}
        helperText={isLicensePlateInvalid && t('licensePlate.licensePlateHelperText')}
      />
      <EngineTypeButtons
        buttonPadding={7}
        iconSize={16}
        engineType={engineType}
        setEngineType={setEngineType}
        disabled={isLicensePlateInvalid || licensePlate === ''}
      />
    </div>
  );
};

interface Props {
  className?: string;
  engineType?: EngineTypeEnum;
  licensePlate?: string;
  setEngineType: (type?: EngineTypeEnum) => void;
  setLicensePlate: (licensePlate?: string) => void;
}

export default LicensePlateAndEngineType;

/**
 * ALTERAR ESTILOS
 *
 * ALTERAR Card do my reservations para receber engine type pelo booking
 *
 * Pass props to tooltip to fix overflow
 */
