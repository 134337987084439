import * as React from 'react';
import { Permission, PermissionsEnum } from './GuardedRoute';
import ReservationWizard from '../../pages/CreateReservation/ReservationWizard/ReservationWizard';
import Dashboard from '../../pages/Dashboard/Dashboard';
import DefaultSettings from '../../pages/DefaultSettings/DefaultSettings';
import MyReservationsRouter from '../../pages/MyReservations/MyReservationsRouter';
import Report from '../../pages/Report/Report';
import Rules from '../../pages/Rules/Rules';
import { RolesEnum } from '../../../enums/roles.enum';
import { RolesUtil } from '../../../utils/roles/roles-util';
import LicensePlateReport from '../../pages/LicensePlateReport/LicensePlateReport';

type MenuEntry = {
  link: RoutePaths;
  title: string;
};

export interface RouteEntry {
  children: React.ReactElement;
  path?: RoutePaths;
  permissions?: Permission[];
  title: string;
}

export enum RoutePaths {
  backoffice = '/backoffice',
  backofficeRouter = '/backoffice/*',
  circulationRules = '/circulation',
  dashboard = '/dashboard',
  editTeamSpace = '/edit-team-space',
  makeReservation = '/make-reservation',
  myReservations = '/my-reservations',
  myReservationsRouter = 'my-reservations/*',
  report = '/report',
  licensePlateReport = '/licensePlateReport',
  userDefaults = '/user-defaults',
}

export enum EditModes {
  teamSpace = 'edit-team-space',
}

export const defaultMenuEntries: MenuEntry[] = [
  {
    link: RoutePaths.dashboard,
    title: 'sidebar.dashboard',
  },
  {
    link: RoutePaths.myReservations,
    title: 'sidebar.myReservations',
  },
  {
    link: RoutePaths.circulationRules,
    title: 'sidebar.circulationRules',
  },
  {
    link: RoutePaths.userDefaults,
    title: 'sidebar.defaultSettings',
  },
];

export const reportMenuEntries: MenuEntry[] = [
  {
    link: RoutePaths.report,
    title: 'sidebar.report',
  },
  {
    link: RoutePaths.licensePlateReport,
    title: 'sidebar.licensePlateReport',
  },
];

export function getMenuEntries(roles: string[]): MenuEntry[] {
  if (RolesUtil.hasRole(RolesEnum.REPORT, roles)) {
    return [...defaultMenuEntries, ...reportMenuEntries];
  }

  return defaultMenuEntries;
}

export const routeEntries: RouteEntry[] = [
  {
    children: <Dashboard />,
    path: RoutePaths.dashboard,
    permissions: [
      {
        permission: PermissionsEnum.HAS_DEFAULT_SETTINGS,
        ifNotRedirectTo: RoutePaths.userDefaults,
      },
    ],
    title: 'Dashboard',
  },
  {
    children: <MyReservationsRouter />,
    path: RoutePaths.myReservationsRouter,
    permissions: [
      {
        permission: PermissionsEnum.HAS_DEFAULT_SETTINGS,
        ifNotRedirectTo: RoutePaths.userDefaults,
      },
    ],
    title: 'My Reservations',
  },
  {
    children: <ReservationWizard />,
    path: RoutePaths.makeReservation,
    permissions: [
      {
        permission: PermissionsEnum.HAS_DEFAULT_SETTINGS,
        ifNotRedirectTo: RoutePaths.userDefaults,
      },
    ],
    title: 'Make a Reservation',
  },
  {
    children: <Rules />,
    path: RoutePaths.circulationRules,
    title: 'Parking Policies',
  },
  {
    children: <DefaultSettings />,
    path: RoutePaths.userDefaults,
    title: 'User Defaults',
  },
  {
    children: <Report />,
    path: RoutePaths.report,
    permissions: [
      {
        permission: PermissionsEnum.HAS_REPORT_ROLE,
        ifNotRedirectTo: RoutePaths.dashboard,
      },
    ],
    title: 'Report',
  },
  {
    children: <LicensePlateReport />,
    path: RoutePaths.licensePlateReport,
    permissions: [
      {
        permission: PermissionsEnum.HAS_REPORT_ROLE,
        ifNotRedirectTo: RoutePaths.dashboard,
      },
    ],
    title: 'License Plates',
  },
];
