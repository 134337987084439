import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import sharedStyles from '../../../styles/sharedStyles/sharedStyles';
import { muiTheme, theme } from '../../../styles/theme';

const shared = sharedStyles();
const accordionBorder = `1px solid ${theme.colors.grey.border}`;

export default makeStyles(() =>
  createStyles({
    ...shared,

    modalBodyContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      [muiTheme.breakpoints.only('xs')]: {
        height: theme.heightFull,
      },
    },

    modalBodyMarginReset: {
      '& > *': {
        margin: 0,
      },
    },

    modalBodyHeading: {
      color: theme.colors.grey.main,
      fontSize: 20,
      fontWeight: 'bold',
      lineHeight: '30px',
      marginTop: 10,
    },

    legendLisbon: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      paddingBottom: '28px',
      paddingTop: '4px',
      backgroundColor: theme.colors.white.main,
      position: 'sticky',
      top: '-24px',
      zIndex: 1,
      width: '100%',
      columnGap: '1rem',
      [muiTheme.breakpoints.only('xs')]: {
        flexDirection: 'column',
        paddingTop: '24px',
        paddingBottom: 0,
        position: 'unset',
        justifyContent: 'space-between',
      },
    },

    legendLisbonDetailsContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      width: '100%',
      [muiTheme.breakpoints.only('xs')]: {
        justifyContent: 'flex-start',
      },
    },

    formControl: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      width: '100%',
      [muiTheme.breakpoints.only('xs')]: {
        justifyContent: 'flex-start',
      },
    },

    legendTitle: {
      fontWeight: 'bold',
      marginRight: '24px',
    },

    radioGroup: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      '& >label>span': {
        fontWeight: 'bold',
      },
    },

    legendPorto: {
      display: 'flex',
      paddingBottom: '28px',
      paddingTop: '4px',
      backgroundColor: theme.colors.white.main,
      position: 'sticky',
      top: '-24px',
      columnGap: '1rem',
      zIndex: 1,
      [muiTheme.breakpoints.only('xs')]: {
        paddingTop: '24px',
        paddingBottom: 0,
        position: 'unset',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
      },
    },

    legendDetails: {
      display: 'flex',
      paddingLeft: '20px',
      [muiTheme.breakpoints.only('xs')]: {
        paddingLeft: 0,
        columnGap: '1rem',
      },
    },

    paddingLeft3: {
      paddingLeft: muiTheme.spacing(3),
    },

    modalContainer: {
      maxWidth: '880px',
    },

    renderReverseLegend: {
      display: 'flex',
      flexDirection: 'column-reverse',
      height: '100%',
    },

    garageBlueprintImagesContainer: {
      width: '100%',
      height: '100%',
      paddingInline: '74px',
      [muiTheme.breakpoints.only('xs')]: {
        height: '100%',
        minWidth: 'max-content',
        overflowX: 'auto',
        padding: '30px',
        paddingLeft: 0,
      },
    },

    garageBlueprintImage: {
      width: '100%',
      height: '100%',
    },

    legendIcons: {
      width: '27px',
      height: '27px',
    },

    legendText: {
      display: 'flex',
      flexDirection: 'row',
      width: '12ch',
      marginLeft: '8px',
      fontWeight: 'bold',
      lineHeight: '1rem',
    },
  })
);
