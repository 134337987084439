import { padStart } from 'lodash';
import { htmlFontSize } from './theme';

/**
 * Create a css box shadow value
 *
 * @param offsetY offsetY value
 * @param blurRadius blurRadius value
 * @param opacity opacity value
 * @example makeShadow(5, 10, 0.5) returns 0 5px 10px 0 rgba(0, 0, 0, 0.5)
 */

export function makeShadow(offsetY: number, blurRadius: number, opacity: number) {
  return `0 ${offsetY}px ${blurRadius}px 0 rgba(0, 0, 0, ${opacity})`;
}

/**
 * Convert px to REM or EM unit
 *
 * @param px Pixel value
 * @param unit REM or EM (Default : REM)
 * @example pxToRemOrEm(14) returns 1rem - root font size is 14
 */

export function pxToRemOrEm(px: number, unit?: 'rem' | 'em') {
  return `${(1 / htmlFontSize) * px}${unit || 'rem'}`;
}

/**
 * Convert rgb value to hex
 *
 * @param r Red value
 * @param g Green value
 * @param b Blue value
 * @param a Alpha value (Opacity)
 * @example rgbToHex(80, 227, 194) returns #50E3C3
 */
export function rgbToHex(r: number, g: number, b: number, a?: number) {
  function componentToHex(value: number) {
    const hex = value.toString(16);
    return hex.length === 1 ? `0${hex}` : hex;
  }

  const hexValue = '#' + componentToHex(r) + componentToHex(g) + componentToHex(b);

  return a ? withOpacity(hexValue, a) : hexValue;
}

/**
 * Adds opacity to existing hex color
 *
 * @param color Color in HEX
 * @param opacity Value of opacity in range (0, 1)
 * @example withOpacity('#E5E5E5', 0.7) returns #E5E5E5B3
 */
export function withOpacity(color: string, opacity: number) {
  if ((color.length !== 4 && color.length !== 7) || !color.startsWith('#')) {
    throw new Error('Color must be hex format');
  }

  // Check if color is written in short hand, i.e: #FCO is equal to #FFCC00
  if (color.length === 4) {
    const [red, green, blue] = color.slice(1).split('');

    color = `#${red}${red}${green}${green}${blue}${blue}`;
  }

  // Cast the opacity into Hex representation
  const opacityAsHex = Math.round(opacity * 255).toString(16);

  return color + padStart(opacityAsHex, 2, '0');
}

/**
 * Returns the complementary value of a CSS property
 *
 * @param value the value to be turned into negative
 */
export function negativeValue(value: number) {
  return value * -1;
}
