import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import sharedStyles from '../../../styles/sharedStyles/sharedStyles';
import { theme } from '../../../styles/theme';
import { withOpacity } from '../../../styles/utilities';

const shared = sharedStyles();

const clearButtonSize = '0.5rem';

export default makeStyles(() =>
  createStyles({
    ...shared,

    inputGroup: {
      display: 'flex',
      flexDirection: 'row',
      gap: 8,
    },

    inputField: {
      flex: '1 1 auto',
      '& .MuiOutlinedInput-input': {
        paddingBlock: 6,
        fontSize: '0.875rem',
      },
      '& svg': {
        width: clearButtonSize,
        height: clearButtonSize,
      },
    },
  })
);
