import React from 'react';
import Typography from '@mui/material/Typography';
import useStyles from './Loading.style';
import LoadingSpinner from './LoadingSpinner';

const Loading: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.spinnerWrapper}>
        <LoadingSpinner />
      </div>
      <Typography variant='subtitle2' className={classes.loadingSpinnerText}>
        Wally is setting up the seats...
      </Typography>
    </div>
  );
};

export default Loading;
