import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import sharedStyles from '../../../styles/sharedStyles/sharedStyles';
import { muiTheme, theme } from '../../../styles/theme';

const shared = sharedStyles();
const accordionBorder = `1px solid ${theme.colors.grey.border}`;

export default makeStyles(() =>
  createStyles({
    ...shared,

    container: {
      paddingTop: '32px',
      marginInline: 'auto',
    },

    header: {
      backgroundColor: theme.colors.white.main,
      position: 'sticky',
      top: 0,
      /* make sure header overlaps main*/
      zIndex: 1,
      paddingTop: 42,

      [muiTheme.breakpoints.down('md')]: {
        paddingBottom: 36,
        paddingTop: 20,
      },
    },

    title: {
      fontWeight: 'bold',
      color: theme.colors.grey.main,
    },

    officeLocation: {
      backgroundColor: theme.colors.white.main,
      display: 'flex',
      justifyContent: 'center',
      maxWidth: '160ch',
      paddingInline: '24px',
      position: 'sticky',
      top: 0,
      zIndex: 3,
      paddingBottom: '24px',

      [muiTheme.breakpoints.only('xs')]: {
        paddingBottom: 0,
      },

      marginInline: 'auto',
      '& >label': {
        marginBottom: 26,
      },

      '& .MuiFormLabel-root.Mui-focused': {
        color: theme.colors.grey.main,
      },
    },

    officeLocationLeftGrid: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },

    officeLocationRightGrid: {
      display: 'flex',
      justifyContent: 'right',
      alignItems: 'center',
    },

    officeLocationMapButtonHidden: {
      display: 'none',
      maxWidth: '340px',
      width: theme.widthFull,
      height: '95px',
      '& >button': {
        display: 'flex',
        justifyContent: 'left',
        alignItems: 'center',
        width: theme.widthFull,
        height: theme.heightFull,
        backgroundColor: theme.colors.blue.main,
        borderRadius: '6px',
        cursor: 'pointer',
        border: 'none',
      },
    },

    officeLocationMapButton: {
      maxWidth: '340px',
      width: theme.widthFull,
      height: '95px',
      '& >button': {
        display: 'flex',
        justifyContent: 'left',
        alignItems: 'center',
        width: theme.widthFull,
        height: theme.heightFull,
        backgroundColor: theme.colors.blue.main,
        borderRadius: '6px',
        cursor: 'pointer',
        border: 'none',
      },
    },

    garageBlueprintButtonText: {
      display: 'flex',
      flexDirection: 'column',
      color: theme.colors.white.main,
      fontWeight: 'bold',
      textAlign: 'left',
      padding: '14px',

      '& >p': {
        margin: 0,
      },
    },

    iconDoc: {
      marginBottom: '8px',
    },

    formContainerHidden: {
      display: 'none',
      flexDirection: 'column',
      alignItems: 'start',
    },

    formContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
    },

    innerContainer: {
      paddingInline: '24px',
      paddingBottom: '32px',
      maxWidth: '160ch',
      marginInline: 'auto',
    },

    accordionStyles: {
      boxShadow: 'none',
      borderTop: accordionBorder,
    },

    parkingAddress: {
      backgroundColor: theme.colors.blue.lightest,
      borderRadius: 6,
      paddingBlock: 16,
      paddingLeft: 12,
      paddingRight: 12,
      marginBottom: 16,
    },

    parkingAddressDetails: {
      marginBottom: '8px',
    },

    pinIcon: {
      color: theme.colors.blue.main,
      marginRight: 12,
    },

    parkingTitle: {
      color: theme.colors.blue.main,
      '& svg': {
        marginRight: 12,
      },
    },

    icons: {
      margin: 10,
    },

    mobileGridAlignment: {
      [muiTheme.breakpoints.only('xs')]: {
        justifyContent: 'space-between',
      },
    },

    openMaps: {
      color: theme.colors.blue.main,
    },
  })
);
