import React from 'react';
import Radio, { RadioProps } from '@mui/material/Radio';
import withStyles from '@mui/styles/withStyles';
import { theme } from '../../../styles/theme';

const RadioButton = withStyles({
  root: {
    color: theme.colors.blue.royal,
    '&$checked': {
      color: theme.colors.blue.royal,
    },
  },
  checked: {},
})((props: RadioProps) => <Radio color='default' {...props} />);

export default RadioButton;
