import rootReducer, { StoreState } from '../reducers/rootReducer/rootReducer';
import { AnyAction, applyMiddleware, compose, createStore, Middleware, Reducer, Store, StoreEnhancer } from 'redux';
import thunk from 'redux-thunk';
import persistState, { mergePersistedState } from 'redux-localstorage';
import reduxLogger from 'redux-logger';
import { composeWithDevTools } from '@redux-devtools/extension';
import config from '../../config/config';
import filter from 'redux-localstorage-filter';
import adapter from 'redux-localstorage/lib/adapters/localStorage';

export default function configureStore(): Store<StoreState> {
  const storage = filter([
    'app.selectedBuilding',
    'app.selectedFloor',
    'app.licensePlate',
    'app.useParking',
    'app.selectedDate',
    'app.modalState',
    'userInformation',
    'booking.selectedPlace',
  ])(adapter(window.localStorage));

  let enhancer: StoreEnhancer = compose(applyMiddleware(...getMiddlewares()), persistState(storage));
  enhancer = composeWithDevTools(enhancer);

  const reducer = mergePersistedState()(rootReducer) as Reducer<StoreState, AnyAction>;

  return createStore(reducer, enhancer);
}

export function clearStore(): void {
  window.localStorage.clear();
}

function getMiddlewares(): Middleware[] {
  const middlewares: Middleware[] = [];

  middlewares.push(thunk);
  if (['localhost', 'dev'].includes(config.STAGE)) {
    middlewares.push(reduxLogger);
  }

  return middlewares;
}
