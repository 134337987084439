import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import sharedStyles from '../../../styles/sharedStyles/sharedStyles';
import { theme } from '../../../styles/theme';

const shared = sharedStyles();

export default makeStyles(() =>
  createStyles({
    ...shared,

    engineTypeButton: {
      borderRadius: 6,
      minWidth: 0,
      padding: 3,

      '&.MuiButton-outlined.Mui-disabled': {
        borderColor: theme.colors.grey.light,

        '& svg path': {
          fill: theme.colors.grey.light,
        },
      },
    },

    engineTypeButtonActive: {
      backgroundColor: theme.colors.blue.royal,
      borderColor: theme.colors.blue.royal,

      '&:hover': {
        backgroundColor: theme.colors.blue.royal,
      },

      '& svg path': {
        fill: theme.colors.white.main,
      },
    },
  })
);
