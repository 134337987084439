import createStyles from '@mui/styles/createStyles';

/**
 * Flexbox styles :
 *
 * - alignContent
 * - alignItems
 * - flexBasis
 * - flexDirection
 * - flexFlow
 * - flexGrow
 * - flexShrink
 * - flexWrap
 * - justifyContent
 * - order
 *
 */

const flexbox = createStyles({
  flex: {
    display: 'flex',
  },

  flexWrap: {
    flexWrap: 'wrap',
  },

  alignSelfCenter: {
    alignSelf: 'center',
  },

  flexRow: {
    display: 'flex',
    flexDirection: 'row',
  },

  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
  },

  flexAlignCenter: {
    alignItems: 'center',
    display: 'flex',
  },

  flexJustifyCenter: {
    display: 'flex',
    justifyContent: 'center',
  },

  flexRowJustifyCenter: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },

  flexRowJustifyFlexStart: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },

  flexRowAlignCenter: {
    alignItems: 'center',
    display: 'flex',
  },

  flexRowJustifySpaceBetween: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },

  flexRowJustifySpaceAround: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },

  flexColumnJustifySpaceBetween: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },

  flexColumnJustifySpaceBetweenAlignCenter: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },

  flexColumnJustifyCenter: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },

  flexColumnAlignCenter: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },

  flexJustifySpaceBetween: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
  },

  flexRowAlignCenterJustifySpaceBetween: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },

  flexRowAlignCenterJustifyCenter: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },

  flexColumnAlignCenterJustifyCenter: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },

  flexColumnAlignFlexStartJustifyCenter: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },

  flexRowAlignFlexStartJustifyEnd: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    display: 'flex',
  },

  flexRowAlignFlexStartJustifyCenter: {
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
});

export default flexbox;
