import { SELECT_FLOOR_SUCCESS, SELECT_PLACE_SUCCESS, SELECT_PARKING_SPACE } from '../../constants/appConstants';
import { BookingAction } from '../../actions/bookingActions/bookingActions';
import { Location } from '../../../models/location/location';
import { Place } from '../../../models/place/place';

export interface BookingState {
  selectedFloor?: Location;
  selectedPlace?: Place;
  selectedParkingSpace?: boolean;
}

const bookingReducer = (state: BookingState = {}, action: BookingAction): BookingState => {
  switch (action.type) {
    case SELECT_FLOOR_SUCCESS:
      return {
        ...state,
        selectedFloor: action.floor,
      };
    case SELECT_PLACE_SUCCESS:
      return {
        ...state,
        selectedPlace: action.place,
      };
    case SELECT_PARKING_SPACE:
      return {
        ...state,
        selectedParkingSpace: action.parkingSpace,
      };
    default:
      return state;
  }
};

export default bookingReducer;
