import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import sharedStyles from '../../../styles/sharedStyles/sharedStyles';
import { muiTheme, theme } from '../../../styles/theme';

const shared = sharedStyles();

const closeButtonSize = '12px';

export default makeStyles(() =>
  createStyles({
    ...shared,

    modal: {
      position: 'fixed',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'rgba(0,0,0,0.5)',
      zIndex: 1111,
    },

    modalContainer: {
      display: 'grid',
      maxHeight: '80vh',
      gridTemplateRows: 'auto 1fr auto',
      [muiTheme.breakpoints.up('sm')]: {
        display: 'grid',
        zIndex: 1,
      },
      backgroundColor: theme.colors.white.main,
      borderRadius: 6,
    },

    maxWidth: {
      maxWidth: '90vw',
      [muiTheme.breakpoints.up('sm')]: {
        display: 'grid',
        zIndex: 1,
        maxWidth: 680,
      },
    },

    modalContainerFullscreen: {
      [muiTheme.breakpoints.down('sm')]: {
        height: theme.heightFull,
        maxHeight: theme.heightFull,
        maxWidth: theme.widthFull,
      },
    },

    modalHeader: {
      boxShadow: '0px 8px 16px -8px rgb(242 242 242)',
      display: 'flex',
      justifyContent: 'space-between',
      paddingBottom: 32,
      paddingInline: 40,
      paddingTop: 24,
      height: '80px',
      zIndex: 3,
      [muiTheme.breakpoints.only('xs')]: {
        paddingInline: 24,
      },
    },

    modalTitle: {
      color: theme.colors.grey.main,
      fontSize: 20,
      fontWeight: 'bold',
      [muiTheme.breakpoints.only('xs')]: {
        paddingRight: 16,
      },
    },

    modalSubTitle: {
      color: theme.colors.grey.dark,
      fontSize: 14,
      fontWeight: 'normal',
      letterSpacing: 0,
    },

    textTransformNone: {
      textTransform: 'none',
    },

    modalHeaderText: {
      display: 'flex',
      flexDirection: 'column',
    },

    modalHeaderCloseButton: {
      paddingTop: 4,
    },

    modalCloseButton: {
      padding: 0,
      color: theme.colors.grey.main,
      '& svg': {
        width: closeButtonSize,
        height: closeButtonSize,
      },
    },

    modalBody: {
      color: theme.colors.grey.main,
      fontSize: 16,
      fontWeight: 'normal',
      overflowX: 'hidden',
      overflowY: 'auto',
      paddingBlock: 24,
      paddingInline: 40,
      [muiTheme.breakpoints.only('xs')]: {
        padding: 18,
      },
      '&::-webkit-scrollbar': {
        width: 27,
        height: 18,
      },
      '&::-webkit-scrollbar-thumb': {
        height: 6,
        border: '10px solid rgba(0, 0, 0, 0)',
        backgroundClip: 'padding-box',
        '-webkit-border-radius': '14px',
        backgroundColor: 'rgba(178,180,182)',
      },
    },

    modalFooter: {
      boxShadow: '0px -8px 16px -8px rgb(242 242 242)',
      padding: 18,
      textAlign: 'center',
    },

    buttonsPosition: {
      ...shared.flexJustifyCenter,
      gap: 8,
      [muiTheme.breakpoints.up('sm')]: {
        gap: 24,
      },
    },

    buttons: {
      [muiTheme.breakpoints.up('sm')]: {
        padding: muiTheme.spacing(1, 10),
      },
      '& > *': {
        [muiTheme.breakpoints.down('md')]: {
          fontSize: '1.2rem',
        },
      },
    },

    disagreeButton: {
      marginRight: 20,
      [muiTheme.breakpoints.only('xs')]: {
        marginRight: 0,
        marginBottom: 10,
      },
    },

    agreeButton: {
      marginLeft: 20,
      [muiTheme.breakpoints.only('xs')]: {
        marginLeft: 0,
        marginTop: 10,
      },
    },

    loadingSpinnerContainer: {
      ...shared.flexRowAlignCenterJustifyCenter,
      marginBlock: 3,
      [muiTheme.breakpoints.up('sm')]: {
        height: 24,
        marginBlock: 4,
        marginInline: 13,
        width: 24,
      },
    },
  })
);
