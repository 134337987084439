import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useStyles from './LicensePlate.style';
import LicensePlateEngineType from '../LicensePlateEngineType/LicensePlateEngineType';
import { LicensePlateOptionsEnum, LicensePlateEngineTypeEnum } from '../../../../enums/licensePlateEngineTypeEnum';
import { EngineTypeEnum } from '../../../../enums/engineType.enum';
import i18n from '../../../../translations/i18n';
import useIsLicensePlateValid from '../../../../hooks/customHooks/useIsLicensePlateValid';

const LicensePlate: React.FC<Props> = ({
  defaultLicensePlate,
  defaultEngineType,
  needParkingSpace,
  reducerLicensePlate,
  reducerEngineType,
  setLicensePlate,
  setEngineType,
  setWithError,
  toggleNeedParkingSpace,
}) => {
  const [licensePlateOption, setLicensePlateOption] = useState<string>(
    !reducerLicensePlate || reducerLicensePlate === defaultLicensePlate
      ? LicensePlateOptionsEnum.RADIO_VALUE_DEFAULT
      : LicensePlateOptionsEnum.RADIO_VALUE_NEW
  );
  const [newLicensePlate, setNewLicensePlate] = useState<string>(
    defaultLicensePlate && reducerLicensePlate === defaultLicensePlate ? '' : reducerLicensePlate!
  );
  const [wasFocused, setWasFocused] = useState<boolean>(false);

  const classes = useStyles();

  const isLicensePlateValid = useIsLicensePlateValid(newLicensePlate);

  const isLicensePlateOptionDefault = licensePlateOption === LicensePlateOptionsEnum.RADIO_VALUE_DEFAULT;
  void i18n.loadNamespaces('settings');
  const { t } = useTranslation('settings');

  useEffect(() => {
    if (needParkingSpace) {
      if (isLicensePlateOptionDefault) {
        setWithError(false);
        return;
      } else if (!isLicensePlateValid) {
        setWithError(true);
        return;
      }
    }
    setWithError(false);
  }, [isLicensePlateOptionDefault, isLicensePlateValid, needParkingSpace, setWithError]);

  useEffect(() => {
    if (needParkingSpace) {
      if (defaultLicensePlate && isLicensePlateOptionDefault) {
        setLicensePlate(defaultLicensePlate);
        defaultEngineType && setEngineType(defaultEngineType);
      } else if (isLicensePlateValid) {
        setLicensePlate(newLicensePlate);
      } else {
        setLicensePlate(undefined);
        setEngineType(undefined);
      }
    }
  }, [
    defaultLicensePlate,
    defaultEngineType,
    isLicensePlateOptionDefault,
    isLicensePlateValid,
    needParkingSpace,
    newLicensePlate,
    setLicensePlate,
    setEngineType,
  ]);

  const onChangeUsePark = () => {
    toggleNeedParkingSpace();
  };

  const onChangeLicensePlate = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewLicensePlate(event.target.value.toUpperCase());
  };

  const onChangeLicensePlateOption = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLicensePlateOption(event.target.value);
  };

  const onFocusLicensePlate = () => {
    setWasFocused(true);
  };

  return <div>{defaultLicensePlate ? renderRadioGroupControl() : renderLicensePlateInput()}</div>;

  function getSharedInputProps() {
    return {
      label: t('licensePlate.inputLabel'),
      switchChecked: needParkingSpace,
      switchOnChange: onChangeUsePark,
      onChange: onChangeLicensePlate,
      onFocus: onFocusLicensePlate,
      value: newLicensePlate,
      inputProps: { maxLength: 6 },
      error: wasFocused && !!newLicensePlate && !isLicensePlateValid,
      defaultEngineType,
      setEngineType,
      selectedEngineType: reducerEngineType,
    };
  }

  function renderLicensePlateInput() {
    return (
      <LicensePlateEngineType
        labelWithSwitch
        placeholder={t('licensePlate.licensePlatePlaceholder')}
        switcherClassName={classes.switchMargins}
        mode={LicensePlateEngineTypeEnum.SHOW_AS_TEXT_FIELD}
        helperText={wasFocused && !!newLicensePlate && !isLicensePlateValid && t('licensePlate.licensePlateHelperText')}
        {...getSharedInputProps()}
      />
    );
  }

  function renderRadioGroupControl() {
    return (
      <LicensePlateEngineType
        labelWithSwitch
        placeholder={t('licensePlate.licensePlatePlaceOther')}
        mode={LicensePlateEngineTypeEnum.SHOW_AS_RADIO_GROUP}
        radioGroupOption={licensePlateOption}
        fixedOptionLabel={defaultLicensePlate}
        radioGroupOnChange={onChangeLicensePlateOption}
        helperText={
          !!newLicensePlate &&
          !isLicensePlateValid &&
          licensePlateOption === LicensePlateOptionsEnum.RADIO_VALUE_NEW &&
          t('licensePlate.licensePlateHelperText')
        }
        {...getSharedInputProps()}
      />
    );
  }
};

export type Props = {
  defaultLicensePlate?: string;
  defaultEngineType?: EngineTypeEnum;
  needParkingSpace: boolean;
  reducerLicensePlate?: string;
  reducerEngineType?: EngineTypeEnum;
  setLicensePlate: (licensePlate?: string) => void;
  setEngineType: (engineType?: EngineTypeEnum) => void;
  setWithError: (withError: boolean) => void;
  toggleNeedParkingSpace: () => void;
  withError: boolean;
};

export default LicensePlate;
