import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import sharedStyles from '../../../styles/sharedStyles/sharedStyles';
import { muiTheme, theme } from '../../../styles/theme';
import { pxToRemOrEm, withOpacity } from '../../../styles/utilities';

const shared = sharedStyles();

export default makeStyles(() =>
  createStyles({
    ...shared,

    container: {
      padding: '32px 24px',
      '&.MuiContainer-root': {
        width: 'unset',
      },

      ...shared.flexColumn,
      marginTop: muiTheme.spacing(2),
      paddingLeft: muiTheme.spacing(3),
      paddingRight: muiTheme.spacing(3),
    },

    marginTop3: {
      marginTop: muiTheme.spacing(3),
    },

    marginBottom1: {
      marginBottom: muiTheme.spacing(1),
    },

    marginBottom2: {
      marginBottom: muiTheme.spacing(2),
    },

    marginBottom4: {
      marginBottom: muiTheme.spacing(4),
    },

    marginBottom5: {
      marginBottom: muiTheme.spacing(5),
    },

    marginBottom10: {
      marginBottom: muiTheme.spacing(10),
    },

    radioGroupContainer: {
      ...shared.flexColumn,
    },

    inputLabel: {
      ...shared.flexRowJustifySpaceBetween,
      color: theme.colors.blue.main,
      // input label when focused
      '&.Mui-focused': {
        color: theme.colors.blue.main,
      },
    },

    disabled: {
      opacity: 0.2,
      pointerEvents: 'none',
      color: theme.colors.grey.main,
    },

    engineTypeLabel: {
      ...shared.flexRowJustifySpaceBetween,
      fontSize: pxToRemOrEm(14),
      fontWeight: 'bold',
      color: theme.colors.blue.main,
    },

    marginEngineTypeLabel: {
      margin: '20px 10px 10px 0px',
      [muiTheme.breakpoints.down('md')]: {
        margin: '20px 10px 0px 10px',
      },
    },

    inputGroup: {
      display: 'flex',
      flexDirection: 'row',
      gap: 8,
    },

    inputField: {
      width: 150,
      flex: 'none',
      '& .MuiOutlinedInput-input': {
        paddingBlock: 10,
        borderRadius: 6,
      },
    },

    borderBlueMainWithOp: {
      border: `1px solid ${withOpacity(theme.colors.blue.dark, 0.2)}`,
      '&:hover, &:focus': {
        border: `1px solid ${withOpacity(theme.colors.blue.dark, 0.5)}`,
      },
    },

    overlappedText: {
      marginLeft: 8,
    },

    confirmButton: {
      paddingInline: 0,
      width: theme.widthFull,
      color: theme.colors.white.main,
    },

    consentText: {
      color: theme.colors.grey.medium,
      marginTop: 0,
      marginBottom: 8,
      fontSize: 12,
    },

    showConsentButton: {
      border: 'none',
      background: 'none',
      textDecoration: 'underline',
      cursor: 'pointer',
      fontSize: 12,
      padding: 0,
      '&.Mui-focused': {
        color: theme.colors.blue.main,
      },
    },
    consentButtonLabel: {
      color: theme.colors.blue.main,
    },
  })
);
