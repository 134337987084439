import { combineReducers } from 'redux';
import appReducer, { AppState } from '../appReducer/appReducer';
import bookingReducer, { BookingState } from '../bookingReducer/bookingReducer';
import userInformationReducer, { UserInformationState } from '../userInformation/userInformationReducer';

export interface StoreState {
  app: AppState;
  booking: BookingState;
  userInformation: UserInformationState;
}

// Add the several reducers
const rootReducer = combineReducers({
  app: appReducer,
  booking: bookingReducer,
  userInformation: userInformationReducer,
});

export default rootReducer;
