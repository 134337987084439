import { Place } from '../../models/place/place';
import { PlaceTypeEnum } from '../../enums/placeType.enum';

export class PlaceUtil {
  /**
   * Method that checks if {@link Place} is of type [Team Space]{@link PlaceTypeEnum.TEAM_SPACE}.
   *
   * @param place Place to check
   * @returns true if place is team space, false otherwise
   */
  static isTeamSpace(place: Place): boolean {
    return place.type === PlaceTypeEnum.TEAM_SPACE;
  }
}
