import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import FormHelperText from '@mui/material/FormHelperText';
import './PeoplePicker.scss';
import { useGetUserDefaults } from '../../../hooks/userHooks/userHooks';
import { ReactComponent as IconWarning } from '../../../assets/svg/icon_warning.svg';

const StaticPeoplePicker: React.FC<Props> = ({ user, className = '', hasOverlappedBookings = false }) => {
  const { username } = useGetUserDefaults();
  const { t } = useTranslation('reservationWizard');

  return (
    <div className={classNames('column-row', className)}>
      <div className={classNames('people-picker-input-wrapper', 'people-picker-input-disabled')}>
        <div className={'self-person__wrapper'}>
          <div className={'self-person__content'}>
            <p className={'self-person__name'}>{user ?? username}</p>
          </div>
        </div>
      </div>
      {hasOverlappedBookings && (
        <FormHelperText error className={classNames('overlappedText')}>
          <IconWarning className={classNames('overlappedErrorIcon')} />
          {t('teamSpaceManager.userOverlappedBooking')}
        </FormHelperText>
      )}
    </div>
  );
};

interface Props {
  className?: string;
  hasOverlappedBookings?: boolean;
  user?: string | null;
}

export default StaticPeoplePicker;
