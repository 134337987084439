import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import sharedStyles from '../../../../styles/sharedStyles/sharedStyles';
import { muiTheme, theme } from '../../../../styles/theme';
import { pxToRemOrEm } from '../../../../styles/utilities';

const shared = sharedStyles();

export default makeStyles(() =>
  createStyles({
    ...shared,

    dateSliderContainer: {
      ...shared.flexRowAlignCenterJustifySpaceBetween,
      marginBottom: muiTheme.spacing(2),
      minWidth: 180,
      [muiTheme.breakpoints.up('sm')]: {
        marginTop: muiTheme.spacing(2),
      },
    },

    weekdayContainer: {
      ...shared.flexRowAlignCenterJustifyCenter,
      marginBottom: muiTheme.spacing(2),
      minWidth: 180,
      fontSize: pxToRemOrEm(16),
    },

    date: {
      fontWeight: 'bold',
      color: theme.colors.grey.main,
      fontSize: pxToRemOrEm(18),
      letterSpacing: 0,
    },

    chevron: {
      color: theme.colors.grey.main,
      height: 15,
    },

    flip: {
      transform: 'scale(-1,1)',
    },
  })
);
