import React, { useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import EditWizard from './EditWizard/EditWizard';
import MyReservations from './MyReservations';
import useTimeout from '../../../hooks/customHooks/useTimeout';
import { Booking } from '../../../models/booking/booking';
import { defaultGlowTime } from '../../../utils/constants';
import { DEFAULT_DAYS } from '../../../constants/dateFormat';
import { DateRangePickerProps } from '@wojtekmaj/react-daterange-picker';

export enum EditModes {
  default = 'edit',
  teamSpace = 'edit-team-space',
}

const MyReservationsRouter: React.FC = () => {
  const navigate = useNavigate();
  const [editedBookingId, setEditedBookingId] = useState<string | null>(null);

  const currentDay = new Date();
  const nextMonth = new Date(currentDay.getTime() + DEFAULT_DAYS);
  const [date, changeDate] = useState<DateRangePickerProps['value']>([currentDay, nextMonth]);

  /* Edit logic */
  const onEditBooking = (booking: Booking) => {
    booking.id && navigate(`${booking.id}/${EditModes.default}`);
  };

  const onEditTeamSpaceBooking = (booking: Booking) => {
    booking.id && navigate(`${booking.id}/${EditModes.teamSpace}`);
  };

  const handleClearEditedBooking = () => {
    editedBookingId && setEditedBookingId(null);
  };
  const { reset } = useTimeout(handleClearEditedBooking, defaultGlowTime);

  const handleSetEditedBookingId = (bookingId: string | null) => {
    setEditedBookingId(bookingId);
    void reset();
  };

  return (
    <Routes>
      <Route
        path='/'
        element={
          <MyReservations
            date={date}
            editedBooking={editedBookingId}
            onChangeDate={changeDate}
            onEditBooking={onEditBooking}
            onEditTeamSpaceBooking={onEditTeamSpaceBooking}
          />
        }
      />
      <Route path=':id/:mode' element={<EditWizard setEditedBooking={handleSetEditedBookingId} />} />
    </Routes>
  );
};

export default MyReservationsRouter;
