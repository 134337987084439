import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { orderBy, round, startCase } from 'lodash';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import useStyles from './Floorlist.style';
import { RoutePaths } from '../../../App/Router/RouterConstants';
import Card from '../../../Shared/Card/Card';
import { ReactComponent as IconCar } from '../../../../assets/svg/icon_car.svg';
import { ReactComponent as IconElectricalServices } from '../../../../assets/svg/icon_electrical_services_bold.svg';
import { ReactComponent as IconFamilySpace } from '../../../../assets/svg/icon_family.svg';
import { ReactComponent as IconLocalGas } from '../../../../../src/assets/svg/icon_local_gas_station_bold.svg';
import { ReactComponent as IconTeamSpace } from '../../../../assets/svg/icon_group.svg';
import { ReactComponent as IconUser } from '../../../../assets/svg/icon_user.svg';
import { EngineTypeEnum } from '../../../../enums/engineType.enum';
import { PlaceTypeEnum } from '../../../../enums/placeType.enum';
import { FloorListEnum } from '../../../../enums/settings.enum';
import { useSetSelectedPlace } from '../../../../hooks/locationHooks/locationHooks';
import { Location } from '../../../../models/location/location';
import { Parking } from '../../../../models/parking/parking';
import { Place } from '../../../../models/place/place';
import i18n from '../../../../translations/i18n';
import { GarageTypeEnum } from '../../../../enums/garageType.enum';

const FloorList: React.FC<Props> = ({ buildingStatus }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const setSelectedPlace = useSetSelectedPlace();

  void i18n.loadNamespaces(['settings', 'garages']);
  const { t } = useTranslation(['settings', 'garages']);

  const onFloorSelection = (place: Place) => () => {
    setSelectedPlace(place);

    navigate(RoutePaths.makeReservation);
  };

  const newArrayPlaces: Place[] = [];
  buildingStatus?.locations?.forEach((wings) =>
    wings?.locations?.forEach((floors) => floors?.places?.forEach((place) => newArrayPlaces.push(place)))
  );
  const orderedPlaces: Place[] = orderBy(newArrayPlaces, ['rank']);

  const orderedParkingLot: Parking[] = orderBy(buildingStatus?.parkingLots, ['priority']);

  return (
    <>
      {orderedPlaces?.map((place) => renderPlaceCard(place))}
      {orderedParkingLot?.map((parkingLot) => renderParkingCard(parkingLot))}
    </>
  );

  function renderPlaceCard(place: Place) {
    const { id, maxCapacity, currentOccupation, type } = place;
    const isDisabled = (() => {
      if (
        currentOccupation &&
        currentOccupation > 0 &&
        (type.includes(PlaceTypeEnum.TEAM_SPACE) || type.includes(PlaceTypeEnum.FAMILY_SPACE))
      ) {
        return true;
      }
      return currentOccupation === maxCapacity;
    })();

    return (
      <Card key={id} className={classes.floorCard}>
        <CardActionArea disabled={isDisabled} onClick={onFloorSelection(place)} disableRipple>
          <CardContent>
            <Grid container>{renderPlaceContent(place)}</Grid>
          </CardContent>
        </CardActionArea>
      </Card>
    );
  }

  function renderParkingCard(parking: Parking) {
    return (
      <Card key={parking.id} className={classes.parkingCard}>
        <CardActionArea disabled>
          <CardContent>
            <Grid container>{renderParkingContent(parking)}</Grid>
          </CardContent>
        </CardActionArea>
      </Card>
    );
  }

  function renderParkingContent({ maxCapacity, currentOccupation, garageType, engineType, description }: Parking) {
    const occupation = currentOccupation ? currentOccupation : 0;
    const capacity = maxCapacity ? maxCapacity : 0;
    const roundedCurrentOccupationPercentage = maxCapacity ? round((occupation / capacity) * 100) : 0;

    const currentOccupationPercentageColor = (() => {
      if (roundedCurrentOccupationPercentage < 100) {
        return classes.colorBlueRoyal;
      }

      return classes.colorRedMain;
    })();

    const occupationLabel = (() => {
      return `${roundedCurrentOccupationPercentage}%`;
    })();

    const placeTypeIcon = (() => {
      return <IconCar data-testid='IconCarSvg' />;
    })();

    return (
      <Grid container>
        <Grid item xs={4} md={4} classes={{ root: classes.parkingList }}>
          <Typography
            variant='body1'
            className={
              garageType === GarageTypeEnum.OFFICE_GARAGE ? classes.officeGarageLabel : classes.externalGarageLabel
            }
          >
            {t(description, { ns: 'garages' })}
          </Typography>
          <span>{EngineTypeEnum.COMBUSTION === engineType ? <IconLocalGas /> : <IconElectricalServices />}</span>
        </Grid>

        <Grid item xs={4} md={4} classes={{ root: classNames(classes.flexJustifyCenter, classes.alignSelfCenter) }}>
          <Typography variant='body1' className={classes.currentOccupationText}>
            {currentOccupation} / {maxCapacity}
          </Typography>

          <div className={classes.floorIcon}>{placeTypeIcon}</div>
        </Grid>
        <Grid item xs={3} md={4} classes={{ root: classes.alignSelfCenter }}>
          <Typography
            variant='body1'
            className={classNames(classes.currentOccupationPercentageText, currentOccupationPercentageColor)}
          >
            {occupationLabel}
          </Typography>
        </Grid>
      </Grid>
    );
  }

  function renderPlaceContent({ maxCapacity, currentOccupation, name, type, description, wing, floor }: Place) {
    const occupation = currentOccupation ? currentOccupation : 0;
    const capacity = maxCapacity ? maxCapacity : 0;
    const roundedCurrentOccupationPercentage = maxCapacity ? round((occupation / capacity) * 100) : 0;
    const isGroupSpace =
      type?.includes(PlaceTypeEnum.TEAM_SPACE) ||
      type?.includes(PlaceTypeEnum.FAMILY_SPACE) ||
      type?.includes(PlaceTypeEnum.TRAINING_SPACE);
    const isHotDesk = type?.includes(PlaceTypeEnum.HOT_DESKS);

    const currentOccupationPercentageColor = (() => {
      if (isGroupSpace) {
        if (roundedCurrentOccupationPercentage === 0) {
          return classes.colorBlueRoyal;
        }
        return classes.colorRedMain;
      }

      if (roundedCurrentOccupationPercentage < 100) {
        return classes.colorBlueRoyal;
      }

      return classes.colorRedMain;
    })();

    const occupationLabel = (() => {
      if (isGroupSpace) {
        return currentOccupation !== 0 ? t('floorList.occupied') : t('floorList.free');
      }

      return `${roundedCurrentOccupationPercentage}%`;
    })();

    const placeTypeIcon = (() => {
      if (PlaceTypeEnum.TEAM_SPACE === type) {
        return <IconTeamSpace data-testid='IconGroupSvg' />;
      }

      if (PlaceTypeEnum.FAMILY_SPACE === type) {
        return <IconFamilySpace data-testid='IconFamilySvg' />;
      }

      return <IconUser data-testid='IconUserSvg' />;
    })();

    const showAvailability =
      type?.includes(PlaceTypeEnum.FIXED_DESKS) ||
      type?.includes(PlaceTypeEnum.HOT_DESKS) ||
      type?.includes(EngineTypeEnum.COMBUSTION) ||
      type?.includes(EngineTypeEnum.EV_PHEV);

    const getWingName = () => {
      return !wing?.toLowerCase().includes('no') && `${startCase(String(wing).toLowerCase())} - `;
    };

    return (
      <Grid container>
        {isGroupSpace && (
          <Grid item xs={12} classes={{ root: classes.alignSelfCenter }}>
            <Typography variant='body1' className={classes.floorHeadTitle}>
              {description}
            </Typography>
          </Grid>
        )}
        {isHotDesk && (
          <Grid item xs={5} md={4} classes={{ root: classes.alignSelfCenter }}>
            <Typography variant='body1' className={classes.floorName}>
              {getWingName()}
              {floor}
            </Typography>
          </Grid>
        )}
        {isGroupSpace && (
          <Grid item xs={5} md={4} classes={{ root: classes.alignSelfCenter }}>
            <Typography variant='body1' className={classes.floorName}>
              {getWingName()}
              {name}
            </Typography>
          </Grid>
        )}
        <Grid
          item
          xs={isGroupSpace && occupationLabel === t('floorList.occupied') ? 3 : 4}
          md={4}
          classes={{ root: classNames(classes.flexJustifyCenter, classes.alignSelfCenter) }}
        >
          {showAvailability && (
            <Typography variant='body1' className={classes.currentOccupationText}>
              {currentOccupation} / {maxCapacity}
            </Typography>
          )}
          <div className={classes.floorIcon}>{placeTypeIcon}</div>
        </Grid>
        <Grid
          item
          xs={isGroupSpace && occupationLabel === t('floorList.occupied') ? 4 : 3}
          md={4}
          classes={{ root: classes.alignSelfCenter }}
        >
          <Typography
            variant='body1'
            className={classNames(classes.currentOccupationPercentageText, currentOccupationPercentageColor)}
          >
            {occupationLabel}
          </Typography>
        </Grid>
      </Grid>
    );
  }
};

export type Props = {
  buildingStatus: Location | null;
};

export default FloorList;
