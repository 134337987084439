/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import IconButton from '@mui/material/IconButton';
import useStyles from './ConsentModal.style';
import RoundButton from '../../Shared/RoundButton/RoundButton';
import { ConsentTypeEnum } from '../../../enums/consentType.enum';
import { useGetUserDefaults, useSetUserConsent } from '../../../hooks/userHooks/userHooks';
import { Consent } from '../../../models/consent/consent';
import { setConsentState, setModalState } from '../../../redux/actions/appActions/appActions';
import { getConsentState } from '../../../redux/selectors/appSelectors';
import { ReactComponent as IconClose } from '../../../../src/assets/svg/icon_close.svg';
import i18n from '../../../translations/i18n';

const ConsentModal: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { username, email } = useGetUserDefaults();
  const ctwId = email.substring(0, email.lastIndexOf('@')).toUpperCase();
  const [selectedConsent, setSelectedConsent] = useState<Consent>();
  const { createUserConsent } = useSetUserConsent(selectedConsent?.consent);
  void i18n.loadNamespaces('consentModal');
  const { t } = useTranslation('consentModal');

  useEffect(() => {
    if (selectedConsent) {
      void createUserConsent();
      dispatch(setModalState(false));
    }
  }, [selectedConsent, dispatch, createUserConsent]);

  const setConsent = (consent: ConsentTypeEnum) => {
    setSelectedConsent({ consent });
    dispatch(setConsentState(consent));
  };

  const [read, setRead] = useState(false);

  const handleScroll = (e: any) => {
    if (+e.target.offsetHeight + +e.target.scrollTop + 10 >= e.target.scrollHeight) {
      setRead(true);
    }
  };
  const consent = useSelector(getConsentState);

  return (
    <div className={classes.modal}>
      <div
        className={
          consent === ConsentTypeEnum.DISAGREE || consent === ConsentTypeEnum.UNKNOWN
            ? classes.modalContainer
            : classNames(classes.modalContainer, classes.ModalContainerWithoutButtons)
        }
      >
        <div className={classes.modalHeader}>
          <div className={classes.modalHeaderText}>
            <div className={classes.modalTitle}>{t('title')}</div>
            <div className={classes.modalSubTitle}>{t('subtitle')}</div>
          </div>
          {consent === ConsentTypeEnum.AGREE && (
            <div className={classes.modalHeaderCloseButton}>{renderCloseButton()}</div>
          )}
        </div>
        <div className={classes.modalBody} onScroll={handleScroll}>
          <p>
            <Trans components={{ 1: <strong /> }} values={{ username, ctwId }} t={t} i18nKey={'firstParagraph'} />
          </p>

          <p>
            <strong>{t('secondParagraph')}</strong>
          </p>

          <ol className={classes.alphaList}>
            <li>
              <span>{t('firstItem')}</span>
            </li>
            <li>
              <span>{t('secondItem')}</span>
            </li>
            <li>
              <span>{t('thirdItem')}</span>
            </li>
            <li>
              <span>
                {<Trans components={{ 1: <a href={`mailto:${t('privacyEmail')}`} /> }} t={t} i18nKey={'fourthItem'} />}
              </span>
            </li>
            <li>
              <span>{t('fifthItem')}</span>
            </li>
            <li>
              <span>
                <Trans
                  components={{ 1: <a href={`mailto:${t('privacyEmail')}`} />, 2: <a href={t('cnpdWebsite')} /> }}
                  t={t}
                  i18nKey={'sixthItem'}
                />
              </span>
            </li>
          </ol>
          <p>{t('agreementParagraph')}</p>
        </div>
        <div className={classes.modalFooter}>
          {consent === ConsentTypeEnum.DISAGREE || consent === ConsentTypeEnum.UNKNOWN ? renderButtons() : ''}
        </div>
      </div>
    </div>
  );

  function renderButtons() {
    return (
      <div className={classes.buttonsPosition}>
        <RoundButton
          capitalizeText
          onClick={() => setConsent(ConsentTypeEnum.DISAGREE)}
          className={classNames(classes.consentButtons, classes.disagreeButton)}
          disabled={!read}
        >
          {t('disagree')}
        </RoundButton>
        <RoundButton
          capitalizeText
          onClick={() => setConsent(ConsentTypeEnum.AGREE)}
          className={classNames(classes.consentButtons, classes.agreeButton)}
          disabled={!read}
        >
          {t('agree')}
        </RoundButton>
      </div>
    );
  }

  function renderCloseButton() {
    return (
      <IconButton className={classes.modalCloseButton} onClick={() => dispatch(setModalState(false))} size='large'>
        <IconClose />
      </IconButton>
    );
  }
};

export default ConsentModal;
