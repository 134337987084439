import React, { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useParams } from 'react-router-dom';
import BookingEditor from './BookingEditor/BookingEditor';
import TeamSpaceBookingEditor from './TeamSpaceBookingEditor/TeamSpaceBookingEditor';
import { EditModes } from '../MyReservationsRouter';
import { ReservationContext } from '../../CreateReservation/ReservationWizard/ReservationContext/ReservationContext';
import {
  reservationReducer,
  setNeedParkingSpace,
} from '../../CreateReservation/ReservationWizard/ReservationContext/ReservationReducer';
import { initialReservation } from '../../CreateReservation/ReservationWizard/ReservationContext/ReservationState';
import { StepProps } from '../../CreateReservation/ReservationWizard/Step/Step';
import { RoutePaths } from '../../../App/Router/RouterConstants';
import Wizard from '../../../Shared/Wizard/Wizard';
import { ConsentTypeEnum } from '../../../../enums/consentType.enum';
import { setModalState } from '../../../../redux/actions/appActions/appActions';
import { getConsentState } from '../../../../redux/selectors/appSelectors';
import i18n from '../../../../translations/i18n';

const EditWizard: React.FC<Props> = ({ setEditedBooking }) => {
  const { id: bookingId, mode: editMode } = useParams<keyof Params>() as Params;
  const stepLength = 1;

  const reduxDispatch = useDispatch();
  const consent = useSelector(getConsentState);

  void i18n.loadNamespaces('editWizard');

  const [state, dispatch] = useReducer(reservationReducer, initialReservation);
  const providerValue = useMemo(() => ({ state, dispatch }), [state]);

  const { needParkingSpace } = state;

  const [hasUserClickedToggle, setHasUserClickedToggle] = useState(false);

  /* Enable Need Parking Place if Consent Agree And user has clicked toggle */
  useEffect(() => {
    hasUserClickedToggle && dispatch(setNeedParkingSpace(consent === ConsentTypeEnum.AGREE && hasUserClickedToggle));
  }, [consent, dispatch, hasUserClickedToggle]);

  const toggleNeedParkingSpace = useCallback(() => {
    setHasUserClickedToggle(true);
    if (consent === ConsentTypeEnum.AGREE) {
      dispatch(setNeedParkingSpace(!needParkingSpace));
    } else {
      reduxDispatch(setModalState(true));
    }
  }, [consent, dispatch, reduxDispatch, needParkingSpace]);

  const stepProps = {
    stepLength,
  };

  return (
    <ReservationContext.Provider value={providerValue}>
      <Helmet>
        <title>Wally App | Edit Reservation</title>
      </Helmet>
      <Wizard stepLength={stepLength}>
        {({ onNextStep, onPreviousStep, step }) => {
          const editProps = {
            ...stepProps,
            step,
            onNextStep,
            onPreviousStep,
            bookingId,
            toggleNeedParkingSpace,
            setEditedBooking,
          };
          switch (editMode) {
            case EditModes.teamSpace:
              return <TeamSpaceBookingEditor {...editProps} />;
            case EditModes.default:
              return <BookingEditor {...editProps} />;
            default:
              return <Navigate to={RoutePaths.myReservations} />;
          }
        }}
      </Wizard>
    </ReservationContext.Provider>
  );
};

interface Params {
  id: string;
  mode: EditModes;
}

interface Props {
  setEditedBooking: (bookingId: string | null) => void;
}

export interface EditBookingProps extends StepProps {
  bookingId: string;
  toggleNeedParkingSpace: () => void;
  setEditedBooking: (bookingId: string | null) => void;
}

export default EditWizard;
