import React from 'react';
import { useTranslation } from 'react-i18next';
import { orderBy } from 'lodash';
import classNames from 'classnames';
import { ClassNameMap } from '@mui/styles';
import useStyles from './ReservationCardContent.style';
import Tooltip from '../../../Shared/Tooltip/Tooltip';
import { EngineTypeEnum } from '../../../../enums/engineType.enum';
import { PlaceTypeEnum } from '../../../../enums/placeType.enum';
import { TooltipEnum } from '../../../../enums/tooltip.enum';
import { Parking } from '../../../../models/parking/parking';
import { Place } from '../../../../models/place/place';
import { PlaceAndParkingStatus } from '../../../../models/placeAndParkingStatus/placeAndParkingStatus';
import Formatters from '../../../../services/Formatters/Formatters';
import { ParkingLotsUtil } from '../../../../utils/parkingLots/parking-lots-util';
import { ReactComponent as IconElectricalServices } from '../../../../../src/assets/svg/electrical_services_black_24dp.svg';
import { ReactComponent as IconLocalGas } from '../../../../../src/assets/svg/local_gas_station_black_24dp.svg';
import LoadingSpinner from '../../../Shared/Loading/LoadingSpinner';
import i18n from '../../../../translations/i18n';
import { GarageTypeEnum } from '../../../../enums/garageType.enum';

let classes: ClassNameMap;

const ReservationCardContent: React.FC<Props> = ({
  date,
  floor,
  isBuildingFull,
  place,
  wing,
  placeAndParkingStatus,
  loading,
  showIfIsUnavailable,
  ...rest
}) => {
  const orderedParkingLot: Parking[] = orderBy(placeAndParkingStatus?.parkingLots, ['priority']);
  const officeParkingLots = getParkingLotsFrom(orderedParkingLot, GarageTypeEnum.OFFICE_GARAGE);
  const externalParkingLots = getParkingLotsFrom(orderedParkingLot, GarageTypeEnum.EXTERNAL_GARAGE);
  const isAllParkingLotsFull = ParkingLotsUtil.isParkingLotsFull(orderedParkingLot, date) || false;
  const isPlaceFull =
    (placeAndParkingStatus &&
      placeAndParkingStatus?.place?.currentOccupation === placeAndParkingStatus?.place?.maxCapacity) ||
    false;
  const isUnavailable = isPlaceFull || false;
  const isTeamSpace = placeAndParkingStatus?.place?.type?.includes(PlaceTypeEnum.TEAM_SPACE);
  const isTeamSpaceFull =
    (isTeamSpace &&
      showIfIsUnavailable &&
      placeAndParkingStatus?.place?.currentOccupation &&
      placeAndParkingStatus?.place?.currentOccupation > 0) ||
    false;

  // TODO: delete the line below when we create wings type and rename the place names - US CTWTETRIS-1938
  const isPlaceHotOrFixedDesks = place?.type === PlaceTypeEnum.HOT_DESKS || place?.type === PlaceTypeEnum.FIXED_DESKS;
  classes = useStyles();
  void i18n.loadNamespaces('reservation');
  const { t } = useTranslation('reservation');

  const renderParkingStatus = (parking: Parking | undefined, date?: string, isExternalGarage = false) => {
    const isParkingFull = (parking?.currentOccupation || 0) >= (parking?.maxCapacity || 0);
    const isPresentDay = date?.includes(Formatters.formatDateISOYearMonthDay(new Date()));
    const isCombustionParking = parking?.engineType === EngineTypeEnum.COMBUSTION;
    const isUnavailable = (isPresentDay && isExternalGarage) || isParkingFull;

    return (
      <div className={classes.parkingStatus} key={parking?.id}>
        {parking?.engineType && (
          <Tooltip
            className={classes.parkingTooltip}
            content={t(`reservationCardContent.${parking?.engineType}`)}
            direction={TooltipEnum.TOP}
          >
            <span className={classes.parkingIcon}>
              {isCombustionParking ? <IconLocalGas /> : <IconElectricalServices />}
            </span>
          </Tooltip>
        )}
        <span className={classNames(isUnavailable && classes.colorRedMain)}>
          {isUnavailable ? t('reservationCardContent.unavailable') : t('reservationCardContent.available')}
        </span>
      </div>
    );
  };

  return (
    <main
      className={classNames(
        !loading ? classes.main : classes.mainLoading,
        isAllParkingLotsFull && classes.mainResizeWithUnavailable
      )}
      {...rest}
    >
      {loading ? (
        <div className={classes.loadingSpinnerContainer}>
          <LoadingSpinner />
        </div>
      ) : (
        <>
          <section>
            <h1 className={classNames(classes.title, classes.marginTop)}>
              {t('reservationCardContent.spaceOccupation')}
            </h1>
            <div
              className={classNames(
                classes.content,
                classes.colorBlueRoyal,
                isTeamSpace && !isTeamSpaceFull && classes.colorBlueDark,
                isUnavailable && classes.colorRedMain,
                isTeamSpaceFull && classes.colorRedMain
              )}
            >
              <p>
                {wing
                  ? t('reservationCardContent.buildingAndWing', {
                      buildingName: placeAndParkingStatus?.building?.name,
                      wing,
                    })
                  : t('reservationCardContent.building', {
                      buildingName: placeAndParkingStatus?.building?.name,
                    })}
              </p>
              <p>
                {floor &&
                  t('reservationCardContent.floorAndPlace', {
                    floorName: floor,
                    placeName: place?.name,
                  })}
                {!isPlaceHotOrFixedDesks && isTeamSpaceFull && t('reservationCardContent.unavailableWithHyphen')}
                {!isPlaceHotOrFixedDesks &&
                  !isTeamSpaceFull &&
                  place?.maxCapacity &&
                  t('reservationCardContent.teamSpaceOccupation', {
                    placeCurrentOccupation: placeAndParkingStatus?.place?.currentOccupation || 0,
                    placeMaxCapacity: placeAndParkingStatus?.place?.maxCapacity || 0,
                  })}
                {isUnavailable && t('reservationCardContent.unavailableWithHyphen')}
              </p>
            </div>
          </section>
          <section>
            <h1 className={classes.title}>{t('reservationCardContent.parkingSpace')}</h1>
            <div className={classes.content}>
              {!!officeParkingLots?.length && (
                <div className={classes.parkingStatusContainer}>
                  {/* office garage */}
                  <span className={classes.garageLabel}>{t('reservationCardContent.OFFICE_GARAGE')}</span>
                  {officeParkingLots?.map((parking: Parking) => renderParkingStatus(parking))}
                </div>
              )}
              {!!externalParkingLots?.length && (
                <div className={classes.parkingStatusContainer}>
                  {/* external garage */}
                  <span className={classNames(classes.garageLabel, classes.garageLabelExt)}>
                    {t('reservationCardContent.EXTERNAL_GARAGE')}
                  </span>
                  {externalParkingLots?.map((parking: Parking) => renderParkingStatus(parking, date, true))}
                </div>
              )}
            </div>
          </section>
        </>
      )}
    </main>
  );
};

const getParkingLotsFrom = (parkingLots: Parking[] | undefined, parkingName: string) => {
  return parkingLots
    ?.filter((p: Parking) => p.garageType === parkingName)
    .sort((a, b) => (a.engineType < b.engineType ? 1 : -1));
};

export type Props = {
  date?: string;
  floor?: string;
  isBuildingFull?: (isFull: boolean) => void;
  place?: Place;
  wing?: string;
  placeAndParkingStatus?: PlaceAndParkingStatus;
  loading?: boolean;
  showIfIsUnavailable?: boolean;
};

export default ReservationCardContent;
