import makeStyles from '@mui/styles/makeStyles';
import { theme } from '../../../styles/theme';
import sharedStyles from '../../../styles/sharedStyles/sharedStyles';

const shared = sharedStyles();
const iconsContainer = {
  backgroundColor: 'transparent',
  border: 0,
  cursor: 'pointer',
  height: 24,
  right: 16,
  width: 24,
};

interface StyleProps {
  maxWidth?: string | number;
  minWidth?: string | number;
}

export default makeStyles({
  ...shared,

  root: {
    maxWidth: (props?: StyleProps) => props?.maxWidth || 'auto',
    minWidth: (props?: StyleProps) => props?.minWidth || 'auto',
    width: '100%',
    transition: 'all 300ms ease-in-out',
    fontFamily: 'Roboto Condensed',
  },

  mainContent: {
    ...shared.flexRow,
    position: 'relative',
  },

  threeDotMenuContainer: {
    position: 'absolute',
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: 8,
    top: 0,
    right: 0,
  },

  deleteButtonContainer: {
    ...iconsContainer,
    position: 'absolute',
    display: 'flex',
    alignItems: 'flex-start',
    top: 16,
    '& svg': {
      width: 16,
      height: 16,
    },
  },

  showMoreContainer: {
    ...iconsContainer,
    height: 16,
    bottom: 16,
    position: 'absolute',
  },

  backgroundRedColor: {
    backgroundColor: theme.colors.red.main,
  },

  showMoreIcon: {
    ...shared.colorBlueMain,
    height: '100%',
    transform: 'rotate(-90deg)',
    width: '100%',
  },

  deleteButtonIcon: {
    height: '100%',
    width: '100%',
  },

  showMoreIconInverted: {
    transform: 'rotate(90deg)',
  },
});
