import React from 'react';
import classNames from 'classnames';
import CalendarComp, { CalendarProps } from 'react-calendar';
import { ReactComponent as IconRight } from '../../../assets/svg/icon_arrow_right.svg';
import { ReactComponent as IconLeft } from '../../../assets/svg/icon_arrow_left.svg';
import '../../../sass/shared/Calendar.scss';

interface Props extends CalendarProps {
  styles?: string;
}

const Calendar: React.FC<Props> = (props) => (
  <CalendarComp
    className={classNames('calendar-picker', props.styles)}
    locale={'en'}
    nextLabel={<IconRight />}
    prevLabel={<IconLeft />}
    {...props}
  />
);

export default Calendar;
