import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import sharedStyles from '../../../../../styles/sharedStyles/sharedStyles';
import { muiTheme, theme } from '../../../../../styles/theme';
import { pxToRemOrEm } from '../../../../../styles/utilities';

const shared = sharedStyles();

const clearButtonSize = '12px';

const iconsContainer = {
  ...shared.flex,
  backgroundColor: 'transparent',
  border: 0,
  cursor: 'pointer',
  height: 24,
  right: 16,
  justifyContent: 'center',
  alignItems: 'center',
  padding: 0,
};

const iconSize = '16px';

export default makeStyles(() =>
  createStyles({
    ...shared,

    toggleLabel: {
      paddingTop: '2rem',
      marginBottom: 8,
    },

    toggle: {
      marginLeft: '1rem',
    },

    guestsWrapper: {
      display: 'grid',
      rowGap: '1rem',
      gridAutoRows: 'min-content',
      marginTop: muiTheme.spacing(2),
      alignItems: 'center',
      gridTemplateColumns: 'auto 1fr',
    },

    guestsWrapperGap: {
      columnGap: '1rem',
    },

    deleteButtonContainer: {
      ...iconsContainer,
      display: 'flex',
      gridColumn: 1,
      alignItems: 'center',
      top: '16px',
      '& svg': {
        width: iconSize,
        height: iconSize,
      },
    },

    guestInputField: {
      gridColumn: 2,
      width: '100%',
      '& .MuiOutlinedInput-root': {
        borderRadius: 6,
      },
      '& .MuiOutlinedInput-input': {
        color: theme.colors.grey.main,
        padding: 12,
        minWidth: 200,
        [muiTheme.breakpoints.only('xs')]: {
          minWidth: 'unset',
        },
        '&.Mui-disabled': {
          color: theme.colors.grey.light,
        },
      },
      '& .MuiFormHelperText-contained': {
        margin: 0,
        position: 'absolute',
        bottom: -24,
        [muiTheme.breakpoints.only('xs')]: {
          bottom: -42,
        },
      },
      '& .Mui-disabled .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.colors.grey.light,
      },
      '& svg': {
        width: clearButtonSize,
        height: clearButtonSize,
      },
    },

    inputMarginBottom: {
      marginBottom: '1.25rem',
    },

    addGuestButtonContainer: {
      ...iconsContainer,
      fontSize: pxToRemOrEm(16),
      gridColumn: 2,
      justifyContent: 'flex-start',
      color: theme.colors.blue.dark,

      [muiTheme.breakpoints.down('md')]: {
        '&:hover:not(:disabled)': {
          textDecoration: 'none !important',
        },
      },
      '&:hover:not(:disabled)': {
        textDecoration: 'underline',
      },
      '&:disabled': {
        color: theme.colors.grey.dark,
        cursor: 'default',
      },
    },
  })
);
