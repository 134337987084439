import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { stepInfo, stepTitle, textContent } from '../../../../../styles/reservation';
import sharedStyles from '../../../../../styles/sharedStyles/sharedStyles';
import { theme } from '../../../../../styles/theme';

const shared = sharedStyles();

export default makeStyles(() =>
  createStyles({
    ...shared,
    ...stepInfo,
    ...stepTitle,
    ...textContent,

    titlePaddingLeft: {
      paddingLeft: 10,
    },

    licensePlateWrapper: {
      width: theme.widthFull,
    },

    calendarPicker: {
      marginBottom: 32,
    },
  })
);
