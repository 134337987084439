import makeStyles from '@mui/styles/makeStyles';

export default makeStyles({
  root: {
    height: 0,
    overflow: 'hidden',
    transitionDuration: '.5s',
    transitionProperty: 'height',
  },
});
