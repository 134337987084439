import React, { createContext } from 'react';
import { ReservationActions } from './ReservationReducer';
import { initialReservation, ReservationState } from './ReservationState';

export interface IReservationContext {
  state: ReservationState;
  dispatch: React.Dispatch<ReservationActions>;
}

export const ReservationContext = createContext<IReservationContext>({
  state: initialReservation,
  dispatch: () => undefined,
});

ReservationContext.displayName = 'ReservationContext';
