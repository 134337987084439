import { EngineTypeEnum } from '../../../../../enums/engineType.enum';
import { Guest } from '../../../../../models/guest/guest';
import { ReservationError } from '../../../../../models/parkingReservation/parkingReservation';
import { Place } from '../../../../../models/place/place';
import { TeamSpaceMember } from '../../../../../models/teamSpaceMember/team-space-member';

export interface ReservationState {
  canContinue: boolean;
  date: string[];
  engineType: EngineTypeEnum | undefined;
  existingTeamSpaceMembers: Pick<TeamSpaceMember, 'person' | 'hasParkingInformation' | 'needParkingSpace'>[];
  guests: Guest[];
  licensePlate: string | undefined;
  needGuests: boolean;
  needParkingSpace: boolean;
  place: Place | undefined;
  reservationError: ReservationError[];
  teamSpaceMembers: Pick<TeamSpaceMember, 'index' | 'person'>[];
  teamSpaceMembersParking: Pick<TeamSpaceMember, 'index' | 'needParkingSpace'>[];
}

export const initialReservation: ReservationState = {
  canContinue: true,
  date: [],
  engineType: undefined,
  existingTeamSpaceMembers: [],
  guests: [] as Guest[],
  licensePlate: undefined,
  needGuests: false,
  needParkingSpace: false,
  place: undefined,
  reservationError: [],
  teamSpaceMembers: [],
  teamSpaceMembersParking: [],
};

export const getInitialReservation = (props: Partial<ReservationState> = {}): ReservationState => {
  return { ...initialReservation, ...props };
};
