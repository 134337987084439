import React from 'react';
import { MgtTemplateProps } from '@microsoft/mgt-react';

const SelectedPerson: React.FC<MgtTemplateProps> = (mstTemplateProps) => {
  const { person } = mstTemplateProps.dataContext;
  return (
    <div>
      <p className='chip'>{person.displayName}</p>
    </div>
  );
};

export default SelectedPerson;
