import classNames from 'classnames';
import React from 'react';
import { ReactComponent as Spinner } from '../../../assets/svg/loading_spinner.svg';
import { LoadingSpinnerEnum } from '../../../enums/loadingSpinner.enum';
import useStyles from './LoadingSpinner.style';

const LoadingSpinner: React.FC<Props> = ({ color }) => {
  const classes = useStyles();
  return (
    <div
      data-testid={'spinner-svg'}
      className={classNames(classes.spinner, color ?? LoadingSpinnerEnum.BLUE_ROYAL_COLOR)}
    >
      <Spinner />
    </div>
  );
};

export type Props = {
  color?: LoadingSpinnerEnum.BLUE_ROYAL_COLOR | LoadingSpinnerEnum.WHITE_COLOR;
};

export default LoadingSpinner;
