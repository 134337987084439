import createStyles from '@mui/styles/createStyles';
import sharedStyles from './sharedStyles/sharedStyles';
import { theme } from './theme';
import { pxToRemOrEm } from './utilities';

const shared = sharedStyles();

export const reservation = {
  calendarSelectedRangeColor: 'rgba(0, 0, 120, 0.35)',
  containerPadding: 24,
  containerWidth: 1600,
  leftColumnMaxWidth: 450,
  marginSpacer: 32,
  rightColumnMaxWidth: 520,
  textMarginTop: 24,
};

export const stepInfo = createStyles({
  stepInfo: {
    marginBottom: reservation.marginSpacer,
    width: theme.widthFull,
    position: 'sticky',
    top: 0,
    zIndex: 2,
    backgroundColor: theme.colors.white.main,
  },
});

export const stepTitle = createStyles({
  stepTitle: {
    color: theme.colors.grey.main,
    fontWeight: theme.fontWeights.bold,
    fontSize: pxToRemOrEm(20),
  },
});

export const textContent = createStyles({
  textContent: {
    ...shared.flexRowJustifyFlexStart,
    color: theme.colors.grey.medium,
    marginTop: reservation.textMarginTop,
  },
});
