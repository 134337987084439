import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import sharedStyles from '../../../../../styles/sharedStyles/sharedStyles';
import { muiTheme, theme } from '../../../../../styles/theme';
import { reservation } from '../../../../../styles/reservation';

const shared = sharedStyles();

export default makeStyles(() =>
  createStyles({
    ...shared,

    buttonsWrapper: {
      ...shared.gap24,
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      margin: 0,
      height: theme.heightFull,
      [muiTheme.breakpoints.up('md')]: {
        ...shared.gap144,
      },
    },

    stickyWrapper: {
      backgroundColor: theme.colors.white.main,
      bottom: 0,
      boxShadow: `0px 8px 24px 0px ${theme.colors.grey.medium}`,
      flex: 'none',
      height: 88,
      paddingLeft: reservation.containerPadding,
      paddingRight: reservation.containerPadding,
      position: 'sticky',
      width: theme.widthFull,
      zIndex: 1,
    },

    loadingSpinnerContainer: {
      ...shared.flexRowAlignCenterJustifyCenter,
      marginBlock: 3,
      height: 24,
      width: 24,
      [muiTheme.breakpoints.up('sm')]: {
        marginBlock: 4,
        marginInline: 17,
      },
      '& .spinnerWhiteColor': {
        height: 24,
        width: 24,
      },
    },
  })
);
