import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { EditBookingProps } from '../EditWizard';
import LicensePlate from '../../../CreateReservation/LicensePlate/LicensePlate';
import {
  setCanContinue,
  setDate,
  setLicensePlate,
  setNeedParkingSpace,
  setEngineType,
  setPlace,
} from '../../../CreateReservation/ReservationWizard/ReservationContext/ReservationReducer';
import { ReservationContext } from '../../../CreateReservation/ReservationWizard/ReservationContext/ReservationContext';
import Step from '../../../CreateReservation/ReservationWizard/Step/Step';
import { RoutePaths } from '../../../../App/Router/RouterConstants';
import Loading from '../../../../Shared/Loading/Loading';
import { BookingRequestAssembler } from '../../../../../assemblers/booking/booking-request-assembler';
import { EngineTypeEnum } from '../../../../../enums/engineType.enum';
import { useEditBooking, useGetBookingById } from '../../../../../hooks/bookingHooks/bookingHooks';
import { useGetUserDefaults } from '../../../../../hooks/userHooks/userHooks';

const BookingEditor: React.FC<EditBookingProps> = ({
  bookingId,
  toggleNeedParkingSpace,
  stepLength,
  step,
  onNextStep,
  onPreviousStep,
  setEditedBooking,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation('editWizard');
  const { enqueueSnackbar } = useSnackbar();
  const { booking, bookingLoading, bookingError } = useGetBookingById(bookingId);
  const { licensePlate: defaultLicensePlate, engineType: defaultEngineType } = useGetUserDefaults();
  const { state, dispatch } = useContext(ReservationContext);
  const { needParkingSpace, licensePlate, engineType, place } = state;

  const [withError, setWithError] = useState<boolean>(false);

  useEffect(() => {
    if (booking) {
      booking.place && dispatch(setPlace(booking.place));
      booking.date && dispatch(setDate([booking.date]));
      dispatch(setNeedParkingSpace(!!booking.parking));
      booking.user?.licensePlate && dispatch(setLicensePlate(booking.user.licensePlate));
      booking.parking?.engineType && dispatch(setEngineType(booking.parking?.engineType));
    }
  }, [booking, dispatch]);

  const onCancel = () => {
    setEditedBooking(null);
    navigate(RoutePaths.myReservations);
  };

  const bookingRequest = BookingRequestAssembler.fromBooking({
    id: bookingId,
    licensePlate: needParkingSpace ? licensePlate : undefined,
    engineType: needParkingSpace ? engineType : undefined,
  });

  const {
    editBooking,
    response: bookingResponse,
    loading: bookingResponseLoading,
    error: bookingResponseError,
  } = useEditBooking(bookingRequest);

  const onConfirm = () => {
    setEditedBooking(bookingId);
    void editBooking();
  };

  useEffect(() => {
    bookingResponse && navigate(RoutePaths.myReservations);
    bookingResponse &&
      enqueueSnackbar(t('toastSuccess'), {
        variant: 'success',
      });
  }, [booking, bookingResponse, enqueueSnackbar, navigate, t]);

  useEffect(() => {
    if (bookingResponseError) {
      enqueueSnackbar(bookingResponseError, {
        variant: 'error',
        persist: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingResponseError, enqueueSnackbar]);

  useEffect(() => {
    bookingError && navigate(RoutePaths.myReservations);
  }, [bookingError, navigate]);

  const disabledConditions = useMemo(
    () =>
      (needParkingSpace && !state.engineType) ||
      (needParkingSpace && !state.licensePlate) ||
      (needParkingSpace && withError),
    [needParkingSpace, state.licensePlate, state.engineType, withError]
  );

  useEffect(() => {
    dispatch(setCanContinue(!disabledConditions));
  }, [disabledConditions, dispatch]);

  const setReducerEngineType = useCallback(
    (type: EngineTypeEnum | undefined) => {
      dispatch(setEngineType(type));
    },
    [dispatch]
  );

  const setReducerLicensePlate = useCallback(
    (plate: string | undefined) => {
      dispatch(setLicensePlate(plate));
    },
    [dispatch]
  );

  const renderLoading = () => {
    return <Loading />;
  };

  const renderEdit = () => {
    const stepProps = {
      bookingResponseLoading,
      onCancel,
      onConfirm,
      stepLength,
      step,
      onNextStep,
      onPreviousStep,
    };

    // it needs to wait for the dispatched information, so component cannot be loaded previously
    return place ? (
      <Step {...stepProps} stepTitle={t('title')} editMode>
        <LicensePlate
          needParkingSpace={needParkingSpace}
          setLicensePlate={setReducerLicensePlate}
          setEngineType={setReducerEngineType}
          setWithError={setWithError}
          toggleNeedParkingSpace={toggleNeedParkingSpace}
          withError={withError}
          defaultLicensePlate={defaultLicensePlate}
          defaultEngineType={defaultEngineType}
          reducerLicensePlate={licensePlate}
          reducerEngineType={engineType}
        />
      </Step>
    ) : (
      renderLoading()
    );
  };

  return bookingLoading ? renderLoading() : renderEdit();
};

export default BookingEditor;
