import classNames from 'classnames';
import Formatters from '../../../../services/Formatters/Formatters';
import React from 'react';
import useStyles from './ReservationCardDate.style';

const ReservationCardDate: React.FC<Props> = ({
  colorsInverted,
  date,
  isUnavailable,
  isTeamSpace,
  isTeamSpaceFull,
  teamSpacetag,
}) => {
  const { day, weekday, month, year } = Formatters.formatDayMonthYearDate(date);
  const classes = useStyles();

  return (
    <aside
      className={classNames(
        classes.aside,
        colorsInverted && classes.backgroundGreyColor, // if card is open, the background will be grey
        !colorsInverted && isUnavailable && classes.backgroundRedColor, // if card is close & unavailable, the background will be red
        !colorsInverted && isTeamSpaceFull && classes.backgroundRedColor, // if card is close & team space & unavailable, the background will be red
        !colorsInverted && !isUnavailable && isTeamSpace && !isTeamSpaceFull && classes.backgroundDarkBlue // if card is close & team space, the background will be dark blue
      )}
    >
      {/* TAG */}
      {isTeamSpace && (
        <div className={classNames(classes.tagContainer, isTeamSpace && classes.backgroundTagBlue)}>
          <p>{isTeamSpace ? 'TEAM SPACE' : teamSpacetag}</p>
        </div>
      )}
      <div
        className={classNames(
          classes.flexColumnAlignCenter,
          classes.colorWhiteMain, // the card text color will be white by default
          colorsInverted && classes.colorBlueRoyal, // if card is open, the color will be blue royal
          colorsInverted && isUnavailable && classes.colorRedMain, // if card is open & unavailable, the color will be red
          colorsInverted && isTeamSpaceFull && classes.colorRedMain, // if card is open & team space & unavailable, the color will be red
          colorsInverted && !isUnavailable && isTeamSpace && !isTeamSpaceFull && classes.colorBlueDark // if card is open & team space, the color will be dark blue
        )}
      >
        <div className={classNames(classes.flexRowAlignCenter, classes.gap4)}>
          <h1 className={classes.dayOfWeek}>{day}</h1>
          <div className={classes.monthAndYear}>
            <span>{month}</span>
            <span>{year}</span>
          </div>
        </div>
        <span className={classes.extendedDayOfWeek}>{weekday}</span>
      </div>
    </aside>
  );
};

export type Props = {
  colorsInverted?: boolean; // If `true`, the component colors will transition in order to invert.
  date?: string; // Date to convert in object and show date more detailed.
  isUnavailable?: boolean; // If `true`, the date container background will be red
  isTeamSpace?: boolean; // If `true`, the date container background will be dark blue
  isTeamSpaceFull?: boolean; // If `true`, the date container background will be red
  teamSpacetag?: string; // Tag for team space
};

export default ReservationCardDate;
