import { IBookingReportResponse } from '../../interfaces/bookingReport/booking-report-response';
import { BookingReport } from '../../models/bookingReport/bookingReport';

export class BookingReportAssembler {
  static from(bookingReportResponse: IBookingReportResponse): BookingReport {
    return {
      email: bookingReportResponse.email,
      username: bookingReportResponse.username,
      office: bookingReportResponse.office,
      floor: bookingReportResponse.floor,
      date: bookingReportResponse.date,
      reason: bookingReportResponse.reason,
      licensePlate: bookingReportResponse.licensePlate,
      garage: bookingReportResponse.garageType,
      engineType: bookingReportResponse.engineType,
    };
  }

  static fromList(bookingReportResponseList: IBookingReportResponse[]): BookingReport[] {
    return bookingReportResponseList?.map((bookingReportResponse) => this.from(bookingReportResponse));
  }
}
