import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import sharedStyles from '../../../styles/sharedStyles/sharedStyles';
import { muiTheme, theme } from '../../../styles/theme';
import { withOpacity } from '../../../styles/utilities';

const shared = sharedStyles();

export default makeStyles(() =>
  createStyles({
    ...shared,

    marginTop: {
      marginTop: muiTheme.spacing(5),
    },

    dateRangePicker: {
      display: 'flex',
      justifyContent: 'center',
    },

    textContent: {
      color: theme.colors.grey.medium,
    },

    container: {
      padding: '32px 24px',
      [muiTheme.breakpoints.down('md')]: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      },
    },

    reservationCard: {
      display: 'flex',
      flexDirection: 'column',
    },

    glow: {
      boxShadow: `0px 0px 12px 0px ${withOpacity(theme.colors.blue.royal, 0.5)}`,
    },

    // Modal Styles
    modalBodyContainer: {
      display: 'flex',
      flexDirection: 'column',
      height: theme.heightFull,
      justifyContent: 'center',
      paddingBlock: 28,
    },

    modalBodyMarginReset: {
      '& > *': {
        margin: 0,
      },
    },

    modalBodyHeading: {
      color: theme.colors.grey.main,
      fontSize: 20,
      fontWeight: 'bold',
      lineHeight: '30px',
      marginTop: 10,
    },

    modalBodyText: {
      paddingTop: 16,
    },

    modalBodySpan: {
      fontWeight: 'bold',
    },

    warning: {
      color: theme.colors.orange.main,
    },

    warningIcon: {
      flexShrink: 0,
      height: 27,
      width: 27,
      marginTop: 2,
      marginRight: muiTheme.spacing(1),
    },
  })
);
