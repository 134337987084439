import React from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import useStyles from './ThreeDotMenu.style';
import classNames from 'classnames';

export const ThreeDotMenu: React.FC<Props> = ({ menuOptions, className }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={className}>
      <IconButton
        aria-label='more'
        aria-controls='long-menu'
        aria-haspopup='true'
        onClick={handleClick}
        classes={{
          root: classNames(classes.buttonRoot),
        }}
        size='large'
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id='long-menu'
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        elevation={0}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        classes={{
          paper: classNames(classes.menuRoot),
        }}
      >
        {menuOptions.map((menuOption) => (
          <MenuItem
            key={menuOption.title}
            onClick={menuOption.handleAction}
            onMouseUp={handleClose}
            classes={{
              root: classNames(classes.menuItem),
            }}
          >
            {menuOption.title}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export type MenuOption = {
  title: string;
  handleAction: (() => void) | undefined;
};

interface Props {
  menuOptions: MenuOption[];
  className?: string;
}

export default ThreeDotMenu;
