/* eslint-disable @typescript-eslint/no-redeclare */
export const SET_LICENSE_PLATE = 'SET_LICENSE_PLATE';
export type SET_LICENSE_PLATE = typeof SET_LICENSE_PLATE;

export const SELECT_BUILDING_SUCCESS = 'SELECT_BUILDING_SUCCESS';
export type SELECT_BUILDING_SUCCESS = typeof SELECT_BUILDING_SUCCESS;

export const SELECT_FLOOR_SUCCESS = 'SELECT_FLOOR_SUCCESS';
export type SELECT_FLOOR_SUCCESS = typeof SELECT_FLOOR_SUCCESS;

export const SELECT_PLACE_SUCCESS = 'SELECT_PLACE_SUCCESS';
export type SELECT_PLACE_SUCCESS = typeof SELECT_PLACE_SUCCESS;

export const SELECT_DATE_SUCCESS = 'SELECT_DATE_SUCCESS';
export type SELECT_DATE_SUCCESS = typeof SELECT_DATE_SUCCESS;

export const SELECT_PARKING_SPACE = 'SELECT_PARKING_SPACE';
export type SELECT_PARKING_SPACE = typeof SELECT_PARKING_SPACE;

export const SET_EMAIL = 'SET_EMAIL';
export type SET_EMAIL = typeof SET_EMAIL;

export const SET_USERNAME = 'SET_USERNAME';
export type SET_USERNAME = typeof SET_USERNAME;

export const SET_CONSENT_STATE = 'SET_CONSENT_STATE';
export type SET_CONSENT_STATE = typeof SET_CONSENT_STATE;

export const SET_MODAL_STATE = 'SET_MODAL_STATE';
export type SET_MODAL_STATE = typeof SET_MODAL_STATE;

export const SET_ENGINE_TYPE = 'SET_ENGINE_TYPE';
export type SET_ENGINE_TYPE = typeof SET_ENGINE_TYPE;
