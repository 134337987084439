import sharedStyles from '../../../styles/sharedStyles/sharedStyles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { muiTheme, theme } from '../../../styles/theme';

const shared = sharedStyles();

export default makeStyles(() =>
  createStyles({
    ...shared,

    container: {
      height: 'fit-content',
      margin: '0 auto',
      marginBottom: '32px',
      padding: '32px 24px',
      [muiTheme.breakpoints.up('sm')]: {
        maxWidth: '900px',
      },
    },

    marginTop: {
      marginTop: muiTheme.spacing(5),
    },

    searchBar: {
      marginTop: 62,
    },

    userResultsTable: {
      marginTop: '2.2rem',
    },

    marginTopZero: {
      marginTop: 0,
    },

    inputField: {
      marginLeft: 1,
      flex: 1,
    },

    search: {
      height: '40px',
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      width: 400,
    },

    iconSearch: {
      padding: '10px',
    },

    tableHeader: {
      minWidth: 650,
    },

    tableRows: {
      '&:last-child td, &:last-child th': { border: 0 },
    },

    helperText: {
      marginTop: 0,
      height: '2.2rem',
    },
  })
);
