export enum TooltipEnum {
  BOTTOM = 'bottom',
  LEFT = 'left',
  RIGHT = 'right',
  TOP = 'top',
}

export enum TooltipCursorEnum {
  NOT_ALLOWED = 'not-allowed',
  POINTER = 'pointer',
}
