import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { ClassNameMap } from '@mui/styles';
import useStyles from './ReservationCard.style';
import ReservationCardDate from './ReservationCardDate/ReservationCardDate';
import ReservationCardPeople from './ReservationCardPeople/ReservationCardPeople';
import Card from '../Card/Card';
import Collapse from '../Collapse/Collapse';
import { MenuOption, ThreeDotMenu } from '../ThreeDotMenu/ThreeDotMenu';
import { ReactComponent as ChevronIcon } from '../../../assets/svg/icon_chevron.svg';
import { PlaceTypeEnum } from '../../../enums/placeType.enum';
import { Booking } from '../../../models/booking/booking';
import { Place } from '../../../models/place/place';
import { PlaceAndParkingStatus } from '../../../models/placeAndParkingStatus/placeAndParkingStatus';
import i18n from '../../../translations/i18n';

let classes: ClassNameMap;

const ReservationCard: React.FC<Props> = ({
  booking,
  children,
  date,
  maxWidth,
  minWidth,
  onDeleteBooking,
  onEditBooking,
  onEditTeamSpaceBooking,
  place,
  placeAndParkingStatus,
  showIfIsTeamSpace,
  showIfIsUnavailable,
  style,
  tag,
  ...rest
}) => {
  classes = useStyles({ maxWidth, minWidth });

  const [showMore, setShowMore] = useState<boolean>(false);
  void i18n.loadNamespaces('buttons');
  const { t } = useTranslation('buttons');
  const isPlaceFull =
    (placeAndParkingStatus &&
      placeAndParkingStatus?.place?.currentOccupation === placeAndParkingStatus?.place?.maxCapacity) ||
    false;
  const isUnavailable = showIfIsUnavailable && (isPlaceFull || false);
  const isTeamSpace =
    placeAndParkingStatus?.place?.type?.includes(PlaceTypeEnum.TEAM_SPACE) ||
    booking?.place?.type?.includes(PlaceTypeEnum.TEAM_SPACE);
  const isTeamSpaceFull =
    (isTeamSpace &&
      showIfIsUnavailable &&
      placeAndParkingStatus?.place?.currentOccupation &&
      placeAndParkingStatus?.place?.currentOccupation > 0) ||
    false;

  const cardPeople = placeAndParkingStatus?.location?.users || booking?.location?.users;
  const teamSpaceName = isTeamSpace ? booking?.place?.name || placeAndParkingStatus?.place?.name : undefined;
  const teamSpacePeople = isTeamSpace ? booking?.place?.users || placeAndParkingStatus?.place?.users : undefined;
  const teamSpaceMenuOptions: MenuOption[] = [
    {
      title: t('editLabel'),
      handleAction: onEditTeamSpaceBooking,
    },
    {
      title: t('deleteLabel'),
      handleAction: onDeleteBooking,
    },
  ];
  const bookingMenuOption: MenuOption[] = [
    {
      title: t('editLabel'),
      handleAction: onEditBooking,
    },
    {
      title: t('deleteLabel'),
      handleAction: onDeleteBooking,
    },
  ];

  return (
    <Card className={classNames(classes.root, style)} width={'100%'} {...rest}>
      {/* Content */}
      <div className={classes.mainContent}>
        {/* Delete Action */}
        {!isTeamSpace && onDeleteBooking && onEditBooking && (
          <ThreeDotMenu className={classes.threeDotMenuContainer} menuOptions={bookingMenuOption} />
        )}
        {isTeamSpace && onDeleteBooking && onEditTeamSpaceBooking && (
          <ThreeDotMenu className={classes.threeDotMenuContainer} menuOptions={teamSpaceMenuOptions} />
        )}

        {/* Show More Action */}
        {RenderShowMoreButton(setShowMore)}

        {/* Left Content / Date */}
        <ReservationCardDate
          colorsInverted={showMore}
          date={date ? date : booking?.date}
          isUnavailable={showIfIsUnavailable && isUnavailable}
          isTeamSpace={isTeamSpace}
          isTeamSpaceFull={isTeamSpaceFull}
        />

        {/* Right Content */}
        {children}
      </div>

      {/* Collapse Area */}
      <Collapse isOpen={showMore}>
        <ReservationCardPeople
          generalPeople={cardPeople}
          teamSpaceName={teamSpaceName}
          teamSpacePeople={teamSpacePeople}
        />
      </Collapse>
    </Card>
  );
};

const RenderShowMoreButton = (setParent: (b: boolean) => void) => {
  const [showMore, setShowMore] = useState<boolean>(false);

  const toggleShowMore = () => {
    setParent(!showMore);
    setShowMore(!showMore);
  };

  return (
    <button className={classes.showMoreContainer} onClick={toggleShowMore}>
      <ChevronIcon className={classNames(classes.showMoreIcon, showMore && classes.showMoreIconInverted)} />
    </button>
  );
};

export type Props = {
  booking?: Booking;
  children?: React.ReactNode;
  date?: string;
  maxWidth?: number;
  minWidth?: number;
  onDeleteBooking?: () => void;
  onEditBooking?: () => void;
  onEditTeamSpaceBooking?: () => void;
  place?: Place;
  placeAndParkingStatus?: PlaceAndParkingStatus;
  showIfIsTeamSpace?: boolean;
  showIfIsUnavailable?: boolean;
  style?: string;
  tag?: string;
};

export default ReservationCard;
