import { createTheme, BreakpointsOptions } from '@mui/material/styles';
import { makeShadow, rgbToHex, withOpacity } from './utilities';

const space = 8;
export const htmlFontSize = 14;

const breakpoints: BreakpointsOptions = {
  keys: ['xs', 'sm', 'md', 'lg', 'xl'],
  values: {
    xs: 0,
    sm: 768,
    md: 1200,
    lg: 1600,
    xl: 1920,
  },
};

const shadows = {
  medium: makeShadow(3, 12, 0.07),
  strong: makeShadow(10, 12, 0.1),
  strongHover: makeShadow(10, 12, 0.3),
};

const colors = {
  blue: {
    //main: '#000078',
    main: '#C01722', // color code for critical
    light: '#d6ddf8',
    lighter: '#e2e7fa',
    lightest: '#f3f5fd',
    royal: '#C01722',
    //royal: '#3455db',
    //dark: rgbToHex(0, 0, 120),
    dark: '#C01722',
  },
  grey: {
    dark: '#9b9b9b',
    light: '#d8d8d8',
    lighter: '#f2f2f2',
    main: '#393939',
    medium: '#a5a5a5',
    superLight: '#f3f5fc',
    border: '#b2b4b6',
  },
  orange: {
    main: '#df7a39',
    office365: '#eb3c01',
  },
  red: {
    main: '#F13030',
  },
  white: {
    main: '#ffffff',
    mainBg: '#fafafa',
    op50: withOpacity('#ffffff', 0.5),
    op95: withOpacity('#ffffff', 0.95),
  },
};

const fontWeights = {
  bold: 'bold' as const,
};

export const theme = {
  colors,
  defaultBorderRadius: 6,
  fontWeights,
  heightFull: '100%',
  hoverOpacity: 0.8,
  navbarSmWidth: 300,
  navbarWidth: 350,
  shadows,
  transition: 'all 0.2s ease-in-out',
  widthFull: '100%',
  zIndex: {
    space: 1200,
  },
};

const defaultTheme = createTheme({
  breakpoints,
  spacing: space,
  typography: {
    fontSize: htmlFontSize,
    htmlFontSize,
    fontFamily: [
      'Roboto',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    h1: {
      fontSize: '1.5rem',
      fontWeight: 'bold',
      letterSpacing: '0.38px',
      lineHeight: '32px',
      color: theme.colors.grey.main,
    },
    h2: {
      fontSize: '1.286rem',
      fontWeight: 'bold',
      letterSpacing: '0.32px',
      lineHeight: '27px',
      color: theme.colors.grey.main,
    },
    subtitle1: {
      fontSize: '1.143rem',
      letterSpacing: '0.29px',
      lineHeight: '24px',
      color: theme.colors.grey.main,
    },
    subtitle2: {
      fontSize: '1.143rem',
      fontWeight: 'bold',
      letterSpacing: '0.29px',
      lineHeight: '24px',
      color: theme.colors.grey.main,
    },
    body1: {
      fontSize: '1rem',
      letterSpacing: '0.25px',
      lineHeight: '21px',
      color: theme.colors.grey.main,
    },
    body2: {
      fontSize: '0.857rem',
      letterSpacing: '0.21px',
      lineHeight: '18px',
      color: theme.colors.grey.main,
    },
  },
  palette: {
    primary: {
      main: theme.colors.blue.royal,
    },
    error: {
      main: colors.red.main,
    },
  },
  components: {
    MuiLink: {
      defaultProps: {
        underline: 'always',
      },
    },
  },
});

export const muiTheme = createTheme(defaultTheme, {
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          minHeight: '100vh',
          fontSize: htmlFontSize,
          backgroundColor: theme.colors.white.main,
          [defaultTheme.breakpoints.up('sm')]: {
            fontSize: htmlFontSize + 2,
          },
        },
        body: {
          minHeight: '100vh',
          color: theme.colors.grey.main,
          backgroundColor: theme.colors.white.main,
          '&::-webkit-scrollbar': {
            width: 27,
            height: 18,
            backgroundColor: theme.colors.white.main,
          },
          '&::-webkit-scrollbar-thumb': {
            height: 6,
            border: '10px solid rgba(0, 0, 0, 0)',
            backgroundClip: 'padding-box',
            '-webkit-border-radius': '14px',
            backgroundColor: 'rgba(178,180,182)',
          },
        },
        svg: {
          pointerEvents: 'none',
        },
      },
    },
    MuiContainer: {
      root: {
        paddingLeft: 0,
        paddingRight: 0,
        [defaultTheme.breakpoints.up('sm')]: {
          paddingLeft: 0,
          paddingRight: 0,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 6,
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.colors.blue.royal,
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            boxShadow: `${theme.colors.blue.lighter} 0 0 0 0.2rem`,
            borderWidth: 1,
          },
        },
      },
    },
  },
});
