import React, { useContext, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { EditBookingProps } from '../EditWizard';
import {
  setDate,
  setEngineType,
  setExistingTeamSpaceMembers,
  setLicensePlate,
  setNeedParkingSpace,
  setPlace,
} from '../../../CreateReservation/ReservationWizard/ReservationContext/ReservationReducer';
import { ReservationContext } from '../../../CreateReservation/ReservationWizard/ReservationContext/ReservationContext';
import Step from '../../../CreateReservation/ReservationWizard/Step/Step';
import TeamSpaceManager from '../../../CreateReservation/ReservationWizard/TeamSpaceManager/TeamSpaceManager';
import { RoutePaths } from '../../../../App/Router/RouterConstants';
import Loading from '../../../../Shared/Loading/Loading';
import { BookingRequestAssembler } from '../../../../../assemblers/booking/booking-request-assembler';
import { useEditBooking, useGetBookingById } from '../../../../../hooks/bookingHooks/bookingHooks';
import { BookingUtil } from '../../../../../utils/booking/booking-util';
import { TeamSpaceUtil } from '../../../../../utils/teamSpace/team-space-util';
import { useGetUserDefaults } from '../../../../../hooks/userHooks/userHooks';

const TeamSpaceBookingEditor: React.FC<EditBookingProps> = ({
  bookingId,
  stepLength,
  step,
  onNextStep,
  onPreviousStep,
  setEditedBooking,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation('editWizard');
  const { enqueueSnackbar } = useSnackbar();
  const {
    booking: bookingResponse,
    bookingLoading: bookingResponseLoading,
    bookingError: bookingResponseError,
  } = useGetBookingById(bookingId);
  const { state, dispatch } = useContext(ReservationContext);
  const { licensePlate, engineType } = state;
  const { email, licensePlate: defaultLicensePlate, engineType: defaultEngineType } = useGetUserDefaults();

  useEffect(() => {
    if (bookingResponse) {
      const teamSpaceUsers = BookingUtil.getTeamSpaceUsers(bookingResponse);
      bookingResponse.place && dispatch(setPlace(bookingResponse.place));
      teamSpaceUsers && dispatch(setExistingTeamSpaceMembers(teamSpaceUsers));
      bookingResponse.date && dispatch(setDate([bookingResponse.date]));
      TeamSpaceUtil.hasNeedParkingSpace(teamSpaceUsers) && dispatch(setNeedParkingSpace(true));
      dispatch(setLicensePlate(bookingResponse.user?.licensePlate ?? defaultLicensePlate));
      dispatch(setEngineType(bookingResponse.user?.engineType ?? defaultEngineType));
    }
  }, [bookingResponse, defaultEngineType, defaultLicensePlate, dispatch]);

  const onCancel = () => {
    setEditedBooking(null);
    navigate(RoutePaths.myReservations);
  };

  const bookingTeamSpaceMembers = useMemo(
    () =>
      TeamSpaceUtil.getTeamSpaceMembers(
        state.teamSpaceMembers,
        state.teamSpaceMembersParking,
        state.existingTeamSpaceMembers
      ),
    [state.existingTeamSpaceMembers, state.teamSpaceMembers, state.teamSpaceMembersParking]
  );

  const bookingRequest = BookingRequestAssembler.fromBooking({
    id: bookingId,
    teamSpaceMembers: bookingTeamSpaceMembers,
    licensePlate: TeamSpaceUtil.getTeamSpaceMemberNeedParkingSpaceByEmail(bookingTeamSpaceMembers, email)
      ? licensePlate
      : undefined,
    engineType: TeamSpaceUtil.getTeamSpaceMemberNeedParkingSpaceByEmail(bookingTeamSpaceMembers, email)
      ? engineType
      : undefined,
  });

  const {
    editBooking,
    response: bookingRequestResponse,
    error: bookingRequestError,
    loading: bookingRequestLoading,
  } = useEditBooking(bookingRequest);

  const onConfirm = () => {
    setEditedBooking(bookingId);
    void editBooking();
  };

  useEffect(() => {
    bookingRequestResponse && navigate(RoutePaths.myReservations);
    bookingRequestResponse &&
      enqueueSnackbar(t('toastSuccess'), {
        variant: 'success',
      });
  }, [bookingRequestResponse, enqueueSnackbar, navigate, t]);

  useEffect(() => {
    if (bookingRequestError) {
      enqueueSnackbar(bookingRequestError, {
        variant: 'error',
        persist: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingRequestError, enqueueSnackbar]);

  useEffect(() => {
    bookingResponseError && navigate(RoutePaths.myReservations);
  }, [bookingResponseError, navigate]);

  const renderLoading = () => {
    return <Loading />;
  };

  const renderEdit = () => {
    const stepProps = {
      bookingLoading: bookingRequestLoading,
      onCancel,
      onConfirm,
      stepLength,
      step,
      onNextStep,
      onPreviousStep,
    };

    return (
      <Step {...stepProps} stepTitle={t('title')} editMode>
        <TeamSpaceManager editMode />
      </Step>
    );
  };

  return bookingResponseLoading ? renderLoading() : renderEdit();
};

export default TeamSpaceBookingEditor;
