import { ILocationResponse } from '../../interfaces/location/location-response';
import { ILocationStatusResponse } from '../../interfaces/location/location-status-response';
import { Location } from '../../models/location/location';
import { ParkingLotsUtil } from '../../utils/parkingLots/parking-lots-util';

export class LocationAssembler {
  static from(locationResponse: ILocationResponse): Location {
    return {
      id: locationResponse.id,
      name: locationResponse.name,
      description: locationResponse.description,
      type: locationResponse.type,
      rank: locationResponse.rank,
    };
  }

  static fromLocationStatus(locationStatusResponse: ILocationStatusResponse): Location {
    return {
      id: locationStatusResponse.id,
      name: locationStatusResponse.name,
      description: locationStatusResponse.description,
      type: locationStatusResponse.type,
      rank: locationStatusResponse.rank,
      maxCapacity: locationStatusResponse.maxCapacity,
      currentOccupation: locationStatusResponse.currentOccupation,
      locations: locationStatusResponse.locations,
      places: locationStatusResponse.places,
      parkingLots: ParkingLotsUtil.reduceParkingLots(locationStatusResponse.parkingLots),
      users: locationStatusResponse.users,
    };
  }

  static fromList(locationResponseList: ILocationResponse[]): Location[] {
    return locationResponseList.map((locationResponse) => this.from(locationResponse));
  }
}
