import makeStyles from '@mui/styles/makeStyles';
import { theme } from '../../../../styles/theme';
import sharedStyles from '../../../../styles/sharedStyles/sharedStyles';

const shared = sharedStyles();
const timeout = `.5s`; // timeout to transition in.

const transitionAttr = (attr: string) => {
  return { transition: `${attr} ${timeout} ease` };
};

export default makeStyles({
  ...shared,

  aside: {
    ...shared.gap4,
    ...transitionAttr('background'),
    backgroundColor: theme.colors.blue.royal,
    display: 'grid',
    minWidth: 128,
    minHeight: 140,
    padding: 16,
    placeItems: 'center',
  },

  backgroundGreyColor: {
    backgroundColor: theme.colors.blue.lightest,
  },

  backgroundRedColor: {
    backgroundColor: theme.colors.red.main,
  },

  backgroundDarkBlue: {
    backgroundColor: theme.colors.blue.dark,
  },

  backgroundTagBlue: {
    backgroundColor: theme.colors.blue.light,
  },

  tagContainer: {
    color: theme.colors.blue.royal,
    ...shared.fontSize8,
    borderRadius: 12,
    height: 16,
    minWidth: 80,
    padding: '4px 8px',
    textTransform: 'uppercase',
    textAlign: 'center',
    position: 'absolute',
    top: 10,
    '& p': {
      margin: 0,
    },
  },

  dayOfWeek: {
    ...shared.fontSize48,
    ...transitionAttr('color'),
    margin: 0,
  },

  extendedDayOfWeek: {
    ...shared.fontSize14,
    ...transitionAttr('color'),
    letterSpacing: 0,
    opacity: 0.8,
    textAlign: 'center',
    textTransform: 'uppercase',
  },

  monthAndYear: {
    ...shared.flexColumnAlignCenterJustifyCenter,
    ...shared.fontSize16,
    ...shared.gap4,
    ...transitionAttr('color'),
    letterSpacing: 0,
    opacity: 0.8,
    textAlign: 'center',
    textTransform: 'uppercase',
  },
});
