import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import sharedStyles from '../../../styles/sharedStyles/sharedStyles';
import { theme } from '../../../styles/theme';

const shared = sharedStyles();

export default makeStyles(() =>
  createStyles({
    ...shared,

    '@keyframes spin': {
      '0%': {
        transform: 'rotate(0deg)',
      },
      '100%': {
        transform: 'rotate(360deg)',
      },
    },

    spinner: {
      animation: `$spin 3s linear infinite`,
      height: theme.heightFull,
      width: theme.widthFull,

      '&.spinnerBlueRoyalColor': {
        color: theme.colors.blue.royal,
      },

      '&.spinnerWhiteColor': {
        color: theme.colors.white.main,
      },
    },
  })
);
