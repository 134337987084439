import React from 'react';
import { Helmet } from 'react-helmet';
import { Provider } from 'react-redux';
import CssBaseline from '@mui/material/CssBaseline';
import { Msal2Provider } from '@microsoft/mgt-msal2-provider';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { Providers } from '@microsoft/mgt-element';
import { FeatureToggleProvider } from './FeatureToggleProvider/FeatureToggleContext';
import Router from './Router/Router';
import SnackbarProvider from './SnackbarProvider/SnackbarProvider';
import config from '../../config/config';
import configureStore from '../../redux/store/configureStore';
import { muiTheme, theme } from '../../styles/theme';

Providers.globalProvider = new Msal2Provider(config.MSAL_PROVIDER);

// Set up Redux store
const store = configureStore();

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={muiTheme}>
          <SnackbarProvider>
            <FeatureToggleProvider>
              <CssBaseline />
              <Helmet>
                <meta name='theme-color' content={theme.colors.blue.royal} />
              </Helmet>
              <Router />
            </FeatureToggleProvider>
          </SnackbarProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </Provider>
  );
};

export default App;
