import { StoreState } from '../reducers/rootReducer/rootReducer';

export function getUserFloor(state: StoreState) {
  return state.booking.selectedFloor;
}

export function getSelectedFloor(state: StoreState) {
  return state.booking.selectedFloor;
}

export function getSelectedPlace(state: StoreState) {
  return state.booking.selectedPlace;
}

export function getParkingSpace(state: StoreState) {
  return state.booking.selectedParkingSpace;
}
