import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import Button from '@mui/material/Button';
import useStyles from './VehicleTypeButtons.style';
import Tooltip from '../Tooltip/Tooltip';
import { ReactComponent as EngineTypeCombustion } from '../../../assets/svg/icon_local_gas_station_bold.svg';
import { ReactComponent as EngineTypeElectric } from '../../../assets/svg/icon_electrical_services_bold.svg';
import { EngineTypeEnum } from '../../../enums/engineType.enum';
import i18n from '../../../translations/i18n';

const VehicleTypeButtons: React.FC<Props> = ({ disabled = false, selectedEngineType, setEngineType }) => {
  const classes = useStyles();
  void i18n.loadNamespaces('licensePlateEngineType');
  const { t } = useTranslation('licensePlateEngineType');

  return (
    <div className={classNames(classes.flexRowAlignCenter, classes.gap16)}>
      <Tooltip disabled={disabled} content={t('combustionEngineType')}>
        <Button
          aria-label={t('combustionEngineType')}
          data-testid='button-parking-combustion'
          variant='outlined'
          disabled={disabled}
          onClick={() => setEngineType(EngineTypeEnum.COMBUSTION)}
          className={classNames(
            classes.engineTypeButton,
            selectedEngineType === EngineTypeEnum.COMBUSTION && !disabled ? classes.engineTypeButtonActive : undefined
          )}
        >
          <EngineTypeCombustion />
        </Button>
      </Tooltip>
      <Tooltip disabled={disabled} content={t('evAndPhevEngineType')}>
        <Button
          aria-label={t('evAndPhevEngineType')}
          data-testid='button-parking-ev'
          variant='outlined'
          disabled={disabled}
          onClick={() => setEngineType(EngineTypeEnum.EV_PHEV)}
          className={classNames(
            classes.engineTypeButton,
            selectedEngineType === EngineTypeEnum.EV_PHEV && !disabled ? classes.engineTypeButtonActive : undefined
          )}
        >
          <EngineTypeElectric title={t('evAndPhevEngineType')} />
        </Button>
      </Tooltip>
    </div>
  );
};

interface Props {
  disabled?: boolean;
  selectedEngineType: EngineTypeEnum | undefined;
  setEngineType: (engineType: EngineTypeEnum | undefined) => void;
}

export default VehicleTypeButtons;
