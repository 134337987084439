import { colorsStyles } from './colors';
import createStyles from '@mui/styles/createStyles';
import { muiTheme } from './../theme';
import { pxToRemOrEm } from '../utilities';
import { theme } from '../theme';
import flexbox from './flexbox';
import fontSizes from './fontsizes';
import fontWeight from './fontweight';
import gaps from './gaps';

const sharedStyles = () =>
  createStyles({
    ...colorsStyles,
    ...flexbox,
    ...fontSizes,
    ...fontWeight,
    ...gaps,

    buttonDisabled: {
      color: `${theme.colors.grey.light}!important`,
      pointerEvents: 'none',
      opacity: 0.5,
      '& svg': {
        color: theme.colors.grey.light,
      },
    },

    pageTitle: {
      fontSize: pxToRemOrEm(22),
      textTransform: 'capitalize',
      fontWeight: 'bold',
      color: theme.colors.blue.royal,
      marginBottom: muiTheme.spacing(2),
    },
  });

export default sharedStyles;
