import React from 'react';
import classNames from 'classnames';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import useStyles from './TopBar.style';
import { ReactComponent as MenuHamburger } from '../../../../assets/svg/icon_menu.svg';
import { ReactComponent as WallyLogoIcon } from '../../../../assets/svg/logo_wally-notitle.svg';
import { ReactComponent as WallyLogoTitle } from '../../../../assets/svg/logo_wally-title.svg';
import { useScrollYDistance } from '../../../../hooks/mediaHooks/scrollHooks';

const TopBar: React.FC<Props> = ({ openMenu }) => {
  const classes = useStyles();
  const scrollYDistance = useScrollYDistance();

  return (
    <AppBar
      position={'sticky'}
      color={'primary'}
      className={classNames(classes.appBar, scrollYDistance > 0 && classes.scrollAppBar)}
    >
      <Toolbar className={classes.toolbar}>
        {renderMenuButton()}
        {renderWallyLogo()}
      </Toolbar>
    </AppBar>
  );

  function renderMenuButton() {
    return (
      <IconButton
        aria-label='menu'
        size={'small'}
        onClick={openMenu}
        className={classNames(classes.menuButton, scrollYDistance > 0 && classes.scrollMenuButton)}
      >
        <MenuHamburger data-testid='wallyHamburger' />
      </IconButton>
    );
  }

  function renderWallyLogo() {
    return (
      <div className={classes.logoWrapper}>
        <WallyLogoIcon
          className={classNames(classes.wallyLogo, scrollYDistance > 0 && classes.scrollWallyLogo)}
          data-testid='wallyLogoIcon'
        />
      </div>
    );
  }
};

type Props = {
  openMenu: () => void;
};

export default TopBar;
