import i18n from 'i18next';
import ChainedBackend from 'i18next-chained-backend';
import { initReactI18next } from 'react-i18next';
import HttpBackend from 'i18next-http-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';
import config from '../config/config';

const backendOptions = {
  // backends in preferred order of use
  backends: [LocalStorageBackend, HttpBackend],
  backendOptions: [
    // options for first backend
    {
      // prefix for stored languages
      prefix: 'i18n_wally_',
      expirationTime: 14 * 24 * 60 * 60 * 1000, // 14 days
      // Version applied to all languages, can be overridden using the option `versions`
      defaultVersion: process.env.REACT_APP_VERSION,
      // language versions
      // versions: { en: 'v1.2', fr: 'v1.1' },
      // changing the default version will force fetch of new translations
    },
    // options for second backend
    {
      loadPath: `${config.TRANSLATIONS_ENDPOINT}/{{lng}}/{{ns}}.json`,
    },
  ],
};

void i18n
  .use(ChainedBackend)
  .use(initReactI18next)
  .init({
    ns: ['dashboard'],
    defaultNS: 'dashboard',
    fallbackLng: 'en',
    interpolation: { escapeValue: false },
    backend: backendOptions,
    debug: true,
  });

export default i18n;
