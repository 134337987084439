import React from 'react';
import classNames from 'classnames';
import Button, { ButtonProps } from '@mui/material/Button';
import useMediaQuery from '@mui/material/useMediaQuery';
import useStyles from './RoundButton.style';
import { mobileQuery } from '../../../constants/mediaQuery';
import Tooltip from '../../Shared/Tooltip/Tooltip';
import { TooltipEnum } from '../../../enums/tooltip.enum';

const RoundButton: React.FC<Props> = ({
  align,
  buttonColor,
  capitalizeText,
  children,
  size,
  textTransformNone,
  tooltipText,
  ...other
}) => {
  const classes = useStyles();
  const isMobile = useMediaQuery(mobileQuery);

  const rootClassAlign = (() => {
    switch (align) {
      case 'center':
        return classes.roundButtonRootAlignCenter;
      case 'right':
        return classes.roundButtonRootAlignRight;
      case 'left':
        return classes.roundButtonRootAlignLeft;
      default:
        return '';
    }
  })();

  const rootClassSize = (() => {
    switch (size) {
      case 'small':
        return classes.roundButtonRootSizeSmall;
      case 'large':
        return classes.roundButtonRootSizeLarge;
      default:
        return classes.roundButtonRootSizeMedium;
    }
  })();

  const labelClassSize = (() => {
    switch (size) {
      case 'small':
        return classes.roundButtonLabelTextSmall;
      case 'large':
        return classes.roundButtonLabelTextLarge;
      default:
        return classes.roundButtonLabelTextMedium;
    }
  })();

  const rootClassColor = (() => {
    switch (buttonColor) {
      case 'blue':
        return classes.roundButtonRootBlueColor;
      case 'grey':
        return classes.roundButtonRootGreyColor;
      case 'outline':
        return classes.roundButtonRootOutline;
      default:
        return classes.roundButtonRootBlueRoyalColor;
    }
  })();

  const disabledStyle = (() => {
    switch (buttonColor) {
      case 'outline':
        return classes.roundButtonOutlineDisabled;
      default:
        return classes.buttonDisabled;
    }
  })();

  return tooltipText ? renderToolTipWrapper(renderButton()) : renderButton();

  function renderButton() {
    return (
      <Button
        classes={{
          root: classNames(classes.roundButtonRoot, rootClassAlign, rootClassSize, rootClassColor),
          text: classNames(
            classes.roundButtonLabel,
            labelClassSize,
            capitalizeText && !textTransformNone ? classes.textTransformCapitalize : classes.textTransformUppercase,
            textTransformNone && classes.textTransformNone
          ),
          disabled: classNames(disabledStyle),
        }}
        {...other}
      >
        {children}
      </Button>
    );
  }

  function renderToolTipWrapper(children: JSX.Element) {
    if (isMobile || !tooltipText || !other.disabled) {
      return children;
    }

    return (
      <Tooltip content={tooltipText || ''} direction={TooltipEnum.TOP}>
        <div className={classes.buttonWrapper}>
          {/* A disabled button does not send mouse events. To make the tooltip work, we need this div. */}
          {children}
        </div>
      </Tooltip>
    );
  }
};

export type Props = {
  align?: 'left' | 'center' | 'right';
  buttonColor?: 'blue royal' | 'blue' | 'grey' | 'outline';
  capitalizeText?: boolean;
  children: React.ReactElement | string;
  size?: 'small' | 'medium' | 'large' | 'responsiveLarge';
  textTransformNone?: boolean;
  tooltipText?: string;
} & Partial<ButtonProps>;

export default RoundButton;
