import { getSelectedDate } from '../../redux/selectors/appSelectors';
import { useSelector } from 'react-redux';
import moment from 'moment';

export const useSelectedDate = () => {
  const selectedDate = useSelector(getSelectedDate);
  return {
    date: moment(selectedDate).toDate(),
    momentDate: moment(selectedDate),
  };
};
