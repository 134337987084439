import React from 'react';
import Scroll from 'react-scroll';
import classNames from 'classnames';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import useStyles from './BackToTopButton.style';
import { useScrollYDistance } from '../../../hooks/mediaHooks/scrollHooks';

const showButtonDistance = 400;

export const BackToTopButton: React.FC<Props> = ({ name, className }) => {
  const classes = useStyles();
  const scrollYDistance = useScrollYDistance();

  return (
    <Button
      disableRipple
      classes={{
        root: classNames(classes.buttonRoot, scrollYDistance >= showButtonDistance && classes.buttonVisible),
      }}
      onClick={Scroll.animateScroll.scrollToTop}
      className={className}
    >
      <ArrowUpwardIcon className={classes.buttonIcon} />
      <Typography classes={{ root: classes.buttonTypography }}>{name || 'Back to Top'}</Typography>
    </Button>
  );
};

interface Props {
  name?: string;
  className?: string;
}

export default BackToTopButton;
