import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@mui/material/useMediaQuery';
import useStyles from './Navigation.style';
import RoundButton from '../../../../Shared/RoundButton/RoundButton';
import Tooltip from '../../../../Shared/Tooltip/Tooltip';
import { mobileQuery } from '../../../../../constants/mediaQuery';
import { TooltipCursorEnum, TooltipEnum } from '../../../../../enums/tooltip.enum';
import { ReservationError } from '../../../../../models/parkingReservation/parkingReservation';
import { ReservationErrorUtil } from '../../../../../utils/reservationError/reservation-error-util';
import LoadingSpinner from '../../../../Shared/Loading/LoadingSpinner';
import { LoadingSpinnerEnum } from '../../../../../enums/loadingSpinner.enum';
import i18n from '../../../../../translations/i18n';

const Navigation: React.FC<Props> = ({
  onCancel,
  onConfirm,
  onNextStep,
  onPreviousStep,
  step,
  stepLength,
  bookingLoading = false,
  reservationError = [],
  canContinue = true,
}) => {
  const classes = useStyles();

  const isMobile = useMediaQuery(mobileQuery);
  void i18n.loadNamespaces('buttons');
  const { t } = useTranslation('buttons');

  const onPreviousButton = () => {
    step > 0 ? onPreviousStep() : onCancel();
  };

  function getPreviousButtonLabel() {
    return step > 0 ? t('previousButtonLabel') : t('cancelButtonLabel');
  }

  function getPreviousButtonColor() {
    return step > 0 ? 'outline' : 'grey';
  }

  const onNextButton = () => {
    hasNoErrors && step < stepLength - 1 ? onNextStep() : onConfirm();
  };

  function getNextButtonLabel() {
    return step < stepLength - 1 ? t('nextButtonLabel') : t('confirmButtonLabel');
  }

  function getNextButtonColor() {
    return step < stepLength - 1 ? 'outline' : undefined;
  }

  const hasNoErrors = useMemo(() => {
    return ReservationErrorUtil.checkIfNoErrors(reservationError);
  }, [reservationError]);

  const tooltipMessage = useMemo(() => {
    return ReservationErrorUtil.joinErrorMessages(reservationError);
  }, [reservationError]);

  const renderNextButton = () => (
    <RoundButton
      disabled={bookingLoading || !hasNoErrors || !canContinue}
      buttonColor={getNextButtonColor()}
      size='large'
      capitalizeText
      onClick={onNextButton}
    >
      {bookingLoading ? (
        <div className={classes.loadingSpinnerContainer}>
          <LoadingSpinner color={LoadingSpinnerEnum.WHITE_COLOR} />
        </div>
      ) : (
        getNextButtonLabel()
      )}
    </RoundButton>
  );

  return (
    <div className={classes.stickyWrapper}>
      <div className={classes.buttonsWrapper}>
        <RoundButton buttonColor={getPreviousButtonColor()} size='large' capitalizeText onClick={onPreviousButton}>
          {getPreviousButtonLabel()}
        </RoundButton>
        {isMobile ? (
          renderNextButton()
        ) : (
          <Tooltip
            content={tooltipMessage}
            direction={TooltipEnum.TOP}
            disabled={hasNoErrors || step < stepLength - 1}
            cursor={TooltipCursorEnum.NOT_ALLOWED}
          >
            {renderNextButton()}
          </Tooltip>
        )}
      </div>
    </div>
  );
};

interface Props {
  bookingLoading?: boolean;
  canContinue?: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  onNextStep: () => void;
  onPreviousStep: () => void;
  reservationError?: ReservationError[];
  step: number;
  stepLength: number;
}

export default Navigation;
