import { useDispatch } from 'react-redux';
import { EndpointActions, useFetch } from '../ajaxHooks/ajaxHooks';
import { LocationAssembler } from '../../assemblers/location/location-assembler';
import { LocationTypeEnum } from '../../enums/locationType.enum';
import { ILocationResponse } from '../../interfaces/location/location-response';
import { ILocationStatusResponse } from '../../interfaces/location/location-status-response';
import { Location } from '../../models/location/location';
import { Place } from '../../models/place/place';
import { setSelectedFloor, setSelectedPlace } from '../../redux/actions/bookingActions/bookingActions';
import { useMemo } from 'react';

// ************************************************************************
// SET SELECTED FLOOR
// ************************************************************************
export const useSetSelectedFloor = () => {
  const dispatch = useDispatch();

  return (floor: Location) => {
    dispatch(setSelectedFloor(floor));
  };
};

// ************************************************************************
// SET SELECTED PLACE
// ************************************************************************
export const useSetSelectedPlace = () => {
  const dispatch = useDispatch();

  return (place: Place) => {
    dispatch(setSelectedPlace(place));
  };
};

// ************************************************************************
// GET BUILDINGS
// ************************************************************************
export const useGetBuildings = () => {
  const endpoint = EndpointActions.GetLocationsByType([LocationTypeEnum.BUILDING]);
  const { response, loading, error } = useFetch<ILocationResponse[]>(endpoint);
  const buildingsResponse = useMemo(() => response && LocationAssembler.fromList(response), [response]);

  return {
    buildings: response && buildingsResponse,
    buildingsLoading: loading,
    buildingsError: error,
  };
};

// ************************************************************************
// GET LOCATION DETAILS
// ************************************************************************
export const useGetLocationDetails = (locationId: string) => {
  const endpoint = EndpointActions.GetLocationById([locationId]);
  const { run, response, loading, error } = useFetch<ILocationStatusResponse>(endpoint, [], {
    defer: true,
  });
  const locationDetails = useMemo(() => response && LocationAssembler.fromLocationStatus(response), [response]);

  return {
    getLocationDetails: run,
    locationDetails: response && locationDetails,
    locationDetailsLoading: loading,
    locationDetailsError: error,
  };
};
