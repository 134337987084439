import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import useStyles from './Dashboard.style';
import DateSlider from './DateSlider/DateSlider';
import FloorList from './FloorList/FloorList';
import ReservationCapacityProgress from './ReservationCapacityProgress/ReservationCapacityProgress';
import LocationTag from '../../Shared/LocationTag/LocationTag';
import { useGetBuildingStatus } from '../../../hooks/dashboardHooks/dashboardHooks';
import { useGetUserDefaults } from '../../../hooks/userHooks/userHooks';
import { ReactComponent as IconWarning } from '../../../assets/svg/icon_warning.svg';
import { useSelectedDate } from '../../../hooks/appHooks/appHooks';
import { Place } from '../../../models/place/place';
import { selectDate } from '../../../redux/actions/appActions/appActions';
import { DashboardEnum } from '../../../enums/dashboard.enum';
import Loading from '../../Shared/Loading/Loading';

const Dashboard: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation('dashboard');

  const [isFirstRender, setIsFirstRender] = useState(true);
  const { momentDate } = useSelectedDate();

  useEffect(() => {
    dispatch(selectDate(moment().toDate()));
    isFirstRender && setIsFirstRender(false);
    // this should only run at component mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  /*
   * In order to avoid triggering twice the useGetBuildingStatus hook,
   * we need to skip its run on component mount, so that we can dispatch the current date.
   * After that, the hook will be in charge of fetching the status for the current date.
   * In this way, we need to pass `isFirstRender` to the hook.
   */

  const { buildingStatus, buildingStatusLoading } = useGetBuildingStatus(undefined, isFirstRender);
  const newArrayPlaces: Place[] = [];
  buildingStatus?.locations?.forEach((wings) =>
    wings?.locations?.forEach((floors) => floors?.places?.forEach((place) => newArrayPlaces.push(place)))
  );

  const { location } = useGetUserDefaults();

  return (
    <Grid component='main' container justifyContent='center' className={classes.container}>
      <Grid item xs={12}>
        <div className={classes.dateSliderAndLocationTagWrapper}>
          <DateSlider />
          <LocationTag location={location?.name} />
        </div>
      </Grid>
      {buildingStatusLoading ? (
        <div className={classes.marginTop}>
          <Loading />
        </div>
      ) : (
        <>
          <Grid item xs={12}>
            <ReservationCapacityProgress buildingStatus={buildingStatus} />
          </Grid>
          {momentDate.isBetween(DashboardEnum.COVID_MEASURES_INITIAL_DATE, DashboardEnum.COVID_MEASURES_FINAL_DATE) ? (
            <div className={classes.whiteSpacePreLineLockdownWarning}>
              <p className={classes.remoteWorking}>
                <IconWarning className={classes.iconWarning} />
                {t('dashboard.remoteWorking')}
              </p>
              <p className={classes.lockdownWarning}>
                <Trans t={t} components={{ 1: <span className={classes.fontBold} /> }}>
                  dashboard.lockdownMeasures
                </Trans>
              </p>
            </div>
          ) : (
            <>
              <Grid item xs={12}>
                <Typography variant='h2' className={classes.title}>
                  {t('dashboard.whereToSit')}
                </Typography>
              </Grid>
              <Grid item xs={12} md={8}>
                <FloorList buildingStatus={buildingStatus} />
              </Grid>
            </>
          )}
        </>
      )}
    </Grid>
  );
};

export default Dashboard;
