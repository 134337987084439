import sharedStyles from '../../../styles/sharedStyles/sharedStyles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { muiTheme, theme } from '../../../styles/theme';

const shared = sharedStyles();

const closeButtonSize = '12px';

export default makeStyles(() =>
  createStyles({
    ...shared,

    modal: {
      position: 'fixed',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'rgba(0,0,0,0.5)',
      zIndex: 1111,
    },

    modalContainer: {
      display: 'grid',
      width: '90%',
      height: '90%',
      maxWidth: '90vw',
      maxHeight: '80vh',
      [muiTheme.breakpoints.up('sm')]: {
        display: 'grid',
        gridTemplateRows: '80px 1fr 98px',
        zIndex: 1,
        maxWidth: 877,
        maxHeight: 632,
      },
      backgroundColor: theme.colors.white.main,
      borderRadius: 6,
    },

    ModalContainerWithoutButtons: {
      [muiTheme.breakpoints.up('sm')]: {
        gridTemplateRows: '80px 1fr 80px',
      },
    },

    modalHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      gridTemplateRows: '24px 16px',
      paddingTop: 24,
      paddingBottom: 16,
      paddingLeft: 40,
      [muiTheme.breakpoints.only('xs')]: {
        paddingRight: 24,
      },
    },

    modalTitle: {
      color: theme.colors.grey.main,
      fontSize: 20,
      fontWeight: 'bold',
      textTransform: 'uppercase',
      [muiTheme.breakpoints.only('xs')]: {
        paddingRight: 16,
      },
    },

    modalSubTitle: {
      color: theme.colors.grey.dark,
      fontSize: 12,
      fontWeight: 'normal',
      letterSpacing: 0,
    },

    modalHeaderText: {
      display: 'flex',
      flexDirection: 'column',
    },

    modalHeaderCloseButton: {
      paddingTop: 4,
      paddingRight: 20,
      [muiTheme.breakpoints.only('xs')]: {
        paddingRight: 0,
      },
    },

    modalCloseButton: {
      padding: 0,
      color: theme.colors.grey.main,
      '& svg': {
        width: closeButtonSize,
        height: closeButtonSize,
      },
    },

    modalBody: {
      paddingTop: 16,
      paddingBottom: 25,
      paddingRight: 59,
      color: theme.colors.grey.main,
      fontSize: 16,
      fontWeight: 'normal',
      overflowX: 'hidden',
      overflowY: 'auto',
      boxShadow: 'inset 0 8px 16px #f2f2f2, inset 0 -8px 16px #f2f2f2',
      paddingLeft: 40,
      [muiTheme.breakpoints.only('xs')]: {
        paddingRight: 0,
      },
      '&::-webkit-scrollbar': {
        width: 27,
        height: 18,
      },
      '&::-webkit-scrollbar-thumb': {
        height: 6,
        border: '10px solid rgba(0, 0, 0, 0)',
        backgroundClip: 'padding-box',
        '-webkit-border-radius': '14px',
        backgroundColor: 'rgba(178,180,182)',
      },
    },

    alphaList: {
      listStyleType: 'upper-alpha',
      listStylePosition: 'inside',
      padding: 0,

      '& li:not(:last-child)': {
        margin: '16px 0',
      },
      '& li::marker': {
        fontWeight: 'bold',
      },
      '& li span': {
        marginLeft: 12,
      },
    },

    modalFooter: {
      padding: 23,
      textAlign: 'center',
    },

    buttonsPosition: {
      ...shared.flexJustifyCenter,
      [muiTheme.breakpoints.only('xs')]: {
        ...shared.flexColumnAlignCenterJustifyCenter,
      },
    },

    consentButtons: {
      width: 201,
      height: 52,
    },

    disagreeButton: {
      marginRight: 20,
      [muiTheme.breakpoints.only('xs')]: {
        marginRight: 0,
        marginBottom: 10,
      },
    },

    agreeButton: {
      marginLeft: 20,
      [muiTheme.breakpoints.only('xs')]: {
        marginLeft: 0,
        marginTop: 10,
      },
    },
  })
);
