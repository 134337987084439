import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import Container from '@mui/material/Container';
import useMediaQuery from '@mui/material/useMediaQuery';
import MenuLink from './MenuLink/MenuLink';
import useStyles from './SideMenu.style';
import { getMenuEntries, RoutePaths } from '../../Router/RouterConstants';
//import { ReactComponent as LogoCtw } from '../../../../assets/svg/logo_ctw.svg';
import { ReactComponent as LogoCtw } from '../../../../assets/svg/logo_empty.svg';
import { ReactComponent as LogoWally } from '../../../../assets/svg/logo_wally-notitle.svg';
import { ReactComponent as LogoWallyTitle } from '../../../../assets/svg/logo_wally-title.svg';
import { mobileQuery } from '../../../../constants/mediaQuery';
import { useGetUserRoles } from '../../../../hooks/authHooks/authHooks';

const SideMenu: React.FC<Props> = ({ closeMenu }) => {
  const classes = useStyles();
  const roles = useGetUserRoles();

  const routeLocation = useLocation();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(mobileQuery);

  const handleLogoClick = () => {
    navigate(RoutePaths.dashboard);
    closeMenu?.();
  };

  return (
    <Container
      disableGutters
      className={classNames(classes.menuContainer, isMobile ? classes.mobile : classes.desktop)}
    >
      <div>{renderLogoWrapper()}</div>
      <div className={classes.menuOptions}>{renderMenuOptions(roles)}</div>
      {renderFooter()}
    </Container>
  );

  function renderLogoWrapper() {
    return (
      <div
        className={classNames(classes.logoWrapper, { [classes.navMarginLeft]: !isMobile })}
        onClick={handleLogoClick}
      >
        <LogoWally className={classes.wallyLogo} data-testid='wallyLogo' />
        <LogoWallyTitle className={classes.wallyTitle} data-testid='wallyTitle' />
      </div>
    );
  }

  function renderMenuOptions(userRoles: string[] = []) {
    const routePath = routeLocation.pathname;
    const entries = getMenuEntries(userRoles);

    return (
      <nav className={classNames(classes.nav, { [classes.navMarginLeft]: !isMobile })}>
        <ul>
          {entries.map((entry, index) => (
            <MenuLink
              key={index}
              title={entry.title}
              path={entry.link}
              isActive={routePath.includes(entry.link)}
              closeMenu={closeMenu}
            />
          ))}
        </ul>
      </nav>
    );
  }

  function renderFooter() {
    return (
      <footer>
        <LogoCtw className={classes.logoCtw} data-testid='logoCtw' />
      </footer>
    );
  }
};

export default SideMenu;

export type Props = {
  closeMenu?: () => void;
};
