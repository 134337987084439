import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { startCase } from 'lodash';
import { useSnackbar } from 'notistack';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import useStyles from './Step.style';
import Navigation from '../Navigation/Navigation';
import { ReservationContext } from '../ReservationContext/ReservationContext';
import { addError, removeError } from '../ReservationContext/ReservationReducer';
import ReservationCardContent from '../../ReservationCardContent/ReservationCardContent';
import ReservationCard from '../../../../Shared/ReservationCard/ReservationCard';
import { WizardInjectedProps } from '../../../../Shared/Wizard/Wizard';
import { PlaceTypeEnum } from '../../../../../enums/placeType.enum';
import { ReservationErrorEnum } from '../../../../../enums/reservationWizard.enum';
import { useGetStatusByIdAndDates } from '../../../../../hooks/placeHooks/placeHooks';
import { PlaceAndParkingStatus } from '../../../../../models/placeAndParkingStatus/placeAndParkingStatus';
import { ParkingLotsUtil } from '../../../../../utils/parkingLots/parking-lots-util';
import i18n from '../../../../../translations/i18n';

const Step: React.FC<Props> = ({
  children,
  onCancel,
  onConfirm,
  onNextStep,
  onPreviousStep,
  step,
  stepLength,
  stepTitle,
  bookingLoading = false,
  editMode = false,
}) => {
  const classes = useStyles();
  const { state, dispatch } = useContext(ReservationContext);
  const { date, place, needParkingSpace, canContinue, existingTeamSpaceMembers, teamSpaceMembersParking } = state;
  const { closeSnackbar, enqueueSnackbar } = useSnackbar();
  const memoizedExistingParkingPlaces = useMemo(
    () => {
      return existingTeamSpaceMembers.reduce<number>((result, member) => {
        return result + (member.needParkingSpace ? 1 : 0);
      }, 0);
    },
    // This should run only once and after the information is dispatched.
    // This cannot be bound to existingTeamSpacesMembers, as its state is changed.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [place]
  );

  const [buildingsFull, setBuildingsFull] = useState<boolean>(false);

  const { getPlaceAndParkingStatus, placeAndParkingStatus, placeAndParkingStatusLoading } = useGetStatusByIdAndDates(
    place?.id,
    date
  );
  void i18n.loadNamespaces('step');
  const { t } = useTranslation(['reservationWizard', 'step']);

  useEffect(() => {
    date && place && getPlaceAndParkingStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, place]);

  useEffect(() => {
    const existingMembers = existingTeamSpaceMembers.reduce<number>((result, current) => {
      return result + (current.needParkingSpace ? 1 : 0);
    }, 0);
    const newMembers = teamSpaceMembersParking.reduce<number>((result, current) => {
      return result + (current.needParkingSpace ? 1 : 0);
    }, 0);
    const parkingPlacesNeeded = existingMembers - memoizedExistingParkingPlaces + newMembers;
    const daysWithNotEnoughParkingPlaces = needParkingSpace
      ? ParkingLotsUtil.daysWithNotEnoughParkingPlaces(placeAndParkingStatus, parkingPlacesNeeded)
      : [];

    if (needParkingSpace && daysWithNotEnoughParkingPlaces.length > 0) {
      dispatch(
        addError({
          kind: ReservationErrorEnum.FULLY_BOOKED_PARK,
          message: t('tooltipMessage.parkNotAvailable'),
        })
      );
      enqueueSnackbar(
        ParkingLotsUtil.formatDaysWithFullyBookedParkMessage(
          t('snackbarMessage.parkNotAvailable'),
          daysWithNotEnoughParkingPlaces
        ),
        {
          variant: 'error',
          persist: true,
          key: ReservationErrorEnum.FULLY_BOOKED_PARK,
        }
      );
    } else {
      closeSnackbar(ReservationErrorEnum.FULLY_BOOKED_PARK);
      dispatch(removeError({ kind: ReservationErrorEnum.FULLY_BOOKED_PARK }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    closeSnackbar,
    enqueueSnackbar,
    placeAndParkingStatus,
    needParkingSpace,
    existingTeamSpaceMembers,
    teamSpaceMembersParking,
  ]);

  const getWingName = () => {
    return !place?.wing?.toLowerCase().includes('no') ? `${startCase(String(place?.wing).toLowerCase())}` : undefined;
  };

  function getPlaceName() {
    const isHotDesk = place?.type?.includes(PlaceTypeEnum.HOT_DESKS);
    const wingName = getWingName();

    const hotDeskName = wingName ? (
      <b className={classes.colorBlueRoyal}>
        {wingName} - {String(place?.floor)}
      </b>
    ) : (
      <b className={classes.colorBlueRoyal}>{String(place?.floor)}</b>
    );
    const groupName = wingName ? (
      <>
        {String(place?.floor)}{' '}
        <b className={classes.colorBlueRoyal}>
          {wingName} - {String(place?.name)}
        </b>
      </>
    ) : (
      <>
        {String(place?.floor)} <b className={classes.colorBlueRoyal}>{String(place?.name)}</b>
      </>
    );

    return (
      <Typography variant='h6' className={classes.colorBlueRoyal}>
        {isHotDesk ? hotDeskName : groupName}
      </Typography>
    );
  }

  function renderReservationCards() {
    if (!date.length) {
      return (
        <Card className={classes.cardContent}>
          <Typography className={classNames(classes.textContent, classes.noDaysSelected)}>
            {t('step:warningNoValidSelection')}
          </Typography>
        </Card>
      );
    }

    const handleBuildingFull = (isFull: boolean) => {
      if (buildingsFull || isFull) {
        setBuildingsFull(true);
      }
      return false;
    };

    // TODO: delete the line below when we create wings type and rename the place names
    const wing = getWingName();
    return (
      <>
        <Typography variant='subtitle2'>
          <strong>{t('step:reservationSummaryLabel')}</strong>
          {date.length && ` (${date.length} ${date.length > 1 ? 'days' : 'day'} selected)`}
        </Typography>
        <div
          className={
            editMode
              ? classes.reservationCardsContainer
              : classNames(classes.reservationCardsContainer, classes.reservationCardsContainerWithoutEditMode)
          }
        >
          <div className={classes.reservationCardsArea}>
            {date.map((day) => {
              const singlePlaceAndParkingStatus: PlaceAndParkingStatus | undefined = placeAndParkingStatus?.find(
                (singleDay) => singleDay.date === day
              );
              return (
                <ReservationCard
                  date={day}
                  key={`card-${day}`}
                  minWidth={327}
                  place={place}
                  placeAndParkingStatus={singlePlaceAndParkingStatus}
                  showIfIsUnavailable={!editMode}
                  showIfIsTeamSpace
                >
                  <ReservationCardContent
                    date={day}
                    floor={place?.floor}
                    key={`card-content-${day}`}
                    isBuildingFull={editMode ? undefined : handleBuildingFull}
                    place={place}
                    wing={wing}
                    loading={placeAndParkingStatusLoading}
                    placeAndParkingStatus={singlePlaceAndParkingStatus}
                    showIfIsUnavailable={!editMode}
                  />
                </ReservationCard>
              );
            })}
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <Grid container className={classes.gridWrapper}>
        <Grid component='section' item className={classes.leftSide}>
          {/* <div className={classes.widthFull}>
            <Typography variant={'subtitle2'}>
              {step + 1}
              {t('step:stepSeparator')}
              {stepLength}
            </Typography>
          </div> */}
          <div className={classes.stepInfo}>
            {stepTitle && (
              <Typography variant={'subtitle1'} className={classNames(classes.stepTitle)}>
                {stepTitle}
              </Typography>
            )}
            {getPlaceName()}
          </div>
          <div className={classes.stepContentWrapper}>{children}</div>
        </Grid>
        <Grid component='section' item className={classes.informationSide}>
          {renderReservationCards()}
        </Grid>
      </Grid>
      <Navigation
        bookingLoading={bookingLoading}
        onCancel={onCancel}
        onConfirm={onConfirm}
        onNextStep={onNextStep}
        onPreviousStep={onPreviousStep}
        reservationError={state.reservationError}
        step={step}
        stepLength={stepLength}
        canContinue={canContinue}
      />
    </>
  );
};

export interface StepProps extends WizardInjectedProps {
  bookingLoading?: boolean;
  stepLength: number;
  stepTitle?: string;
  editMode?: boolean;
}

interface Props extends StepProps {
  children: React.ReactElement;
  onCancel: () => void;
  onConfirm: () => void;
}

export default Step;
