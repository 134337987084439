import { muiTheme, theme } from '../../../styles/theme';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { pxToRemOrEm } from '../../../styles/utilities';

export default makeStyles(() =>
  createStyles({
    buttonRoot: {
      background: theme.colors.white.main,
      borderRadius: 16,
      boxShadow: theme.shadows.strong,
      height: 32,
      textTransform: 'unset',
      opacity: 0,
      visibility: 'hidden',
      position: 'fixed',
      left: '50%',
      bottom: 14,
      transition: theme.transition,
      whiteSpace: 'nowrap',
      padding: '5px 10px',
      transform: 'translate(-50%, 48px)',
      zIndex: theme.zIndex.space,
      color: theme.colors.blue.main,
      [muiTheme.breakpoints.up('sm')]: {
        transform: 'translate(120%, 48px)',
      },
      '&:hover': {
        background: theme.colors.white.main,
        boxShadow: theme.shadows.strongHover,
      },
    },

    buttonTypography: {
      fontSize: pxToRemOrEm(12),
    },

    buttonIcon: {
      fontSize: pxToRemOrEm(12),
      marginRight: 5,
    },

    buttonVisible: {
      opacity: 1,
      visibility: 'visible',
      transform: 'translate(-50%, 0)',
      [muiTheme.breakpoints.up('sm')]: {
        transform: 'translate(120%, 0)',
      },
    },
  })
);
