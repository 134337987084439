import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Providers } from '@microsoft/mgt-element';
import { EndpointActions, useFetch } from '../ajaxHooks/ajaxHooks';
import { ConsentRequestAssembler } from '../../assemblers/consent/consent-request-assembler';
import { TeamSpaceMemberAssembler } from '../../assemblers/teamSpaceMember/team-space-member-assembler';
import { UserAssembler } from '../../assemblers/user/user-assembler';
import { UserRequestAssembler } from '../../assemblers/user/user-request-assembler';
import { ConsentTypeEnum } from '../../enums/consentType.enum';
import { EngineTypeEnum } from '../../enums/engineType.enum';
import { IConsentRequest } from '../../interfaces/consent/consent-request';
import { IUserInformationResponse } from '../../interfaces/userInformationResponse/parking-information-response';
import { ILoggedUserResponse } from '../../interfaces/user/logged-user-response';
import { IUserInformation } from '../../interfaces/user/user-information';
import { Location } from '../../models/location/location';
import {
  setConsentState,
  setLicensePlate,
  setModalState,
  setEngineType,
  setSelectedBuilding,
} from '../../redux/actions/appActions/appActions';
import {
  getUserBuilding,
  getUserLicensePlate,
  getUserEngineType,
  getConsentState,
} from '../../redux/selectors/appSelectors';
import { getEmail, getUsername } from '../../redux/selectors/userInformationSelector';
import { setEmail, setUsername } from '../../redux/actions/userInformationAction/userInformationActions';

export const useGetUserDefaults = () => {
  return {
    email: useSelector(getEmail),
    licensePlate: useSelector(getUserLicensePlate),
    location: useSelector(getUserBuilding),
    username: useSelector(getUsername),
    engineType: useSelector(getUserEngineType),
  };
};

export const useSetUserDefaults = () => {
  const dispatch = useDispatch();

  return (building: Location, licensePlate?: string, engineType?: EngineTypeEnum) => {
    dispatch(setSelectedBuilding(building));
    dispatch(setLicensePlate(licensePlate));
    dispatch(setEngineType(engineType));
  };
};

export const useCreateUserDefaultSettings = (
  selectedBuilding: Location,
  selectedLicensePlate: string,
  selectedEngineType: EngineTypeEnum | undefined
) => {
  const authUser = useUserInformation();
  const userInformationRequest = UserRequestAssembler.from({
    username: authUser?.username || '',
    email: authUser?.email || '',
    licensePlate: selectedLicensePlate,
    engineType: selectedEngineType,
    defaultLocationId: selectedBuilding?.id,
  });
  const endpoint = EndpointActions.CreateUserDefaultSettings([userInformationRequest]);
  const { run, response, loading, error } = useFetch<IUserInformation>(endpoint, [], {
    defer: true,
  });
  const userResponse = useMemo(() => response && UserAssembler.fromUserInformation(response), [response]);

  return {
    createUserDefaultSettings: run,
    user: response && userResponse,
    userLoading: loading,
    userError: error,
  };
};

export const useUserInformation = () => {
  const dispatch = useDispatch();
  const [userInformation, setUserInformation] = useState<IUserInformation | undefined>(undefined);

  useEffect(() => {
    const getUserInformation = async () => {
      const currentAuthUser = await Providers.me();

      setUserInformation({
        username: currentAuthUser?.displayName || '',
        email: currentAuthUser?.userPrincipalName || '',
      });

      dispatch(setEmail(currentAuthUser?.userPrincipalName || ''));
      dispatch(setUsername(currentAuthUser?.displayName || ''));
    };
    void getUserInformation();
  }, [dispatch]);

  return userInformation && UserAssembler.fromUserInformation(userInformation);
};

// ************************************************************************
// SET USER CONSENT
// ************************************************************************
export const useSetUserConsent = (consent: ConsentTypeEnum | undefined) => {
  const endpoint = EndpointActions.Consent([ConsentRequestAssembler.from(consent!)]);
  const { run, response, loading, error } = useFetch<IConsentRequest>(endpoint, [], {
    defer: true,
  });
  return {
    createUserConsent: run,
    consent: response,
    consentLoading: loading,
    consentError: error,
  };
};

// ************************************************************************
// GET USER CONSENT
// ************************************************************************
export const useGetUserConsent = () => {
  return useSelector(getConsentState);
};

// ************************************************************************
// LOGGED USER
// ************************************************************************
export const useLoggedUser = () => {
  const dispatch = useDispatch();
  const endpoint = EndpointActions.LoggedUser();
  const { response, loading, error } = useFetch<ILoggedUserResponse>(endpoint, []);
  const loggedUserResponse = useMemo(() => response && UserAssembler.fromLoggedUser(response), [response]);

  useEffect(() => {
    if (!loading && !error && response) {
      dispatch(setEmail(response?.email || ''));
      dispatch(setUsername(response?.username || ''));
      dispatch(setSelectedBuilding(response?.defaultLocation));
      dispatch(setLicensePlate(response?.licensePlate));
      dispatch(setEngineType(response?.engineType));
      dispatch(setModalState(response?.consent === ConsentTypeEnum.UNKNOWN));
      dispatch(setConsentState(response?.consent));
    }
  }, [dispatch, error, loading, response]);

  return {
    loggedUser: response && loggedUserResponse,
    loggedUserLoading: loading,
    loggedUserError: error,
  };
};

// ************************************************************************
// GET USER INFORMATION
// ************************************************************************
export const useGetUserInformation = (dates: string[], userIds: string[]) => {
  const endpoint = EndpointActions.GetUserInformation([dates, userIds]);
  const { run, response, loading, error } = useFetch<IUserInformationResponse[]>(endpoint, [], {
    defer: true,
  });
  const userInformationResponse = useMemo(
    () => response && TeamSpaceMemberAssembler.fromUserInformationResponseList(response),
    [response]
  );

  return {
    getUserInformation: run,
    userInformation: response && userInformationResponse,
    userInformationLoading: loading,
    userInformationError: error,
  };
};
