import React, { useContext, useState } from 'react';
import config from '../../../config/config';

interface Props {
  children: React.ReactNode;
}

interface FeatureToggleState {
  guestPicker: boolean;
}

/**
 * This method evaluates the boolean value of a string and if it is undefined it returns the second parameter.
 * If the second parameter doesn't exist it defaults true.
 *
 * @param string The value to be parsed.
 * @param defaultValue the default value to be assigned if 'string' is undefined
 * @returns a boolean
 */
export function stringToBoolean(string: string | undefined, defaultValue = true) {
  if (string === undefined) {
    return defaultValue;
  }

  switch (string.toLowerCase().trim()) {
    case 'true':
    case 'yes':
    case '1':
      return true;

    case 'false':
    case 'no':
    case '0':
    case null:
      return false;

    default:
      return defaultValue;
  }
}

export const FeatureToggleContext = React.createContext<FeatureToggleState | undefined>(undefined);

/**
 * How to add feature toggles:
 *
 * There's a file in our codebase that injects environment variables in the window object,
 * so that they can be changed from outside the container.
 *
 * You need to add it to `env-config.tpl.js`, `src/config.js` and `config.ts` files.
 * Finally, list your feature toggle in the FeatureToggleState interface (this file).
 */
const FeatureToggleProvider: React.FC<Props> = ({ children }) => {
  const [state, _] = useState<FeatureToggleState>({
    guestPicker: stringToBoolean(config.FEATURE_TOGGLE_GUESTS, true),
  });

  return <FeatureToggleContext.Provider value={state}>{children}</FeatureToggleContext.Provider>;
};

function useFeatureToggle() {
  const context = useContext(FeatureToggleContext);
  if (context === undefined) {
    throw new Error('useFeatureToggle must be used within a FeatureToggleProvider');
  }
  return context;
}

export { FeatureToggleProvider, useFeatureToggle };
