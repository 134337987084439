import { ConsentTypeEnum } from '../../enums/consentType.enum';
import { IConsentRequest } from '../../interfaces/consent/consent-request';

export class ConsentRequestAssembler {
  static from(consentRequest: ConsentTypeEnum): IConsentRequest {
    return {
      consent: consentRequest,
    };
  }
}
