import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import sharedStyles from '../../../../styles/sharedStyles/sharedStyles';
import { pxToRemOrEm } from '../../../../styles/utilities';
import { muiTheme, theme } from '../../../../styles/theme';

const shared = sharedStyles();

export default makeStyles(() =>
  createStyles({
    ...shared,

    menuContainer: {
      ...shared.flexColumnJustifySpaceBetween,
      width: theme.navbarSmWidth,
      height: '100vh',
      backgroundColor: theme.colors.blue.lightest,
      zIndex: 2,
      [muiTheme.breakpoints.up('sm')]: {
        width: theme.navbarWidth,
      },
    },

    menuOptions: {
      overflowY: 'auto',
      overflowX: 'hidden',
    },

    mobile: {
      width: '80vw',
      alignItems: 'center',
    },

    desktop: {
      position: 'fixed',
      top: 0,
      left: 0,
    },

    logoWrapper: {
      ...shared.flexColumn,
      marginTop: muiTheme.spacing(4),
      cursor: 'pointer',
      width: 160,
      marginRight: 'auto',
    },

    wallyLogo: {
      height: 92,
      color: theme.colors.blue.main,
    },

    wallyTitle: {
      height: 18,
      color: theme.colors.blue.main,
    },

    nav: {
      color: theme.colors.blue.main,
      fontSize: pxToRemOrEm(21),
      fontWeight: 'bold',
      letterSpacing: '0',
      lineHeight: 2,
      '& > ul': {
        listStyleType: 'none',
        paddingLeft: 0,
      },
      '& > ul > li': {
        marginBottom: muiTheme.spacing(3),
        width: 'fit-content',
      },
    },

    navMarginLeft: {
      paddingLeft: muiTheme.spacing(7),
    },

    logoCtw: {
      marginBottom: muiTheme.spacing(6.5),
      display: 'block',
      marginLeft: 'auto',
      marginRight: 'auto',
      color: '#b1b3b6',
    },
  })
);
