import { Location } from '../../../models/location/location';
import { EngineTypeEnum } from '../../../enums/engineType.enum';
import {
  SELECT_BUILDING_SUCCESS,
  SELECT_DATE_SUCCESS,
  SET_CONSENT_STATE,
  SET_LICENSE_PLATE,
  SET_MODAL_STATE,
  SET_ENGINE_TYPE,
} from '../../constants/appConstants';
import { ConsentTypeEnum } from '../../../enums/consentType.enum';

export type AppAction =
  | SetLicensePlate
  | SelectBuildingSuccess
  | SelectDateSuccess
  | SetEngineType
  | SetModalState
  | SetConsentState;

// ************************************************************************
// SET LICENSE PLATE
// ************************************************************************
interface SetLicensePlate {
  type: SET_LICENSE_PLATE;
  licensePlate?: string;
}
export const setLicensePlate = (licensePlate?: string): SetLicensePlate => {
  return {
    type: SET_LICENSE_PLATE,
    licensePlate,
  };
};

// ************************************************************************
// SELECT BUILDING SUCCESS
// ************************************************************************
interface SelectBuildingSuccess {
  type: SELECT_BUILDING_SUCCESS;
  building: Location | undefined;
}
export const setSelectedBuilding = (building: Location | undefined): SelectBuildingSuccess => {
  return {
    type: SELECT_BUILDING_SUCCESS,
    building,
  };
};

// ************************************************************************
// SELECT DATE
// ************************************************************************
interface SelectDateSuccess {
  type: SELECT_DATE_SUCCESS;
  selectedDate: Date;
}
export const selectDate = (selectedDate: Date): SelectDateSuccess => {
  return {
    type: SELECT_DATE_SUCCESS,
    selectedDate,
  };
};

// ************************************************************************
// SET MODAL STATE
// ************************************************************************
interface SetModalState {
  type: SET_MODAL_STATE;
  modalState?: boolean;
}
export const setModalState = (modalState?: boolean): SetModalState => {
  return {
    type: SET_MODAL_STATE,
    modalState,
  };
};

// ************************************************************************
// SET CONSENT STATE
// ************************************************************************
interface SetConsentState {
  type: SET_CONSENT_STATE;
  consentState?: ConsentTypeEnum;
}
export const setConsentState = (consentState?: ConsentTypeEnum): SetConsentState => {
  return {
    type: SET_CONSENT_STATE,
    consentState,
  };
};

// ************************************************************************
// SET ENGINE TYPE
// ************************************************************************
interface SetEngineType {
  engineType?: EngineTypeEnum;
  type: SET_ENGINE_TYPE;
}
export const setEngineType = (engineType?: EngineTypeEnum): SetEngineType => {
  return {
    engineType,
    type: SET_ENGINE_TYPE,
  };
};
