import React, { useState } from 'react';
import Container from '@mui/material/Container';
import useStyles from './Wizard.style';
import BackToTopButton from '../BackToTopButton/BackToTopButton';

const Wizard: React.FC<Props> = ({ children, stepLength }) => {
  if (stepLength < 1) {
    throw new Error('The Wizard has to have at least one step.');
  }

  const classes = useStyles();
  const [step, setStep] = useState(0);

  function onNextStep() {
    if (step < stepLength - 1) {
      setStep(step + 1);
    }
  }

  function onPreviousStep() {
    step > 0 && setStep(step - 1);
  }

  return (
    <Container component='main' className={classes.container} maxWidth={false} disableGutters>
      {children({ step, onNextStep, onPreviousStep })}
      <BackToTopButton className={classes.backToTop} />
    </Container>
  );
};

interface Props {
  children: (props: WizardInjectedProps) => React.ReactElement;
  stepLength: number;
}

export interface WizardInjectedProps {
  onNextStep: () => void;
  onPreviousStep: () => void;
  step: number;
}

export default Wizard;
